<template>
    <div class="date-picker" ref="datePicker">
        <button
            ref="datePickerButton"
            class="form-control w-100 text-left d-flex align-items-center text-placeholder"
            @click="handleButtonClick"
            :class="{ disabled: disabled }"
            :disabled="disabled"
        >
            <img
                v-if="!noIcon"
                src="../../assets/calendar.svg"
                alt="calendar"
                class="me-3"
            />
            {{ displayedDate }}
        </button>
        <div
            v-if="isOpen"
            ref="datePickerPopup"
            class="date-picker-popup card"
            :style="popupPosition"
        >
            <div class="card-body">
                <div class="calendar-container rounded">
                    <div>
                        <div
                            class="d-flex justify-content-between align-items-center mb-4"
                        >
                            <svg
                                @click="previousMonth()"
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="10"
                                viewBox="0 0 6 10"
                                fill="none"
                                class="cursor-pointer"
                            >
                                <path
                                    d="M4.83869 9.09438L0.775391 5.03605L4.83869 0.977722"
                                    stroke="#1F1E2C"
                                    stroke-width="1.3536"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <h6 class="current-month-year mb-0 fw-bold">
                                {{ currentViewTitle }}
                            </h6>
                            <svg
                                @click="nextMonth()"
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="10"
                                viewBox="0 0 6 10"
                                fill="none"
                                class="cursor-pointer"
                            >
                                <path
                                    d="M0.793213 9.09432L4.85651 5.03599L0.793213 0.977661"
                                    stroke="#1F1E2C"
                                    stroke-width="1.3536"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <hr class="divider mt-2 mb-3" />
                    </div>
                    <div class="calendar-grid">
                        <div class="calendar-header d-flex">
                            <div
                                v-for="day in [
                                    'Su',
                                    'Mo',
                                    'Tu',
                                    'We',
                                    'Th',
                                    'Fr',
                                    'Sa',
                                ]"
                                :key="day"
                                class="calendar-cell text-center"
                            >
                                {{ day }}
                            </div>
                        </div>
                        <div class="calendar-body">
                            <div
                                class="d-flex"
                                v-for="(week, weekIndex) in calendarDays"
                                :key="weekIndex"
                            >
                                <div
                                    v-for="{
                                        date,
                                        isCurrentMonth,
                                        isSelected,
                                        isDisabled,
                                    } in week"
                                    :key="date.toISOString()"
                                    class="calendar-cell text-center"
                                    :class="{
                                        'text-muted': !isCurrentMonth,
                                        selected: isSelected,
                                        disabled: !isCurrentMonth || isDisabled,
                                        'cursor-pointer': !(
                                            isDisabled || !isCurrentMonth
                                        ),
                                    }"
                                    @click="handleDateClick(date)"
                                >
                                    {{ date.getDate() }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 small">
                        <p class="mb-1">
                            Tanggal terpilih:
                            <span class="text-green">
                                {{ formattedSelectedDate }}
                            </span>
                        </p>
                    </div>
                    <div v-if="withTime" class="form-check mt-3">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            v-model="showTime"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                            Tambah Waktu Keberangkatan
                        </label>
                    </div>
                    <div v-if="showTime">
                        <div
                            class="d-flex justify-content-between align-items-center mt-3"
                        >
                            <input
                                type="text"
                                class="input-time form-control"
                                v-model="hours"
                                @input="validateHours"
                                @blur="formatHours"
                                placeholder="HH"
                                maxlength="2"
                            />
                            <p class="mb-0 divider-time">:</p>
                            <input
                                type="text"
                                class="input-time form-control"
                                v-model="minutes"
                                @input="validateMinutes"
                                @blur="formatMinutes"
                                placeholder="MM"
                                maxlength="2"
                            />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-6">
                            <button
                                class="btn btn-outline-yellow w-100"
                                @click="handleReset"
                                :disabled="isResetDisabled"
                            >
                                Reset
                            </button>
                        </div>
                        <div class="col-6">
                            <button
                                class="btn btn-green w-100"
                                @click="handleSave"
                                :disabled="isSaveDisabled"
                            >
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.calendar-cell.text-center.disabled {
    cursor: not-allowed !important;
}
.calendar-cell.text-center.text-muted.disabled {
    cursor: pointer !important;
}
.input-time {
    width: 45%;
}
.divider-time {
    width: 5%;
    text-align: center;
}
.text-placeholder {
    color: #6c757d !important;
    font-size: 13px;
}
.date-picker {
    position: relative;
    width: 100%;
    max-width: 800px;
}
.date-picker-popup {
    position: absolute;
    z-index: 1000;
    width: 25rem;
    margin-top: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    transform-origin: top center;
}
@media screen and (max-width: 768px) {
    .date-picker-popup {
        width: 24rem;
        max-width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .card-body {
        min-width: 24rem;
        padding-bottom: 1rem;
    }
}
.divider {
    border: none;
    border-top: 1px solid #dee2e6;
    margin: 0;
    width: 100%;
}
.calendar-container {
    background: white;
    padding: 1rem;
}
.calendar-cell {
    width: calc(100% / 7);
    padding: 0.5rem;
    cursor: pointer;
}
.calendar-header .calendar-cell {
    font-weight: bold;
    cursor: default;
}
.form-check-input:checked {
    background-color: #40bf76;
    border-color: #40bf76;
}
.form-check-input:focus {
    box-shadow: none;
}
.calendar-cell.selected {
    background-color: #40bf76;
    color: white !important;
}
.calendar-cell.disabled {
    opacity: 0.5;
}
.cursor-pointer {
    cursor: pointer;
}
</style>
<script>
export default {
    name: "DatePicker",
    props: {
        noIcon: {
            type: Boolean,
            default: false,
        },
        withTime: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Date,
            default: null,
        },
        placeholderText: {
            type: String,
            default: "Select date",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledPast: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            popupPosition: {},
            displayedDate: this.placeholderText,
            isOpen: false,
            selectedDate: this.value,
            tempSelectedDate: null,
            currentMonth: new Date().getMonth(),
            currentYear: new Date().getFullYear(),
            showTime: false,
            hours: "",
            minutes: "",
            months: [
                "Januari",
                "Februari",
                "Maret",
                "April",
                "Mei",
                "Juni",
                "Juli",
                "Agustus",
                "September",
                "Oktober",
                "November",
                "Desember",
            ],
        };
    },
    computed: {
        isSaveDisabled() {
            if (!this.tempSelectedDate) return true;
            if (
                this.withTime &&
                this.showTime &&
                (!this.hours || !this.minutes)
            )
                return true;
            return false;
        },
        isResetDisabled() {
            if (!this.tempSelectedDate) return true;
            return false;
        },
        formattedSelectedDate() {
            if (!this.tempSelectedDate) return "-";
            const date = this.tempSelectedDate.getDate();
            const month = this.months[this.tempSelectedDate.getMonth()];
            const year = this.tempSelectedDate.getFullYear();
            if (this.showTime && this.hours && this.minutes) {
                return `${date} ${month} ${year} | ${this.hours}:${this.minutes}`;
            }
            return `${date} ${month} ${year}`;
        },
        calendarDays() {
            const days = this.getMonthDays(
                new Date(this.currentYear, this.currentMonth)
            );
            const weeks = [];
            for (let i = 0; i < days.length; i += 7) {
                weeks.push(days.slice(i, i + 7));
            }
            return weeks;
        },
        currentViewTitle() {
            return `${this.months[this.currentMonth]} ${this.currentYear}`;
        },
    },
    watch: {
        value(newValue) {
            this.selectedDate = newValue;
            this.tempSelectedDate = newValue;
            if (newValue === null) {
                this.displayedDate = this.placeholderText;
            }
        },
        isOpen(newValue) {
            if (newValue) {
                this.$nextTick(() => {
                    this.calculatePopupPosition();
                });
            }
        },
    },
    mounted() {
        if (this.value) {
            this.updateDisplayedDate();
        }

        document.addEventListener("click", this.handleClickOutside);
        window.addEventListener("resize", this.calculatePopupPosition);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
        window.removeEventListener("resize", this.calculatePopupPosition);
    },
    methods: {
        calculatePopupPosition() {
            if (!this.isOpen) return;

            const button = this.$refs.datePickerButton;
            const popup = this.$refs.datePickerPopup;

            if (!button || !popup) return;

            // Get button and popup dimensions
            const buttonRect = button.getBoundingClientRect();
            const popupRect = popup.getBoundingClientRect();
            const windowWidth = window.innerWidth;

            // Horizontal positioning
            let left = 0;
            const popupWidth = popupRect.width;
            const buttonWidth = buttonRect.width;

            // Check if popup would go beyond right side of window
            if (buttonRect.left + popupWidth > windowWidth) {
                // Align right side of popup with right side of button
                left = buttonWidth - popupWidth;
            } else {
                // Default to aligning left sides
                left = 0;
            }

            this.popupPosition = {
                left: `${left}px`,
                top: `${buttonRect.height + 8}px`, // 8px margin
            };
        },
        validateHours() {
            this.hours = this.hours.replace(/[^0-9]/g, "");
            let val = parseInt(this.hours);
            if (!isNaN(val) && val > 23) {
                this.hours = "23";
            }
        },
        validateMinutes() {
            this.minutes = this.minutes.replace(/[^0-9]/g, "");
            let val = parseInt(this.minutes);
            if (!isNaN(val) && val > 59) {
                this.minutes = "59";
            }
        },
        formatHours() {
            if (this.hours !== "") {
                let val = parseInt(this.hours);
                if (!isNaN(val) && val >= 0 && val <= 23) {
                    this.hours = val.toString().padStart(2, "0");
                }
            }
        },
        formatMinutes() {
            if (this.minutes !== "") {
                let val = parseInt(this.minutes);
                if (!isNaN(val) && val >= 0 && val <= 59) {
                    this.minutes = val.toString().padStart(2, "0");
                }
            }
        },
        updateDisplayedDate() {
            if (!this.selectedDate) {
                this.displayedDate = this.placeholderText;
                return;
            }
            const dateStr = `${this.selectedDate.getDate()} ${
                this.months[this.selectedDate.getMonth()]
            } ${this.selectedDate.getFullYear()}`;
            if (this.withTime && this.showTime && this.hours && this.minutes) {
                this.displayedDate = `${dateStr} | ${this.hours}:${this.minutes}`;
            } else {
                this.displayedDate = dateStr;
            }
        },
        handleButtonClick() {
            if (!this.disabled) {
                this.isOpen = !this.isOpen;
            }
        },
        handleClickOutside(event) {
            const datePicker = this.$refs.datePicker;
            if (
                datePicker &&
                !datePicker.contains(event.target) &&
                this.isOpen
            ) {
                this.isOpen = false;
            }
        },
        getMonthDays(date) {
            const year = date.getFullYear();
            const month = date.getMonth();
            const firstDay = new Date(year, month, 1);
            const lastDay = new Date(year, month + 1, 0);
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const days = [];
            const startPadding = firstDay.getDay();

            for (let i = startPadding - 1; i >= 0; i--) {
                const prevDate = new Date(year, month, -i);
                days.push({
                    date: prevDate,
                    isCurrentMonth: false,
                    isSelected: this.isDateSelected(prevDate),
                    isDisabled: this.disabledPast && prevDate < today,
                });
            }

            for (let i = 1; i <= lastDay.getDate(); i++) {
                const currentDate = new Date(year, month, i);
                days.push({
                    date: currentDate,
                    isCurrentMonth: true,
                    isSelected: this.isDateSelected(currentDate),
                    isDisabled: this.disabledPast && currentDate < today,
                });
            }

            const remainingDays = 42 - days.length;
            for (let i = 1; i <= remainingDays; i++) {
                const nextDate = new Date(year, month + 1, i);
                days.push({
                    date: nextDate,
                    isCurrentMonth: false,
                    isSelected: this.isDateSelected(nextDate),
                    isDisabled: this.disabledPast && nextDate < today,
                });
            }

            return days;
        },
        isDateSelected(date) {
            return (
                this.tempSelectedDate?.toDateString() === date.toDateString()
            );
        },
        handleDateClick(date) {
            if (this.disabledPast) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                if (date < today) return;
            }
            this.tempSelectedDate = date;
        },
        handleReset() {
            const today = new Date();
            this.selectedDate = null;
            this.tempSelectedDate = null;
            this.displayedDate = this.placeholderText;
            this.currentMonth = today.getMonth();
            this.currentYear = today.getFullYear();
            this.showTime = this.withTime;
            this.hours = "";
            this.minutes = "";
            this.$emit("input", null);
        },
        handleSave() {
            if (this.tempSelectedDate) {
                let finalDate = new Date(this.tempSelectedDate);
                if (
                    this.withTime &&
                    this.showTime &&
                    this.hours &&
                    this.minutes
                ) {
                    finalDate.setHours(parseInt(this.hours));
                    finalDate.setMinutes(parseInt(this.minutes));
                }
                this.selectedDate = finalDate;
                this.updateDisplayedDate();
                this.$emit("input", finalDate);
                this.$emit("save", finalDate);
            }
            this.isOpen = false;
        },
        previousMonth() {
            if (this.currentMonth === 0) {
                this.currentMonth = 11;
                this.currentYear--;
            } else {
                this.currentMonth--;
            }
        },
        nextMonth() {
            if (this.currentMonth === 11) {
                this.currentMonth = 0;
                this.currentYear++;
            } else {
                this.currentMonth++;
            }
        },
    },
};
</script>
