<template>
    <div class="padding-container">
        <div class="content-load" v-if="isLoading"></div>
        <div id="loader" v-if="isLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <h5 class="fw-bold mb-4">Informasi Vendor</h5>
            <div class="row">
                <div class="col-lg-12 mb-4">
                    <label class="mb-2"
                        >Nama Vendor <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="name"
                        disabled
                    />
                </div>
                <div class="col-lg-6 mb-4">
                    <label class="mb-2"
                        >Nomor Telepon Vendor
                        <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="phone"
                        disabled
                    />
                </div>
                <div class="col-lg-6 mb-4">
                    <label class="mb-2"
                        >Email <span class="text-yellow">*</span></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        v-model="email"
                        disabled
                    />
                </div>
                <div class="col-lg-6 mb-4">
                    <label class="mb-2"
                        >Provinsi Vendor
                        <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="province"
                        disabled
                    />
                </div>
                <div class="col-lg-6 mb-4">
                    <label class="mb-2"
                        >Kota/Kabupaten Vendor
                        <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="regency"
                        disabled
                    />
                </div>
                <div class="col-lg-12 mb-4">
                    <label class="mb-2"
                        >Alamat Vendor <span class="text-yellow">*</span></label
                    >
                    <textarea
                        class="form-control"
                        v-model="address"
                        disabled
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-4">
            <h5 class="fw-bold mb-4">Rute</h5>
            <div class="mb-4">
                <div class="header-sidebar px-3 py-2">
                    <h5 class="mb-0 fw-bold">Informasi Rute</h5>
                </div>
                <div class="body-sidebar px-3 py-2">
                    <div class="row">
                        <div class="col-lg-6 my-3">
                            <label class="fw-bold mb-2"
                                >Jenis Layanan
                                <span class="text-yellow">*</span></label
                            >
                            <multiselect
                                v-model="serviceType"
                                :options="serviceTypes"
                                label="name"
                                track-by="id"
                                placeholder="Pilih jenis layanan"
                                class="multi-form-custom"
                                :show-labels="false"
                                @input="handleServiceTypeChange"
                            >
                            </multiselect>
                        </div>
                        <div class="col-lg-6 my-3" v-if="shouldShowFleetName">
                            <label class="fw-bold mb-2"
                                >Jenis Armada
                                <span class="text-yellow">*</span></label
                            >
                            <multiselect
                                v-model="fleet"
                                :options="fleets"
                                label="name"
                                track-by="code"
                                placeholder="Pilih jenis armada"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-lg-6 my-3" v-if="shouldShowShipName">
                            <label class="fw-bold mb-2"
                                >Nama Kapal
                                <span class="text-yellow">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Masukkan nama kapal"
                                v-model="shipName"
                            />
                        </div>
                        <div class="col-lg-6 my-3" v-if="shouldShowPlaneName">
                            <label class="fw-bold mb-2"
                                >Nama Pesawat
                                <span class="text-yellow">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Masukkan nama pesawat"
                                v-model="planeName"
                            />
                        </div>
                        <div class="col-lg-6 my-3">
                            <label class="fw-bold mb-2"
                                >Kota Asal
                                <span class="text-yellow">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="regencyRoute"
                                disabled
                            />
                        </div>
                        <div class="col-lg-6 my-3">
                            <label class="fw-bold mb-2"
                                >Rute Provinsi Tujuan
                                <span class="text-yellow">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="provinceRoute"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="box">
                        <h5 class="fw-bold mb-4">Samakan Data</h5>
                        <div class="row">
                            <div class="col-lg-3">
                                <label class="fw-bold mb-2">Harga</label>
                                <div class="input-group mb-3">
                                    <span class="input-group-text">Rp</span>
                                    <input
                                        type="text"
                                        class="form-control input-sidebar"
                                        placeholder="Masukkan harga"
                                        v-model="commonPrice"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <label class="fw-bold mb-2">Lead Time</label>
                                <div class="input-group mb-3">
                                    <input
                                        type="text"
                                        class="form-control input-sidebar-2"
                                        placeholder="Masukkan waktu"
                                        v-model="commonLeadTime"
                                    />
                                    <span class="input-group-text">Hari</span>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <label class="fw-bold mb-2"
                                    >Minimal Order</label
                                >
                                <div class="input-group mb-3">
                                    <input
                                        type="text"
                                        class="form-control input-sidebar-2"
                                        placeholder="Masukkan angka"
                                        v-model="commonMinOrder"
                                    />
                                    <span class="input-group-text">{{
                                        minOrderUnit
                                    }}</span>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <label class="fw-bold mb-2 invisible"
                                            >Minimal Order</label
                                        >
                                        <div
                                            class="btn btn-primary w-100"
                                            @click="applyCommonData"
                                        >
                                            Terapkan
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="fw-bold mb-2 invisible"
                                            >Mini</label
                                        >
                                        <button
                                            class="btn btn-outline-yellow"
                                            @click="resetCommonData"
                                        >
                                            <img
                                                src="../assets/refresh.svg"
                                                alt="refresh"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">
                                        <input
                                            type="checkbox"
                                            class="custom-checkbox"
                                            v-model="selectAll"
                                            @change="toggleSelectAll"
                                        />
                                    </th>
                                    <th class="text-center" scope="col">
                                        Kota/Kab
                                    </th>
                                    <th scope="col">Harga</th>
                                    <th scope="col">Lead Time</th>
                                    <th
                                        scope="col"
                                        class="cursor-pointer"
                                        @click="toggleMinOrderOptions"
                                    >
                                        <div
                                            class="d-flex justify-content-between align-items-center"
                                        >
                                            Minimal Order
                                            <div class="position-relative">
                                                <i
                                                    class="fa fa-chevron-down"
                                                ></i>
                                                <div
                                                    class="box p-3 rounded position-absolute left-35"
                                                    v-if="changeMinOrder"
                                                >
                                                    <p
                                                        class="mb-0 cursor-pointer"
                                                        @click="
                                                            changeMinOrderUnit(
                                                                'Kg'
                                                            )
                                                        "
                                                    >
                                                        Kg
                                                    </p>
                                                    <hr />
                                                    <p
                                                        class="mb-0 cursor-pointer"
                                                        @click="
                                                            changeMinOrderUnit(
                                                                'm³'
                                                            )
                                                        "
                                                    >
                                                        m³
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(list, index) in lists" :key="index">
                                    <td class="text-center">
                                        <input
                                            type="checkbox"
                                            class="custom-checkbox"
                                            v-model="list.selected"
                                        />
                                    </td>
                                    <td>{{ list.destinationRegency }}</td>
                                    <td>
                                        <div
                                            class="d-flex align-items-center gap-2"
                                        >
                                            <p class="mb-0">
                                                Rp
                                                <span class="text-gray ms-2"
                                                    >|</span
                                                >
                                            </p>
                                            <input
                                                type="text"
                                                placeholder="Masukkan Harga"
                                                class="input-transparent w-100"
                                                v-model="list.price"
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                            class="d-flex align-items-center gap-2"
                                        >
                                            <input
                                                type="text"
                                                placeholder="Masukkan waktu"
                                                class="input-transparent"
                                                v-model="list.lead_time"
                                            />
                                            <p class="mb-0 w-100 text-end">
                                                <span class="text-gray me-2"
                                                    >|</span
                                                >Hari
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                            class="d-flex align-items-center gap-2"
                                        >
                                            <input
                                                type="text"
                                                placeholder="Minimal Order"
                                                class="input-transparent"
                                                v-model="list.min_order"
                                            />
                                            <p class="mb-0 w-100 text-end">
                                                <span class="text-gray me-2"
                                                    >|</span
                                                >{{ minOrderUnit }}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end mt-4">
                <button
                    class="btn btn-green px-5"
                    @click="modalConfirm = true"
                    :disabled="!isFormValid"
                >
                    Simpan
                </button>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Perubahan Informasi rute vendor ini akan disimpan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isLoadingSave"
                            @click="saveData"
                        >
                            <span
                                v-if="isLoadingSave"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Perubahan Informasi rute vendor ini berhasil disimpan
                    </h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/route"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            name: null,
            phone: null,
            email: null,
            province: null,
            regency: null,
            address: null,
            serviceType: null,
            serviceTypes: [
                { name: "Darat", id: "darat" },
                { name: "Laut", id: "laut" },
                { name: "Udara", id: "udara" },
            ],
            fleet: null,
            fleets: [],
            shipName: "",
            planeName: "",
            regencyRoute: null,
            provinceRoute: null,
            changeMinOrder: false,
            isLoading: false,
            lists: null,
            selectAll: false,
            commonPrice: "",
            commonLeadTime: "",
            commonMinOrder: "",
            minOrderUnit: "",
            vendorId: null,
            regencyIdRoute: null,
            modalConfirm: false,
            modalSuccess: false,
            isLoadingSave: false,
        };
    },
    created() {
        this.loadData();
    },
    computed: {
        shouldShowFleetName() {
            return this.serviceType && this.serviceType.id === "darat";
        },
        shouldShowShipName() {
            return this.serviceType && this.serviceType.id === "laut";
        },
        shouldShowPlaneName() {
            return this.serviceType && this.serviceType.id === "udara";
        },
        isFormValid() {
            if (!this.serviceType) {
                return false;
            }

            if (this.shouldShowFleetName && !this.fleet) {
                return false;
            }

            if (this.shouldShowShipName && !this.shipName) {
                return false;
            }

            if (this.shouldShowPlaneName && !this.planeName) {
                return false;
            }

            if (!this.lists || !this.lists.some((item) => item.selected)) {
                return false;
            }
            const selectedRoutes = this.lists.filter((item) => item.selected);
            return selectedRoutes.every(
                (route) => route.price && route.lead_time && route.min_order
            );
        },
    },
    methods: {
        async loadData() {
            await this.getFleets();
            this.getDetail();
        },
        async getFleets() {
            this.isLoading = true;
            try {
                const res = await Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/master/fleet_type`,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                );
                this.fleets = res.data.data;
            } catch (err) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: err.response.data.message,
                });
            }
        },
        getDetail() {
            const payload = {
                route_ids: this.$route.query.route_ids
                    ? this.$route.query.route_ids.split(",")
                    : [],
            };

            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/detail`,
                {
                    params: payload,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.isLoading = false;
                    const detail = res.data.data;
                    this.vendorId = detail.vendor.vendor_id;
                    this.name = detail.vendor.vendor_name;
                    this.phone = detail.vendor.vendor_phone;
                    this.email = detail.vendor.vendor_email;
                    this.province = detail.vendor.province_name;
                    this.regency = detail.vendor.regency_name;
                    this.address = detail.vendor.address;
                    this.serviceType = this.serviceTypes.find(
                        (r) => r.id === detail.route_calculate.service_type
                    );

                    if (this.serviceType) {
                        if (this.serviceType.id === "darat") {
                            this.fleet = this.fleets.find(
                                (r) =>
                                    r.code ===
                                    detail.route_calculate.vehicle_name
                            );
                        } else if (this.serviceType.id === "laut") {
                            this.shipName =
                                detail.route_calculate.vehicle_name || "";
                        } else if (this.serviceType.id === "udara") {
                            this.planeName =
                                detail.route_calculate.vehicle_name || "";
                        }
                    }

                    this.regencyRoute =
                        detail.route_calculate.route_origin_regency_name;
                    this.provinceRoute =
                        detail.route_calculate.route_destination_province_name;
                    this.lists = detail.routes.map((item) => ({
                        selected: !item.deleted,
                        destinationRegency: item.destination_regency_name,
                        price: item.route_price,
                        lead_time: item.leadtime,
                        min_order: item.order_minimum,
                        id: item.id,
                        origin_regency_id: item.origin_regency_id,
                        destination_regency_id: item.destination_regency_id,
                    }));
                    this.selectAll = detail.routes.every(
                        (item) => !item.deleted
                    );
                    this.minOrderUnit =
                        detail.routes[0].order_minimum_type == "kilogram"
                            ? "Kg"
                            : "m³";
                    this.regencyIdRoute =
                        detail.route_calculate.route_origin_regency_id;
                    this.destinationProvinceId =
                        detail.route_calculate.route_destination_province_id;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        handleServiceTypeChange() {
            if (this.serviceType && this.serviceType.id !== "darat") {
                this.fleet = null;
            }
            if (this.serviceType && this.serviceType.id !== "laut") {
                this.shipName = "";
            }
            if (this.serviceType && this.serviceType.id !== "udara") {
                this.planeName = "";
            }
        },
        toggleSelectAll() {
            if (this.lists) {
                this.lists.forEach((item) => {
                    item.selected = this.selectAll;
                });
            }
        },
        applyCommonData() {
            this.lists.forEach((item) => {
                item.price = this.commonPrice;
                item.lead_time = this.commonLeadTime;
                item.min_order = this.commonMinOrder;
            });
        },
        resetCommonData() {
            this.commonPrice = "";
            this.commonLeadTime = "";
            this.commonMinOrder = "";
        },
        toggleMinOrderOptions() {
            this.changeMinOrder = !this.changeMinOrder;
        },
        changeMinOrderUnit(unit) {
            this.minOrderUnit = unit;
            this.changeMinOrder = false;
        },
        saveData() {
            this.isLoadingSave = true;

            let vehicle = "";
            if (this.serviceType.id === "darat") {
                vehicle = this.fleet.code;
            } else if (this.serviceType.id === "laut") {
                vehicle = this.shipName;
            } else if (this.serviceType.id === "udara") {
                vehicle = this.planeName;
            }

            const routeData = this.lists.map((item) => ({
                id: item.id,
                vendor_id: this.vendorId || null,
                service_type: this.serviceType ? this.serviceType.id : null,
                vehicle: vehicle,
                origin_regency_id: this.regencyIdRoute,
                destination_regency_id: item.destination_regency_id,
                destination_province_id: this.destinationProvinceId,
                price: parseInt(item.price) || 0,
                leadtime: parseInt(item.lead_time) || 0,
                order_minimum: parseInt(item.min_order) || 0,
                order_minimum_type:
                    this.minOrderUnit === "Kg" ? "kilogram" : "metercubic",
                deleted: !item.selected,
            }));
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/edit`,
                {
                    routes: routeData,
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoadingSave = false;
                    this.modalConfirm = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.isLoadingSave = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text:
                            err.response?.data?.message || "Terjadi kesalahan",
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .multiselect--disabled .multiselect__tags,
::v-deep .multiselect--disabled .multiselect__tags .multiselect__single {
    background: #f3f5f7 !important;
    color: #495057 !important;
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.left-35 {
    left: -35px;
}
.outline-add {
    background-color: transparent !important;
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
}
.input-transparent {
    background: transparent;
    border: none;
    outline: none;
    resize: none;
}
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}
.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}
.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
.input-sidebar {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.input-sidebar-2 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    cursor: pointer;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.bb-r {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.rotate-180 {
    transform: rotate(180deg);
}
.outline-green {
    border: 1px solid #ff8600 !important;
    color: #ff8600 !important;
    background-color: transparent !important;
    border-radius: 8px;
}
</style>
