<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="box-manifest">
            <div class="mb-3">
                <h5 class="fw-bold">Daftar Manifest</h5>
            </div>
            <div class="row gy-3">
                <div class="col-12 col-lg-5">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari Nomor Manifest"
                                v-model="search"
                                @keyup.enter="filterData()"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="filterData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span
                            class="search-icon left position-absolute top-50 start-0 translate-middle-y ms-3"
                        >
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="col-12 col-lg-3 col-xl-2">
                    <button
                        class="btn btn-outline-yellow btn-filter w-100"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
                <div
                    v-if="isRole(action)"
                    class="col-12 col-lg-4 col-xl-5 d-flex justify-content-end"
                >
                    <router-link
                        to="/manifest/create"
                        class="btn btn-primary px-3 btn-pesanan d-flex align-items-center justify-content-center width-m-100"
                    >
                        <i class="pi pi-plus me-1 size-12"></i> Buat Manifest
                    </router-link>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-lg-4 my-2">
                        <label class="fw-bold mb-1">Kota Asal</label>
                        <multiselect
                            v-model="origin"
                            :options="origins"
                            label="name"
                            track-by="id"
                            placeholder="Cari dan pilih kota asal"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-4 my-2">
                        <label class="fw-bold mb-1">Kota Tujuan</label>
                        <multiselect
                            v-model="destination"
                            :options="destinations"
                            label="name"
                            track-by="id"
                            placeholder="Cari dan pilih kota tujuan"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col-lg-6 my-2">
                                <label class="fw-bold mb-1 invisible-md"
                                    >Kota</label
                                >
                                <button
                                    class="btn btn-outline-yellow me-3 w-100"
                                    @click="clearFilter()"
                                    :disabled="
                                        origin == null && destination == null
                                    "
                                >
                                    Clear
                                </button>
                            </div>
                            <div class="col-lg-6 my-2">
                                <label class="fw-bold mb-1 invisible-md"
                                    >Kota</label
                                >
                                <button
                                    class="btn btn-green w-100"
                                    :disabled="
                                        origin == null && destination == null
                                    "
                                    @click="filterData()"
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-4">
                <div class="row gy-3 align-items-center">
                    <div class="col-12 col-lg-6">
                        <div class="text-center text-lg-start">
                            Menampilkan
                            <span class="fw-bold"
                                >{{ pageStart }}-{{ pageEnd }}</span
                            >
                            Data dari
                            <span class="fw-bold">{{ lists.total_data }}</span>
                            Data
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div
                            class="d-flex flex-column flex-lg-row align-items-center justify-content-lg-end"
                        >
                            <div class="mb-2 mb-lg-0 me-lg-2">
                                Baris per halaman
                            </div>
                            <select
                                class="form-control form-select w-select outline-yellow"
                                style="max-width: 100px"
                                v-model="limit"
                                @change="onLimitChange"
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="list_loading">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nomor Manifest
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Asal
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Tujuan
                                </th>
                                <th class="text-center" scope="col">
                                    Perkiraan Waktu Tiba
                                </th>
                                <th class="text-center" scope="col">
                                    Status Manifest
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="isRole(action)"
                                >
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in lists.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>
                                    <router-link
                                        :to="'/manifest/detail/' + item.id"
                                        class="text-link"
                                    >
                                        {{ item.manifest_code }}
                                    </router-link>
                                </td>
                                <td>{{ item.origin_regency }}</td>
                                <td>{{ item.destination_regency }}</td>
                                <td>{{ item.estimated_time }}</td>
                                <td class="text-center">
                                    <span
                                        class="tag mb-0 pb-0 radius me-0 d-flex justify-content-center w-100"
                                        v-bind:class="{
                                            'yellow-cro':
                                                item.status == 'process',
                                            'blue-cro':
                                                item.status == 'ontheway',
                                            'green-cro':
                                                item.status == 'finish',
                                            'red-cro': item.status == 'pending',
                                        }"
                                    >
                                        <template
                                            v-if="item.status == 'process'"
                                        >
                                            Dalam Proses
                                        </template>
                                        <template
                                            v-if="item.status == 'ontheway'"
                                        >
                                            Dalam Perjalanan
                                        </template>
                                        <template
                                            v-if="item.status == 'pending'"
                                        >
                                            Tertunda
                                        </template>
                                        <template
                                            v-if="item.status == 'finish'"
                                        >
                                            Selesai
                                        </template>
                                    </span>
                                </td>
                                <td class="text-center" v-if="isRole(action)">
                                    <div
                                        class="d-flex align-items-center justify-content-center gap-2"
                                    >
                                        <img
                                            v-if="item.status == 'finish'"
                                            src="../assets/edit-passive.svg"
                                            alt="edit passive"
                                        />
                                        <img
                                            v-else
                                            @click="showModalEditManifest(item)"
                                            src="../assets/edit-active.svg"
                                            alt="edit active"
                                            class="cursor-pointer"
                                        />
                                        <img
                                            src="../assets/print-action.svg"
                                            alt="print action"
                                            class="cursor-pointer"
                                            @click="
                                                getManifest(item.manifest_code)
                                            "
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="lists.list_data.length == 0"
                    class="text-center mt-5"
                >
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar manifest
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="lists.list_data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="getLists()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalEditManifest"></div>
            <div class="modal-body-chat md" v-if="modalEditManifest">
                <div class="mt-3 mb-3">
                    <h5 class="mb-0 fw-bold">
                        Update Status Manifest - {{ dataEdit.manifest_code }}
                    </h5>
                    <div class="mt-3">
                        <label class="fw-bold mb-1">
                            Status Manifest Terkini
                        </label>
                        <div class="form-box text-dark">
                            <template v-if="dataEdit.status === 'process'">
                                Dalam Proses
                            </template>
                            <template v-if="dataEdit.status === 'ontheway'">
                                Dalam Perjalanan
                            </template>
                            <template v-if="dataEdit.status === 'finish'">
                                Selesai
                            </template>
                        </div>
                        <div class="mt-3">
                            <label class="fw-bold mb-1">
                                Perubahan Status Manifest
                                <span class="text-danger">*</span>
                            </label>
                            <multiselect
                                v-model="status"
                                :options="statuses"
                                label="label"
                                track-by="id"
                                placeholder="Pilih status manifest"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="mt-3">
                            <label class="fw-bold mb-1"> Remark </label>
                            <textarea
                                class="form-control"
                                placeholder="Tambahkan Remark"
                                v-model="remark"
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <a
                        href="javascript:void(0)"
                        @click="
                            (modalEditManifest = false),
                                (status = null),
                                (remark = '')
                        "
                        class="btn btn-outline-yellow btn-lg me-3 px-5"
                    >
                        Batal
                    </a>
                    <button
                        type="button"
                        class="btn btn-primary btn-lg px-5"
                        :disabled="is_update_manifest || status == null"
                        @click="storeData()"
                    >
                        <span
                            class="spinner-border spinner-border-sm me-2"
                            v-if="is_update_manifest"
                        ></span>
                        Update
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessUpdate"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessUpdate">
                <div class="text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Status Manifest berhasil diupdate
                    </h5>
                </div>
                <div class="mt-4">
                    <button
                        @click="
                            modalSuccessUpdate = false;
                            getLists();
                        "
                        type="button"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";
import moment from "moment";

export default {
    components: {
        Multiselect,
        ListLoader,
    },
    name: "Manifest",
    props: ["roles"],
    data() {
        return {
            itemsPerPage: 10,
            currentItems: [],
            pageStart: 0,
            pageEnd: 0,
            moment: moment,
            isFilter: false,
            search: "",
            limit: 5,
            totalPage: 0,
            currentPage: 1,
            lists: {
                list_data: [],
            },
            origin: null,
            origins: [],
            destination: null,
            destinations: [],
            list_loading: false,
            modalEditManifest: false,
            status: null,
            statuses: [
                {
                    id: "process",
                    label: "Dalam Proses",
                },
                {
                    id: "ontheway",
                    label: "Dalam Perjalanan",
                },
                {
                    id: "finish",
                    label: "Selesai",
                },
            ],
            dataEdit: null,
            modalSuccessUpdate: false,
            is_update_manifest: false,
            action: ["tracking", "super-admin"],
            remark: "",
        };
    },
    created() {
        this.getRegency();
        this.getLists();
    },
    methods: {
        getManifest(manifestCode) {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/manifest/print`, {
                params: {
                    manifest_code: manifestCode,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    const data = res.data.data;
                    const link = document.createElement("a");
                    link.href = data.link;
                    link.rel = "noopener noreferrer";
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    this.isPrint = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        getLists() {
            let payload = {
                search: this.search,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };

            if (this.origin) {
                payload.origin_regency_id = this.origin?.id;
            }
            if (this.destination) {
                payload.destination_regency_id = this.destination?.id;
            }

            this.list_loading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/manifest`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.lists = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    const totalItems = this.lists.list_data.length;
                    const totalData = res.data.data.total_data;
                    this.pageStart =
                        totalItems === 0
                            ? 0
                            : (this.currentPage - 1) * this.limit + 1;
                    this.pageEnd = Math.min(
                        Number(this.pageStart) + Number(this.limit) - 1,
                        totalData
                    );
                    this.list_loading = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.list_loading = false;
                });
        },

        onLimitChange() {
            this.currentPage = 1;
            this.getLists();
        },
        storeData() {
            this.is_update_manifest = true;
            var data = {
                manifest_code: this.dataEdit?.manifest_code,
                status: this.status?.id,
                remark: this.remark,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/manifest/update`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.is_update_manifest = false;
                    this.modalEditManifest = false;
                    this.modalSuccessUpdate = true;
                    this.remark = "";
                })
                .catch((err) => {
                    this.modalEditManifest = false;
                    this.status = null;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        showModalEditManifest(item) {
            this.dataEdit = item;
            this.modalEditManifest = true;
        },
        clearFilter() {
            this.origin = null;
            this.destination = null;
            this.getLists();
        },
        filterData() {
            this.getLists();
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.origins = data;
                    this.destinations = data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        showDataDetail(item) {
            this.dataDetail = item;
            this.modalDetailManifest = true;
        },
    },
};
</script>

<style lang="scss" scoped>
textarea {
    resize: none;
}
.width-m-100 {
    width: 100%;
    @media (min-width: 992px) {
        width: fit-content;
    }
}
.invisible-md {
    display: none;
}
@media only screen and (min-width: 992px) {
    .invisible-md {
        visibility: hidden !important;
        display: block;
    }
}
td {
    background-color: transparent !important;
}
th {
    background-color: #f3f5f7 !important;
}
.btn-filter {
    background: transparent !important;
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.grayscale {
    filter: grayscale(100%);
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.outline-yellow:focus {
    background-color: transparent !important;
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
</style>
