<template>
    <div class="padding-container">
        <div class="box-manifest">
            <h5 class="fw-bold">Daftar Route Management & Lead Time</h5>
            <div class="row gy-3 mt-3">
                <div class="col-12 col-lg-5">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari kota asal/tujuan"
                                v-model="search"
                                @keyup.enter="searchRoute()"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="searchRoute()"
                            >
                                Cari
                            </button>
                        </div>
                        <span
                            class="search-icon left position-absolute top-50 start-0 translate-middle-y ms-3"
                        >
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="col-12 col-lg-3 col-xl-2">
                    <button
                        class="btn btn-outline-yellow btn-filter transparent w-100"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
                <div
                    class="col-12 col-lg-4 col-xl-5 d-flex justify-content-end"
                >
                    <router-link
                        v-if="isRole(action)"
                        to="/route-and-lead/add"
                        class="btn btn-primary px-3 btn-pesanan d-flex align-items-center justify-content-center"
                    >
                        <i class="pi pi-plus me-1 size-12"></i> Tambah Data
                    </router-link>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="container-fluid">
                    <div class="row gy-3">
                        <div class="col-12 col-lg-4">
                            <label class="fw-bold mb-1">Layanan</label>
                            <multiselect
                                v-model="service"
                                :options="services"
                                label="name"
                                track-by="id"
                                placeholder="Pilih layanan"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-12 col-lg-4">
                            <label class="fw-bold mb-1">Pilih Kota Asal</label>
                            <multiselect
                                v-model="originRegency"
                                :options="regencies"
                                label="name"
                                track-by="id"
                                placeholder="Cari dan pilih kota asal"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-12 col-lg-4">
                            <label class="fw-bold mb-1"
                                >Pilih Kota Tujuan</label
                            >
                            <multiselect
                                v-model="destinationRegency"
                                :options="regencies"
                                label="name"
                                track-by="id"
                                placeholder="Cari dan pilih kota asal"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="d-flex flex-column flex-lg-row gap-2">
                                <button
                                    class="btn btn-outline-yellow flex-grow-1"
                                    :disabled="
                                        !service &&
                                        !originRegency &&
                                        !destinationRegency
                                    "
                                    @click="
                                        (service = null),
                                            (originRegency = null),
                                            (destinationRegency = null),
                                            getLists()
                                    "
                                >
                                    Clear
                                </button>
                                <button
                                    class="btn btn-green flex-grow-1"
                                    :disabled="
                                        !service &&
                                        !originRegency &&
                                        !destinationRegency
                                    "
                                    @click="getLists()"
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4 align-items-center">
                <div
                    class="col-12 col-lg-6 mb-3 mb-lg-0 d-flex justify-content-lg-start justify-content-center"
                >
                    <div>
                        Menampilkan
                        <span class="fw-bold"
                            >{{ pageStart }}-{{ pageEnd }}</span
                        >
                        Data dari
                        <span class="fw-bold">{{ lists.total_data }}</span>
                        Data
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div
                        class="d-flex align-items-center justify-content-lg-end justify-content-center"
                    >
                        <div class="me-2">Baris per halaman</div>
                        <select
                            class="form-select outline-yellow"
                            style="width: auto"
                            v-model="limit"
                            @change="onLimitChange"
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="isLoader">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Jenis Layanan
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Asal
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Tujuan
                                </th>
                                <th class="text-center" scope="col">
                                    Lead Time
                                </th>
                                <th class="text-center" scope="col">
                                    Harga Pengiriman
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="isRole(action)"
                                >
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(route, index) in lists.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>
                                    <p
                                        class="rounded-pill service text-center mb-0 px-3"
                                    >
                                        {{
                                            route.service_type
                                                .replace(/_/g, " ")
                                                .replace(/\b\w/g, (char) =>
                                                    char.toUpperCase()
                                                )
                                        }}
                                    </p>
                                </td>
                                <td>{{ route.regency }}</td>
                                <td>{{ route.dooring_regency }}</td>
                                <td>{{ route.leadtime }} hari</td>
                                <td>
                                    <div
                                        class="d-flex align-items-center justify-content-end"
                                    >
                                        {{ currency(route.service_price) }}
                                        <img
                                            v-if="isRole(action)"
                                            src="../assets/edit.svg"
                                            width="20"
                                            class="ms-2 cursor-pointer"
                                            @click="openSidebar(route.id)"
                                        />
                                    </div>
                                </td>
                                <td class="text-center" v-if="isRole(action)">
                                    <router-link
                                        :to="'/route-and-lead/' + route.id"
                                    >
                                        <img
                                            src="../assets/eye-action.svg"
                                            width="20"
                                        />
                                    </router-link>
                                    <div
                                        class="ms-2 d-inline-block cursor-pointer"
                                        @click="openModalConfirm(route.id)"
                                    >
                                        <img
                                            src="../assets/dashboard/trash-icon.png"
                                            width="20"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    class="text-center mt-5"
                    v-if="lists.list_data.length === 0"
                >
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar route management & lead time
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="lists.list_data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="getLists()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data route & Lead Time akan dihapus
                    </h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="deleteData()"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data route & Lead Time sudah dihapus
                    </h5>
                </div>
                <div class="mt-4">
                    <div
                        class="btn btn-primary w-100 btn-lg"
                        @click="
                            modalSuccess = false;
                            getLists();
                        "
                    >
                        Tutup
                    </div>
                </div>
            </div>
        </div>
        <div
            class="sidebar-overlay"
            v-if="isSidebarOpen"
            @click="closeSidebar()"
        ></div>
        <div
            class="sidebar"
            v-if="isSidebarOpen"
            :class="{ 'sidebar-open': isSidebarOpen }"
        >
            <div
                class="d-flex justify-content-between align-items-center p-3 border-bottom sticky-header"
            >
                <h5 class="mb-0 fw-bold">Edit Pricing</h5>
                <div
                    type="button"
                    class="btn-close"
                    @click="closeSidebar()"
                ></div>
            </div>
            <div class="p-3 mb-5-rem sidebar-body">
                <div v-if="isLoadingDetail">
                    <div class="d-flex justify-content-center mt-5">
                        <img src="../assets/empty.svg" alt="empty" />
                    </div>
                    <p class="text-gray text-center mt-5">
                        Sedang mengambil data
                    </p>
                </div>
                <label v-if="!isLoadingDetail" class="form-label"
                    >Harga Pengiriman <span class="text-yellow">*</span></label
                >
                <div class="input-group mb-3" v-if="!isLoadingDetail">
                    <span class="input-group-text">Rp</span>
                    <input
                        type="text"
                        class="form-control input-sidebar"
                        placeholder="Masukkan harga pengiriman"
                        v-model="detailPricing.price"
                    />
                </div>
                <hr v-if="!isLoadingDetail" />
                <h5 class="mb-3 fw-bold" v-if="!isLoadingDetail">
                    Data Pricing Vendor
                </h5>
                <div
                    v-if="
                        detailPricing?.transit_lists?.length === 0 &&
                        !isLoadingDetail
                    "
                >
                    <div class="d-flex justify-content-center mt-5">
                        <img src="../assets/empty.svg" alt="empty" />
                    </div>
                    <p class="text-gray text-center mt-5">
                        Tidak ditemukan data pricing dari vendor
                    </p>
                </div>
                <div v-if="!isLoadingDetail">
                    <div
                        class="mb-4"
                        v-for="(
                            transit_list, index
                        ) in detailPricing.transit_lists"
                        :key="index"
                    >
                        <div
                            class="d-flex justify-content-between header-sidebar px-3 py-2"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    src="../assets/origin-location.svg"
                                    alt="origin location"
                                    class="me-2"
                                />
                                {{ transit_list.origin }}
                            </div>
                            <img
                                src="../assets/arrow-location.svg"
                                alt="arrow location"
                            />
                            <div class="d-flex align-items-center">
                                <img
                                    src="../assets/destination-location.svg"
                                    alt="destination location"
                                    class="me-2"
                                />
                                {{ transit_list.destination }}
                            </div>
                        </div>
                        <div class="body-sidebar px-3 py-2">
                            <div
                                v-for="(vendor, index) in transit_list.vendors"
                                :key="index"
                            >
                                <div
                                    class="d-flex justify-content-between align-items-center my-2"
                                >
                                    <p class="mb-0">
                                        {{ vendor.vendor_name }}
                                    </p>
                                    <p class="text-primary mb-0">
                                        {{ currency(vendor.price) }}
                                    </p>
                                </div>
                                <hr
                                    v-if="
                                        index !==
                                        transit_list.vendors.length - 1
                                    "
                                    class="m-0"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3 sticky-footer" v-if="!isLoadingDetail">
                <button
                    class="btn btn-green w-100"
                    @click="openModalConfirmEdit(detailPricing.route_id)"
                >
                    Simpan Data
                </button>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirmEdit">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data pricing akan disimpan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="editPricing()"
                            :disabled="isLoadingEdit"
                        >
                            <span
                                v-if="isLoadingEdit"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccessEdit">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data pricing sudah disimpan
                    </h5>
                </div>
                <div class="mt-4">
                    <div
                        class="btn btn-primary w-100 btn-lg"
                        @click="
                            isSidebarOpen = false;
                            modalSuccessEdit = false;
                            getLists();
                        "
                    >
                        Tutup
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        Multiselect,
    },
    props: ["roles"],
    data() {
        return {
            isFilter: false,
            service: null,
            services: [
                {
                    id: "pengiriman_barang",
                    name: "Pengiriman Barang",
                },
                {
                    id: "pengiriman_motor",
                    name: "Pengiriman Motor",
                },
                {
                    id: "pengiriman_mobil",
                    name: "Pengiriman Mobil",
                },
                {
                    id: "sewa_container_20ft",
                    name: "Sewa Kontainer 20 Feet",
                },
                {
                    id: "sewa_container_40ft",
                    name: "Sewa Kontainer 40 Feet",
                },
            ],
            originRegency: null,
            destinationRegency: null,
            regencies: [],
            isLoadingDetail: false,
            isSidebarOpen: false,
            modalConfirmEdit: false,
            modalSuccessEdit: false,
            modalConfirm: false,
            modalSuccess: false,
            isLoadingEdit: false,
            price: null,
            pageStart: 0,
            pageEnd: 0,
            limit: 5,
            totalPage: 0,
            currentPage: 1,
            search: null,
            isLoader: false,
            lists: {
                list_data: [],
            },
            deleteId: null,
            isLoading: false,
            action: ["super-admin", "vendor-management", "ops-manager"],
            detailPricing: null,
            editId: null,
        };
    },
    created() {
        this.getLists();
        this.getRegency();
    },
    methods: {
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.regencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDetailPricing(value) {
            this.isLoadingDetail = true;
            let payload = {
                route_id: value,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/ho/pricing/detail`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.isLoadingDetail = false;
                    this.detailPricing = res.data.data;
                })
                .catch((err) => {
                    this.isLoadingDetail = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        openSidebar(value) {
            this.isSidebarOpen = true;
            this.getDetailPricing(value);
        },
        closeSidebar() {
            this.isSidebarOpen = false;
            this.detailPricing = null;
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        openModalConfirm(value) {
            this.deleteId = value;
            this.modalConfirm = true;
        },
        openModalConfirmEdit(value) {
            this.modalConfirmEdit = true;
            this.editId = value;
        },
        searchRoute() {
            this.currentPage = 1;
            this.getLists();
        },
        deleteData() {
            this.isLoading = true;

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/ho/routes/delete`,
                { route_id: this.deleteId },
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        onLimitChange() {
            this.currentPage = 1;
            this.getLists();
        },
        getLists() {
            let payload = {
                page: this.currentPage,
                search: this.search ? this.search : null,
                limit: this.limit ? this.limit : null,
                service_type: this.service?.id,
                origin_regency_id: this.originRegency?.id,
                destination_regency_id: this.destinationRegency?.id,
            };
            this.isLoader = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/ho/routes`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.isLoader = false;
                    this.lists = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    const totalItems = this.lists.list_data.length;
                    const totalData = res.data.data.total_data;
                    this.pageStart =
                        totalItems === 0
                            ? 0
                            : (this.currentPage - 1) * this.limit + 1;
                    this.pageEnd = Math.min(
                        Number(this.pageStart) + Number(this.limit) - 1,
                        totalData
                    );
                })
                .catch((err) => {
                    this.isLoader = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        editPricing() {
            this.isLoadingEdit = true;
            var data = {
                route_id: this.editId,
                price: this.detailPricing.price,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/ho/pricing/update`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoadingEdit = false;
                    this.modalConfirmEdit = false;
                    this.modalSuccessEdit = true;
                })
                .catch((err) => {
                    this.isLoadingEdit = false;
                    this.modalConfirmEdits = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.sticky-header {
    position: sticky;
    top: 0;
    background-color: #f9fafb;
    z-index: 1;
}
.sticky-footer {
    position: fixed;
    bottom: 0;
    background: #f9fafb;
    width: 100%;
    max-width: 500px;
    padding: 1rem;
    border-top: 1px solid #dee2e6;
}
.input-sidebar {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1040;
}
.sidebar {
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 500px;
    height: 100vh;
    background-color: #f9fafb;
    z-index: 1050;
    transition: right 0.3s ease-in-out;
    box-shadow: -8px 0 10px -6px rgba(0, 0, 0, 0.1);
    margin-top: 0;
}

.sidebar-open {
    right: 0;
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.width-m-100 {
    width: 100%;
    @media (min-width: 992px) {
        width: fit-content;
    }
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.modal-vue {
    position: fixed;
    z-index: 9999;
}
hr {
    color: #dee2e6 !important;
    margin: 1.5rem 0;
}
.btn-green:disabled {
    background: #e0e6eb !important;
    border: none !important;
    color: #849bae !important;
}
td {
    background-color: transparent !important;
}
th {
    background-color: #f3f5f7 !important;
}
.service {
    background-color: #cce7ff !important;
    border: 1px solid #99d0ff !important;
    color: #005299 !important;
}
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    background-color: transparent;
    color: #40bf76 !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.outline-yellow:focus {
    border: 1px solid #40bf76 !important;
    background-color: transparent;
    color: #40bf76 !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.btn-filter.transparent {
    background-color: transparent !important;
}
</style>
