<template>
    <div class="padding-container">
        <div class="box-manifest">
            <div class="mb-3">
                <h5 class="fw-bold">Pembayaran Vendor</h5>
            </div>
            <div class="row gy-3 mt-3">
                <div class="col-12 col-lg-5">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari nomor manifest/nama vendor"
                                v-model="search"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="filterData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span
                            class="search-icon left position-absolute top-50 start-0 translate-middle-y ms-3"
                        >
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="col-12 col-lg-3 col-xl-2">
                    <button
                        class="btn btn-outline-yellow btn-filter transparent w-100"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
                <div
                    class="col-12 col-lg-4 col-xl-5 d-flex justify-content-end"
                >
                    <button
                        class="btn btn-green d-flex align-items-center"
                        :disabled="isExport"
                        @click="getExport()"
                    >
                        <img
                            src="../assets/download.svg"
                            alt="download"
                            class="me-2"
                        />
                        Download Data
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="container-fluid">
                    <div class="row gy-3">
                        <div class="col-12 col-lg-4">
                            <label class="fw-bold mb-1"
                                >Tanggal Pembayaran</label
                            >
                            <DatePicker
                                v-model="paymentDate"
                                placeholder-text="Pilih tanggal pembayaran"
                            />
                        </div>
                        <div class="col-12 col-lg-4">
                            <label class="fw-bold mb-1"
                                >Status Pembayaran</label
                            >
                            <multiselect
                                v-model="paymentStatus"
                                :options="paymentStatuses"
                                label="name"
                                track-by="id"
                                placeholder="Pilih status pembayaran"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label class="fw-bold mb-1 invisible"
                                        >S</label
                                    >
                                    <button
                                        class="btn btn-outline-yellow w-100"
                                        @click="clearFilter()"
                                        :disabled="
                                            paymentDate == null &&
                                            paymentStatus == null
                                        "
                                    >
                                        Clear
                                    </button>
                                </div>
                                <div class="col-lg-6">
                                    <label class="fw-bold mb-1 invisible"
                                        >S</label
                                    >
                                    <button
                                        class="btn btn-green w-100"
                                        :disabled="
                                            paymentDate == null &&
                                            paymentStatus == null
                                        "
                                        @click="filterData()"
                                    >
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gy-3 align-items-center mt-3">
                <div class="col-12 col-lg-6">
                    <div class="text-center text-lg-start">
                        Menampilkan
                        <span class="fw-bold"
                            >{{ pageStart }}-{{ pageEnd }}</span
                        >
                        Data dari
                        <span class="fw-bold">{{ lists.total_data }}</span>
                        Data
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div
                        class="d-flex flex-column flex-lg-row align-items-center justify-content-lg-end"
                    >
                        <div class="mb-2 mb-lg-0 me-lg-2">
                            Baris per halaman
                        </div>
                        <select
                            class="form-control form-select w-select outline-yellow"
                            style="max-width: 100px"
                            v-model="limit"
                            @change="changeLength()"
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="list_loading">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nama Vendor
                                </th>
                                <th class="text-center" scope="col">
                                    Jumlah Manifest
                                </th>
                                <th class="text-center" scope="col">
                                    Total Pembayaran
                                </th>
                                <th class="text-center" scope="col">
                                    Status Pembayaran
                                </th>
                                <th class="text-center" scope="col">
                                    Tanggal Pembayaran
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(list, index) in lists.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>
                                    <router-link
                                        :to="'/vendor-payment/' + list.id"
                                        class="text-link"
                                    >
                                        {{ list.vendor_name }}
                                    </router-link>
                                </td>
                                <td>
                                    <div
                                        class="d-flex align-items-center justify-content-center"
                                    >
                                        {{ list.manifest_count }} Manifest
                                        <img
                                            src="../assets/information-blue.svg"
                                            alt="informaation blue"
                                            class="ms-2"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="right"
                                            data-bs-html="true"
                                            :title="
                                                generateTooltip(list.manifests)
                                            "
                                        />
                                    </div>
                                </td>
                                <td class="text-end">
                                    {{ currency(list.total_payment) }}
                                </td>
                                <td class="text-center">
                                    <div
                                        class="paid-badge rounded-pill"
                                        v-if="list.paid_at"
                                    >
                                        Lunas
                                    </div>
                                    <div
                                        class="unpaid-badge rounded-pill"
                                        v-else
                                    >
                                        Belum Lunas
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{ list.paid_at ? list.paid_at : "-" }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="lists.list_data.length == 0"
                    class="text-center mt-5"
                >
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar vendor
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="lists.list_data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="getLists()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from "../components/DatePicker/DatePicker.vue";
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";
import { Tooltip } from "bootstrap";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        DatePicker,
        Multiselect,
    },
    data() {
        return {
            paymentDate: null,
            paymentStatus: this.$route.query.status
                ? { id: "pending", name: "Belum Lunas" }
                : null,
            paymentStatuses: [
                {
                    name: "Lunas",
                    id: "paid",
                },
                {
                    name: "Belum Lunas",
                    id: "pending",
                },
            ],
            pageStart: 0,
            pageEnd: 0,
            isFilter: this.$route.query.status ? true : false,
            search: "",
            limit: 5,
            totalPage: 0,
            currentPage: 1,
            lists: {
                list_data: [],
            },
            list_loading: false,
            isExport: false,
        };
    },
    watch: {
        paymentDate(newVal) {
            if (newVal === null && this.paymentStatus === null) {
                this.getLists();
            }
        },
        paymentStatus(newVal) {
            if (newVal === null && this.paymentDate === null) {
                this.getLists();
            }
        },
    },
    created() {
        if (window.performance && window.performance.navigation.type === 1) {
            this.$router.replace({ query: {} });
        }
        this.getLists();
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        generateTooltip(manifests) {
            return manifests
                .map((manifest, index) => {
                    const isLast = index === manifests.length - 1;
                    const separator = isLast ? "" : "<hr class='my-0'>";
                    return `<p class='my-1'>${manifest.code}</p>${separator}`;
                })
                .join("");
        },
        changeLength() {
            this.currentPage = 1;
            this.getLists();
        },
        clearFilter() {
            this.paymentDate = null;
            this.paymentStatus = null;
            this.currentPage = 1;
            const currentQuery = { ...this.$route.query };
            delete currentQuery.status;
            this.$router.replace({ query: currentQuery });
        },
        filterData() {
            this.currentPage = 1;
            this.getLists();
        },
        goToDetail(item) {
            this.$router.push({
                name: "customer-payment-detail",
                query: {
                    order_id: item.order_id,
                },
            });
        },
        getLists() {
            let payload = {
                search: this.search,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
                date: this.paymentDate
                    ? this.formatDate(this.paymentDate)
                    : null,
                payment_status: this.paymentStatus
                    ? this.paymentStatus.id
                    : null,
            };
            this.list_loading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/vendors/payment`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.lists = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    const totalItems = this.lists.list_data.length;
                    const totalData = res.data.data.total_data;
                    this.pageStart =
                        totalItems === 0
                            ? 0
                            : (this.currentPage - 1) * this.limit + 1;
                    this.pageEnd = Math.min(
                        Number(this.pageStart) + Number(this.limit) - 1,
                        totalData
                    );
                    this.list_loading = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.list_loading = false;
                });
        },
        formatDate(date) {
            const localDate = new Date(date);
            const day = String(localDate.getDate()).padStart(2, "0");
            const month = String(localDate.getMonth() + 1).padStart(2, "0");
            const year = localDate.getFullYear();
            return `${year}-${month}-${day}`;
        },
        getExport() {
            this.isExport = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/payment/export`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.exportData = res.data;
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "Pembayaran Vendor" + ".xls");
                    document.body.appendChild(link);
                    link.click();
                    this.isExport = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.list_loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.unpaid-badge {
    border: 1px solid #ffcf99;
    background-color: #ffe7cc;
    color: #995100;
}
.paid-badge {
    border: 1px solid #b2e6c8;
    background-color: #d9f2e4;
    color: #267347;
}
.badge-darat {
    border: 1px solid #c2cdd6;
    background-color: #e0e6eb;
    color: #005299;
}
.badge-laut {
    border: 1px solid #f1eb74;
    background-color: #faf8d1;
    color: #8b850e;
}
.badge-udara {
    border: 1px solid #99d0ff;
    background-color: #cce7ff;
    color: #3e4e5c;
}
.btn-filter.transparent {
    background: transparent !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
td {
    background-color: transparent !important;
}
th {
    background-color: #f3f5f7 !important;
}
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
.filter-button {
    display: flex;
    gap: 1rem;
}
.btn-pesanan {
    width: 100%;
}
@media only screen and (min-width: 992px) {
    .btn-pesanan {
        width: fit-content;
    }
    .btn-pesanan-disabled {
        width: fit-content;
    }
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.outline-yellow:focus {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.radius-select {
    display: flex;
    border: 1px solid #d1d0d4 !important;
    padding: 0px 7px 0 7px !important;
    min-height: 35px !important;
    border-radius: 5px;
}
.scroll-select {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.scroll-select .placeholder-custom {
    margin-bottom: 0;
    color: #adadad;
}
.dropdown-menu {
    padding: 5px 10px 0px 10px;
}
</style>
