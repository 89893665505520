<template>
    <div class="padding-container">
        <div class="content-load" v-if="detail_loading"></div>
        <div id="loader" v-if="detail_loading"></div>
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="row mt-3">
            <div class="col-lg-6 my-2">
                <div class="row flex-column">
                    <div class="col-lg-12 my-2">
                        <div class="box-manifest h-100">
                            <h5 class="mb-0 fw-bold">Informasi Pengirim</h5>
                            <table
                                class="w-100 ps-0 fw-bold mt-3"
                                cellpadding="10"
                            >
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        Nama Pengirim
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail.information_sender.name
                                                ? dataDetail.information_sender
                                                      .name
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        No. Telp Pengirim
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        +{{
                                            dataDetail.information_sender.phone
                                                ? dataDetail.information_sender
                                                      .phone
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-150 fw-medium">
                                        Alamat Pengirim
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail.information_sender
                                                .address
                                                ? dataDetail.information_sender
                                                      .address
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12 my-2">
                        <div class="box-manifest h-100">
                            <h5 class="mb-0 fw-bold">Informasi Pesanan</h5>
                            <table
                                class="w-100 ps-0 fw-bold mt-3"
                                cellpadding="10"
                            >
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        Tanggal Dibuat
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail.information_order
                                                .created_at
                                                ? dataDetail.information_order
                                                      .created_at
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr
                                    class="border-bottom"
                                    v-if="dataDetail.information_order.lead_id"
                                >
                                    <td class="ps-0 w-150 fw-medium">
                                        Deal ID
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <router-link
                                            class="text-primary"
                                            :to="
                                                `/data/leads/detail?lead_id=` +
                                                dataDetail.information_order
                                                    .lead_id
                                            "
                                        >
                                            <u>
                                                {{
                                                    dataDetail.information_order
                                                        .deal_id
                                                        ? dataDetail
                                                              .information_order
                                                              .deal_id
                                                        : "-"
                                                }}
                                                <i
                                                    class="pi pi-arrow-up-right me-1 size-12"
                                                ></i>
                                            </u>
                                        </router-link>
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        Order ID
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail.information_order
                                                .order_id
                                                ? dataDetail.information_order
                                                      .order_id
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        Jenis Pesanan
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span
                                            class="tag mb-0 pb-0 capitalize blue-cro rounded-pill"
                                        >
                                            {{
                                                dataDetail.order_type === "reg"
                                                    ? "Regular"
                                                    : dataDetail.order_type ===
                                                      "walk_in"
                                                    ? "Walk In"
                                                    : "-"
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-150 fw-medium">
                                        Jenis Layanan
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold capitalize">
                                        {{
                                            dataDetail.information_order
                                                .service_type
                                                ? dataDetail.information_order.service_type
                                                      .split("_")
                                                      .join(" ")
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12 my-2">
                        <div class="box-manifest h-100">
                            <h5 class="mb-0 fw-bold">Informasi Pembayaran</h5>
                            <table
                                class="w-100 ps-0 fw-bold mt-3"
                                cellpadding="10"
                            >
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        Jenis Pembayaran
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail.payment
                                                ? "Virtual Account"
                                                : dataDetail.bank_transfer
                                                ? `Bank Transfer - ${dataDetail.bank_transfer.bank_name}`
                                                : "Tunai"
                                        }}
                                    </td>
                                </tr>
                                <tr
                                    :class="{
                                        'border-bottom':
                                            dataDetail?.payment ||
                                            dataDetail?.bank_transfer,
                                    }"
                                >
                                    <td class="ps-0 w-150 fw-medium">
                                        Status Pembayaran
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <div class="d-inline-grid">
                                            <router-link
                                                :to="
                                                    '/order/cashier/va/' +
                                                    dataDetail.information_order
                                                        .order_id
                                                "
                                                class="text-link text-center mb-2"
                                                v-if="
                                                    dataDetail?.payment
                                                        ?.status === 'pending'
                                                "
                                            >
                                                Virtual Account
                                            </router-link>
                                            <span
                                                class="badge-unpaid rounded-pill px-4 justify-content-center d-flex"
                                                v-if="
                                                    dataDetail?.payment
                                                        ?.status === 'pending'
                                                "
                                            >
                                                <p class="mb-0">Unpaid</p>
                                            </span>
                                        </div>
                                        <span
                                            class="badge-paid rounded-pill px-4 justify-content-center"
                                            v-if="
                                                dataDetail?.payment?.status ===
                                                    'success' ||
                                                (!dataDetail?.payment &&
                                                    !dataDetail.bank_transfer)
                                            "
                                        >
                                            Paid
                                        </span>
                                        <div
                                            class="d-inline-grid"
                                            v-if="dataDetail?.bank_transfer"
                                        >
                                            <span
                                                class="rounded-pill px-4 justify-content-center d-flex"
                                                :class="{
                                                    'regular-badge':
                                                        dataDetail
                                                            ?.bank_transfer
                                                            ?.status ===
                                                        'need_approved',
                                                    'badge-unpaid':
                                                        dataDetail
                                                            ?.bank_transfer
                                                            ?.status ===
                                                        'pending',
                                                    'badge-paid':
                                                        dataDetail
                                                            ?.bank_transfer
                                                            ?.status ===
                                                        'success',
                                                    'badge-reject':
                                                        dataDetail
                                                            ?.bank_transfer
                                                            ?.status ===
                                                        'decline',
                                                }"
                                            >
                                                <p class="mb-0">
                                                    {{
                                                        dataDetail
                                                            ?.bank_transfer
                                                            ?.status ===
                                                        "need_approved"
                                                            ? "Need Approve"
                                                            : dataDetail
                                                                  ?.bank_transfer
                                                                  ?.status ===
                                                              "success"
                                                            ? "Paid"
                                                            : dataDetail
                                                                  ?.bank_transfer
                                                                  ?.status ===
                                                              "pending"
                                                            ? "Unpaid"
                                                            : dataDetail
                                                                  ?.bank_transfer
                                                                  ?.status ===
                                                              "decline"
                                                            ? "Reject"
                                                            : ""
                                                    }}
                                                </p>
                                            </span>
                                            <router-link
                                                v-if="
                                                    !isRole(approval) &&
                                                    dataDetail?.bank_transfer
                                                        ?.status !== 'success'
                                                "
                                                :to="
                                                    '/order/cashier/va/' +
                                                    dataDetail
                                                        ?.information_order
                                                        ?.order_id
                                                "
                                                class="text-link text-center mt-2"
                                            >
                                                Detail Pembayaran
                                            </router-link>
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    class="border-bottom"
                                    v-if="
                                        dataDetail?.payment ||
                                        dataDetail?.bank_transfer
                                    "
                                >
                                    <td class="ps-0 w-150 fw-medium">
                                        Tanggal Pembayaran
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail?.payment?.payment_at
                                                ? dataDetail?.payment
                                                      ?.payment_at
                                                : dataDetail?.bank_transfer
                                                      ?.payment_at
                                                ? dataDetail?.bank_transfer
                                                      ?.payment_at
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr
                                    class="border-bottom"
                                    v-if="
                                        dataDetail?.bank_transfer?.status ===
                                        'decline'
                                    "
                                >
                                    <td class="ps-0 w-150 fw-medium">
                                        Ditolak Oleh
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail?.bank_transfer
                                                ?.decline_by
                                        }}
                                    </td>
                                </tr>
                                <tr
                                    class="border-bottom"
                                    v-if="
                                        dataDetail?.bank_transfer?.status ===
                                        'decline'
                                    "
                                >
                                    <td class="ps-0 w-150 fw-medium">
                                        Tanggal Penolakan
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail?.bank_transfer
                                                ?.decline_at
                                        }}
                                    </td>
                                </tr>
                                <tr
                                    v-if="
                                        dataDetail?.bank_transfer?.status ===
                                        'decline'
                                    "
                                >
                                    <td class="ps-0 w-150 fw-medium">
                                        Alasan Penolakan
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{ dataDetail?.bank_transfer?.reason }}
                                    </td>
                                </tr>
                                <tr
                                    class="border-bottom"
                                    v-if="
                                        dataDetail?.bank_transfer?.status ===
                                        'success'
                                    "
                                >
                                    <td class="ps-0 w-150 fw-medium">
                                        Disetujui Oleh
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail?.bank_transfer
                                                ?.approve_by
                                        }}
                                    </td>
                                </tr>
                                <tr
                                    v-if="
                                        dataDetail?.bank_transfer?.status ===
                                        'success'
                                    "
                                >
                                    <td class="ps-0 w-150 fw-medium">
                                        Tanggal Persetujuan
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{ dataDetail?.bank_transfer?.paid_at }}
                                    </td>
                                </tr>
                            </table>
                            <h5
                                class="mb-3 fw-bold mt-3"
                                v-if="
                                    dataDetail?.bank_transfer?.status ===
                                        'need_approved' ||
                                    dataDetail?.bank_transfer?.status ===
                                        'success' ||
                                    dataDetail?.bank_transfer?.status ===
                                        'pending' ||
                                    dataDetail?.bank_transfer?.status ===
                                        'decline'
                                "
                            >
                                Bukti Pembayaran
                            </h5>
                            <div
                                class="box"
                                v-if="
                                    dataDetail?.bank_transfer?.status ===
                                    'pending'
                                "
                            >
                                <div class="d-flex justify-content-center">
                                    <img
                                        src="../assets/empty.svg"
                                        alt="empty"
                                    />
                                </div>
                                <p class="text-gray text-center mt-5">
                                    Belum Ada Pembayaran
                                </p>
                            </div>
                            <div
                                class="position-relative rounded cursor-pointer"
                                v-if="
                                    dataDetail?.bank_transfer?.status ===
                                        'need_approved' ||
                                    dataDetail?.bank_transfer?.status ===
                                        'success' ||
                                    dataDetail?.bank_transfer?.status ===
                                        'decline'
                                "
                                @click="openPreview"
                            >
                                <img
                                    :src="dataDetail?.bank_transfer?.attachment"
                                    alt="bukti pembayaran"
                                    class="w-100 object-fit-cover preview-proof rounded"
                                />
                                <div
                                    class="position-absolute top-50 start-50 translate-middle"
                                >
                                    <div class="d-flex justify-content-center">
                                        <img
                                            src="../assets/eye-preview.svg"
                                            alt="eye preview"
                                        />
                                    </div>
                                    <p class="mb-0 text-white">
                                        Lihat Bukti Bayar
                                    </p>
                                </div>
                            </div>
                            <div
                                class="row mt-3"
                                v-if="
                                    isRole(approval) &&
                                    dataDetail?.bank_transfer?.status ===
                                        'need_approved'
                                "
                            >
                                <div class="col-lg-6">
                                    <button
                                        class="btn btn-orange w-100"
                                        @click="modalConfirmDecline = true"
                                    >
                                        Tolak Pembayaran
                                    </button>
                                </div>
                                <div class="col-lg-6">
                                    <button
                                        class="btn btn-green w-100"
                                        @click="modalConfirm = true"
                                    >
                                        Setujui Pembayaran
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 my-2">
                <div class="row flex-column">
                    <div class="col-lg-12 my-2">
                        <div class="box-manifest h-100">
                            <h5 class="mb-0 fw-bold">Informasi Penerima</h5>
                            <table
                                class="w-100 ps-0 fw-bold mt-3"
                                cellpadding="10"
                            >
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        Nama Penerima
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail.information_receiver.name
                                                ? dataDetail
                                                      .information_receiver.name
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        No. Telp Penerima
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        +{{
                                            dataDetail.information_receiver
                                                .phone
                                                ? dataDetail
                                                      .information_receiver
                                                      .phone
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-150 fw-medium">
                                        Alamat Penerima
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail.information_receiver
                                                .address
                                                ? dataDetail
                                                      .information_receiver
                                                      .address
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12 my-2">
                        <div class="box-manifest h-100">
                            <h5 class="mb-0 fw-bold">
                                Rincian Biaya Pengiriman
                            </h5>
                            <table
                                class="w-100 ps-0 fw-bold mt-3"
                                cellpadding="10"
                            >
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        Biaya Pengiriman
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold">
                                            {{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .service
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr
                                    class="border-bottom"
                                    v-if="
                                        dataDetail.information_order
                                            .service_type ===
                                            'pengiriman_barang' ||
                                        dataDetail.information_order
                                            .service_type === 'pengiriman_motor'
                                    "
                                >
                                    <td class="ps-0 w-150 fw-medium">
                                        Biaya Packing
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold">
                                            {{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .packing
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-150 fw-medium">
                                        Biaya Jasa Tambahan
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold">
                                            {{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .additional
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr
                                    v-for="(additional, index) in dataDetail
                                        .detail_payment.additionals"
                                    :key="index"
                                >
                                    <td class="w-150 fw-medium">
                                        {{ additional.packing }}
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold">{{
                                            currency(additional.price)
                                        }}</span>
                                    </td>
                                </tr>
                                <tr class="border-bottom border-top">
                                    <td class="ps-0 w-150 fw-medium">Diskon</td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold text-yellow">
                                            -{{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .discount
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-150 fw-medium">
                                        Subtotal
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold">
                                            {{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .service +
                                                        dataDetail
                                                            .detail_payment
                                                            .packing +
                                                        dataDetail?.detail_payment?.additionals?.reduce(
                                                            (
                                                                total,
                                                                additional
                                                            ) =>
                                                                total +
                                                                additional.price,
                                                            0
                                                        ) -
                                                        dataDetail
                                                            .detail_payment
                                                            .discount
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        class="ps-0 w-150 fw-medium text-yellow"
                                    >
                                        PPH
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold text-yellow">
                                            -{{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .pph
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">PPN</td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold">
                                            {{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .ppn
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-150 fw-bold">
                                        Total Pembayaran
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold text-green">
                                            {{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .total
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="box-manifest mt-4"
            v-if="
                dataDetail.information_order.service_type ===
                'pengiriman_barang'
            "
        >
            <h5 class="fw-bold mb-0">Daftar Barang</h5>
            <div class="table-responsive-custom">
                <table class="table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th class="fw-medium">Koli</th>
                            <th class="fw-medium">
                                Deskripsi<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat (Kg)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Panjang (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Lebar (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Tinggi (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat Volume (Kg)<span class="text-danger"
                                    >*</span
                                >
                            </th>
                            <th class="fw-medium">
                                Kubikasi (m3)<span class="text-danger">*</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody
                        class="padding-body"
                        v-for="(item, index) in dataDetail.items"
                        :key="index"
                    >
                        <tr>
                            <td class="custom">
                                <div class="form-box w-koli">
                                    {{ item.qty }}
                                </div>
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    :value="item.description"
                                    class="form-control"
                                    disabled
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="item.description"
                                />
                            </td>
                            <td class="custom">
                                <div
                                    class="form-box"
                                    v-bind:class="{
                                        green:
                                            item.weight >
                                            Number(item.weight_volume),
                                    }"
                                >
                                    {{ item.weight }}
                                </div>
                            </td>
                            <td class="custom">
                                <div class="form-box">{{ item.length }}</div>
                            </td>
                            <td class="custom">
                                <div class="form-box">{{ item.width }}</div>
                            </td>
                            <td class="custom">
                                <div class="form-box">{{ item.height }}</div>
                            </td>
                            <td class="custom">
                                <div
                                    class="form-box"
                                    v-bind:class="{
                                        green:
                                            Number(item.weight_volume) >
                                            item.weight,
                                    }"
                                >
                                    {{ item.weight_volume }}
                                </div>
                            </td>
                            <td class="custom">
                                <div class="d-flex align-items-center">
                                    <div class="form-box">
                                        {{ item.cubic }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="custom" colspan="8">
                                <div class="w-300">
                                    <label class="fw-medium mb-1">
                                        Perlindungan Barang
                                    </label>
                                    <div
                                        class="form-box gray"
                                        v-if="!item?.packings?.length"
                                    >
                                        Tidak ada perlindungan barang
                                    </div>
                                    <div class="form-box mt-3" v-else>
                                        <div
                                            class="d-flex justify-content-between my-1"
                                            v-for="(
                                                packing, index
                                            ) in item.packings"
                                            :key="index"
                                        >
                                            <p class="mb-0 text-capitalize">
                                                {{
                                                    packing.packing.replace(
                                                        /_/g,
                                                        " "
                                                    )
                                                }}
                                            </p>
                                            <p class="mb-0">
                                                {{ currency(packing.price) }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr class="mt-3" />
            <h5 class="fw-bold mb-0">Hasil</h5>
            <div class="table-responsive-custom">
                <table class="result table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th scope="col" class="fw-medium">
                                Berat Aktual (Kg)
                            </th>
                            <th scope="col" class="fw-medium">
                                Berat Volume (Kg)
                            </th>
                            <th scope="col" class="fw-medium">Kubikasi (m3)</th>
                            <th scope="col" class="fw-medium">
                                Charge Weight (Kg)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-box">
                                    {{ dataDetail.total_item.actual_weight }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{
                                        dataDetail.total_item.actual_weight_volume.toFixed(
                                            2
                                        )
                                    }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{
                                        dataDetail.total_item.actual_cubic.toFixed(
                                            2
                                        )
                                    }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box green">
                                    {{
                                        dataDetail.total_item.charge_weight.toFixed(
                                            2
                                        )
                                    }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr class="mt-3" />
            <label class="fw-medium mb-1"> Catatan </label>
            <div class="form-box">
                {{ dataDetail?.note ? dataDetail?.note : "-" }}
            </div>
            <div
                class="d-flex justify-content-end mt-4"
                v-if="
                    dataDetail?.payment?.status !== 'pending' && isRole(action)
                "
            >
                <button
                    @click="
                        downloadInvoice(dataDetail?.information_order?.order_id)
                    "
                    class="btn btn-green d-flex align-items-center px-5 py-2"
                >
                    <img src="../assets/print.svg" alt="print" class="me-2" />
                    Cetak Invoice
                </button>
            </div>
        </div>
        <div
            class="box-manifest mt-3"
            v-if="
                dataDetail.information_order.service_type === 'pengiriman_motor'
            "
        >
            <h5 class="mb-0 fw-bold">
                Detail Pengiriman Motor <span class="text-danger">*</span>
            </h5>
            <div class="row">
                <div class="mt-3 col-lg-6">
                    <label class="mb-2"
                        >Tipe Motor <span class="text-yellow">*</span></label
                    >
                    <div class="form-box">{{ dataDetail.vehicle }}</div>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="mb-2"
                        >Kapasitas Motor
                        <span class="text-yellow">*</span></label
                    >
                    <div class="form-box">
                        {{
                            dataDetail.cc === "lower"
                                ? "100 CC-149 CC"
                                : dataDetail.cc === "middle"
                                ? "150 CC - 249 CC"
                                : "Diatas 250 CC"
                        }}
                    </div>
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="mb-2">Perlindungan Barang </label>
                    <div class="form-box">
                        {{
                            dataDetail.protections
                                .map((item) =>
                                    item.protection
                                        .replace(/_/g, " ")
                                        .replace(/\b\w/g, (char) =>
                                            char.toUpperCase()
                                        )
                                )
                                .join(", ")
                        }}
                    </div>
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="mb-2">Deskripsi</label>
                    <div class="form-box">
                        {{ dataDetail.note ? dataDetail.note : "-" }}
                    </div>
                </div>
                <div
                    class="d-flex justify-content-end mt-4"
                    v-if="
                        dataDetail?.payment?.status !== 'pending' &&
                        isRole(action)
                    "
                >
                    <button
                        @click="
                            downloadInvoice(
                                dataDetail?.information_order?.order_id
                            )
                        "
                        class="btn btn-green d-flex align-items-center px-5 py-2"
                    >
                        <img
                            src="../assets/print.svg"
                            alt="print"
                            class="me-2"
                        />
                        Cetak Invoice
                    </button>
                </div>
            </div>
        </div>
        <div
            class="box-manifest mt-3"
            v-if="
                dataDetail.information_order.service_type === 'pengiriman_mobil'
            "
        >
            <h5 class="mb-0 fw-bold">
                Detail Pengiriman Mobil <span class="text-danger">*</span>
            </h5>
            <div class="row">
                <div class="mt-3 col-lg-12">
                    <label class="mb-2"
                        >Tipe Mobil <span class="text-yellow">*</span></label
                    >
                    <div class="form-box">
                        {{ dataDetail.vehicle }}
                    </div>
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="mb-2">Deskripsi</label>
                    <div class="form-box">
                        {{ dataDetail.note ? dataDetail.note : "-" }}
                    </div>
                </div>
                <div
                    class="d-flex justify-content-end mt-4"
                    v-if="
                        dataDetail?.payment?.status !== 'pending' &&
                        isRole(action)
                    "
                >
                    <button
                        @click="
                            downloadInvoice(
                                dataDetail?.information_order?.order_id
                            )
                        "
                        class="btn btn-green d-flex align-items-center px-5 py-2"
                    >
                        <img
                            src="../assets/print.svg"
                            alt="print"
                            class="me-2"
                        />
                        Cetak Invoice
                    </button>
                </div>
            </div>
        </div>
        <div
            class="d-flex justify-content-end mt-4"
            v-if="
                dataDetail?.payment?.status !== 'pending' &&
                dataDetail.information_order.service_type ===
                    'sewa_container_20ft' &&
                isRole(action)
            "
        >
            <button
                @click="
                    downloadInvoice(dataDetail?.information_order?.order_id)
                "
                class="btn btn-green d-flex align-items-center px-5 py-2"
            >
                <img src="../assets/print.svg" alt="print" class="me-2" />
                Cetak Invoice
            </button>
        </div>
        <div class="image-modal" v-if="showPreview" @click.self="closePreview">
            <div class="modal-content">
                <span class="close-button" @click="closePreview">&times;</span>
                <div class="image-viewer" ref="imageViewer">
                    <img
                        :src="dataDetail?.bank_transfer?.attachment"
                        ref="previewImage"
                        alt="bukti pembayaran preview"
                        @mousedown="startDrag"
                        @touchstart="startDrag"
                        @wheel="handleZoom"
                    />
                </div>
                <div class="zoom-controls">
                    <button @click="zoomIn">+</button>
                    <button @click="zoomOut">-</button>
                    <button @click="resetZoom">↻</button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Yakin Menyetujui Pembayaran Order?
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan bukti pembayaran sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            @click="modalConfirm = false"
                            href="javascript:void(0)"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeAction('success')"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isLoading"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isLoading"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Persetujuan Pembayaran berhasil disimpan
                    </h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/customer-payment"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirmDecline">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm modal-custom">
                <div class="d-flex justify-content-between mb-3">
                    <h5 class="fw-bold mb-0">Tolak Pembayaran</h5>
                    <h5
                        class="mb-0"
                        @click="(modalConfirmDecline = false), (reason = null)"
                    >
                        X
                    </h5>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-12">
                        <label class="fw-bold mb-1">
                            Pilih Alasan Penolakan
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <multiselect
                            v-model="reason"
                            :options="reasons"
                            label="name"
                            track-by="id"
                            placeholder="Pilih Alasan"
                            class="multi-form-custom magnifier"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button
                        @click="storeAction('decline')"
                        type="button"
                        class="btn btn-green px-3"
                        :disabled="isLoading || !reason"
                    >
                        <span
                            class="spinner-border spinner-border-sm me-2"
                            v-if="isLoading"
                        ></span>
                        Tolak Pembayaran
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccessDecline">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">Penolakan Order Berhasil</h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/customer-payment"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import { Tooltip } from "bootstrap";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    name: "OrderProcessDetail",
    props: ["roles"],
    data() {
        return {
            dataClaim: {},
            detail_loading: false,
            dataDetail: {
                information_sender: {},
                information_receiver: {},
                information_order: {},
                detail_payment: {},
                items: [],
                total_item: {},
            },
            action: ["cashier"],
            approval: ["finance"],
            showPreview: false,
            scale: 1,
            translateX: 0,
            translateY: 0,
            startX: 0,
            startY: 0,
            isDragging: false,
            lastTouchDistance: 0,
            modalConfirm: false,
            modalSuccess: false,
            modalConfirmDecline: false,
            modalSuccessDecline: false,
            isLoading: false,
            reason: null,
            reasons: [
                {
                    name: "Nominal Transfer Tidak Sesuai",
                    id: "Nominal Transfer Tidak Sesuai",
                },
                {
                    name: "Salah Unggah Bukti Transfer",
                    id: "Salah Unggah Bukti Transfer",
                },
                {
                    name: "Pembatalan Order",
                    id: "Pembatalan Order",
                },
                {
                    name: "Salah Tujuan Rekening",
                    id: "Salah Tujuan Rekening",
                },
            ],
        };
    },
    created() {
        this.getDetail();
    },
    mounted() {
        const dataClaim = sessionStorage.getItem("dataClaim");
        this.dataClaim = JSON.parse(dataClaim);

        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });

        window.addEventListener("mouseup", this.stopDrag);
        window.addEventListener("touchend", this.stopDrag);
        window.addEventListener("mousemove", this.drag);
        window.addEventListener("touchmove", this.drag);
        window.addEventListener("keydown", this.handleKeyDown);
    },
    beforeDestroy() {
        window.removeEventListener("mouseup", this.stopDrag);
        window.removeEventListener("touchend", this.stopDrag);
        window.removeEventListener("mousemove", this.drag);
        window.removeEventListener("touchmove", this.drag);
        window.removeEventListener("keydown", this.handleKeyDown);
    },
    methods: {
        openPreview() {
            this.showPreview = true;
            // Reset zoom dan posisi
            this.resetZoom();
            // Mencegah scrolling pada body saat modal terbuka
            document.body.style.overflow = "hidden";
        },
        closePreview() {
            this.showPreview = false;
            // Mengembalikan scrolling pada body
            document.body.style.overflow = "auto";
        },
        startDrag(event) {
            this.isDragging = true;

            if (event.type === "touchstart") {
                if (event.touches.length === 1) {
                    // Single touch for dragging
                    const touch = event.touches[0];
                    this.startX = touch.clientX - this.translateX;
                    this.startY = touch.clientY - this.translateY;
                } else if (event.touches.length === 2) {
                    // Two touches for pinch zoom
                    const touch1 = event.touches[0];
                    const touch2 = event.touches[1];
                    this.lastTouchDistance = Math.hypot(
                        touch2.clientX - touch1.clientX,
                        touch2.clientY - touch1.clientY
                    );
                }
            } else {
                this.startX = event.clientX - this.translateX;
                this.startY = event.clientY - this.translateY;
            }

            event.preventDefault();
        },
        stopDrag() {
            this.isDragging = false;
            this.lastTouchDistance = 0;
        },
        drag(event) {
            if (!this.isDragging || !this.showPreview) return;

            if (event.type === "touchmove") {
                if (event.touches.length === 1) {
                    // Single touch for dragging
                    const touch = event.touches[0];
                    this.translateX = touch.clientX - this.startX;
                    this.translateY = touch.clientY - this.startY;
                } else if (event.touches.length === 2) {
                    // Pinch zoom
                    const touch1 = event.touches[0];
                    const touch2 = event.touches[1];
                    const distance = Math.hypot(
                        touch2.clientX - touch1.clientX,
                        touch2.clientY - touch1.clientY
                    );

                    if (this.lastTouchDistance > 0) {
                        const delta = distance - this.lastTouchDistance;
                        const scaleFactor = 0.01;
                        if (delta > 0) {
                            // Zoom in
                            this.scale += delta * scaleFactor;
                        } else {
                            // Zoom out
                            this.scale += delta * scaleFactor;
                        }
                        // Limit scale
                        this.scale = Math.max(0.5, Math.min(5, this.scale));
                    }

                    this.lastTouchDistance = distance;

                    // Get midpoint position for centered zooming
                    const midX = (touch1.clientX + touch2.clientX) / 2;
                    const midY = (touch1.clientY + touch2.clientY) / 2;
                    this.startX = midX - this.translateX;
                    this.startY = midY - this.translateY;
                }
            } else {
                this.translateX = event.clientX - this.startX;
                this.translateY = event.clientY - this.startY;
            }

            this.updateImageTransform();
            event.preventDefault();
        },
        handleZoom(event) {
            if (!this.showPreview) return;

            const zoomIntensity = 0.1;
            if (event.deltaY < 0) {
                this.scale += zoomIntensity;
            } else {
                this.scale -= zoomIntensity;
            }

            // Limit scale
            this.scale = Math.max(0.5, Math.min(5, this.scale));

            this.updateImageTransform();
            event.preventDefault();
        },
        zoomIn(event) {
            if (this.scale < 5) {
                // Maximum zoom level
                this.scale += 0.2;
                this.updateImageTransform();
            }
            if (event) event.preventDefault();
        },
        zoomOut(event) {
            if (this.scale > 0.5) {
                // Minimum zoom level
                this.scale -= 0.2;
                this.updateImageTransform();
            }
            if (event) event.preventDefault();
        },
        resetZoom() {
            this.scale = 1;
            this.translateX = 0;
            this.translateY = 0;
            this.updateImageTransform();
        },
        updateImageTransform() {
            if (this.$refs.previewImage) {
                this.$refs.previewImage.style.transform = `translate(${this.translateX}px, ${this.translateY}px) scale(${this.scale})`;
            }
        },
        handleKeyDown(event) {
            if (!this.showPreview) return;

            switch (event.key) {
                case "Escape":
                    this.closePreview();
                    break;
                case "+":
                    this.zoomIn();
                    break;
                case "-":
                    this.zoomOut();
                    break;
                case "0":
                    this.resetZoom();
                    break;
                case "ArrowLeft":
                    this.translateX += 20;
                    this.updateImageTransform();
                    break;
                case "ArrowRight":
                    this.translateX -= 20;
                    this.updateImageTransform();
                    break;
                case "ArrowUp":
                    this.translateY += 20;
                    this.updateImageTransform();
                    break;
                case "ArrowDown":
                    this.translateY -= 20;
                    this.updateImageTransform();
                    break;
            }
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        downloadInvoice(id) {
            this.detail_loading = true;
            var data = {
                receipt_code: id,
            };
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/order/invoice`, data, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.detail_loading = false;
                    const link = res.data.data.link;
                    window.open(link, "_blank");
                })
                .catch((err) => {
                    this.detail_loading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        storeAction(action) {
            this.isLoading = true;
            var data = {
                order_id: this.dataDetail?.id,
                status: action,
                attachment:
                    this.dataDetail?.bank_transfer?.attachment.split(
                        "/payment_proof/"
                    )[1],
                reason: action == "success" ? null : this.reason.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/order/upload_proof/validation`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoading = false;
                    if (action === "success") {
                        this.modalConfirm = false;
                        this.modalSuccess = true;
                    } else {
                        this.modalConfirmDecline = false;
                        this.modalSuccessDecline = true;
                    }
                })
                .catch((err) => {
                    this.isLoading = false;
                    if (action === "success") {
                        this.modalConfirm = false;
                    } else {
                        this.modalConfirmDecline = false;
                    }
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDetail() {
            this.detail_loading = true;
            const param = this.$route.query;
            var payload = {
                order_id: param.order_id,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/order/detail`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.dataDetail = res.data.data;
                    this.detail_loading = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.detail_loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-custom {
    width: 35rem !important;
}
.btn-orange {
    background-color: #ff8600 !important;
    border: none !important;
    color: #fbfefc;
}
.btn-orange:focus {
    background-color: #ff8600 !important;
    border: none !important;
    color: #fbfefc !important;
}
.btn-orange:hover {
    background-color: #ff8600 !important;
    border: none !important;
    color: #fbfefc !important;
}
.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    color: white;
    font-size: 30px;
    cursor: pointer;
    z-index: 1001;
}

.image-viewer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image-viewer img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    transform-origin: center;
    transition: transform 0.1s ease;
    user-select: none;
}

.zoom-controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
}

.zoom-controls button {
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.preview-proof {
    height: 150px;
    filter: brightness(0.5);
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.badge-unpaid {
    border: 1px solid #ffcf99;
    background-color: #ffe7cc;
    color: #995100;
}
.badge-paid {
    border: 1px solid #b2e6c8;
    background-color: #d9f2e4;
    color: #267347;
}
.w-30 {
    width: 30px;
}
.w-150 {
    width: 150px;
}
.w-120 {
    width: 120px;
}
.table > :not(caption) > * > * {
    border: unset;
}
.table-bordered > :not(caption) > * {
    border: unset;
}
.table > :not(caption) > * > * {
    background-color: unset;
}
tr {
    border-radius: 10px !important;
}
tr td.custom {
    background-color: #ebf6ff !important;
}
.w-handling {
    width: 300px;
}
table tbody tr:first-child td:first-child {
    border-top-left-radius: 6px;
}

table tbody tr:first-child td:last-child {
    border-top-right-radius: 6px;
}

table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}
.padding-body::after {
    height: 10px;
    display: table-row;
    content: "";
}
.result.table > :not(caption) > * > * {
    padding: 0.2rem 0.5rem;
}
.form-control.green {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.form-control.green[disabled] {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.w-desc {
    width: 160px;
}
.w-koli {
    width: 60px;
}
.radius-select {
    border-radius: 10px;
    padding: 8px 12px;
    background: #f3f5f7;
    border: 1px solid #d1d0d4;
}
.w-300 {
    width: 300px;
}
.form-box {
    color: #000;
}
.form-box.gray {
    color: #a3b4c2;
}
.regular-badge {
    border: 1px solid #99d0ff;
    background-color: #cce7ff;
    color: #005299;
}
.badge-reject {
    border: 1px solid #ebadad;
    background-color: #f5d6d6;
    color: #7b1e1e;
}
</style>
