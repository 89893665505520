<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div
            class="d-flex flex-wrap justify-content-between align-items-center"
        >
            <div class="mb-3">
                <h5 class="fw-bold">
                    Halo {{ user.name }} 👋,
                    <span>Selamat Datang Kembali!</span>
                </h5>
                <p class="text-gray mb-0">
                    Menampilkan filter data tanggal
                    <span v-if="hasDateRange" class="fw-bold text-primary">
                        {{ formatDateRange(dateRange.from, dateRange.to) }}
                    </span>
                    <span class="fw-bold" v-else>{{
                        date.format("DD MMMM YYYY")
                    }}</span>
                </p>
            </div>
            <DateRangePicker
                v-model="dateRange"
                :onFilter="filterData"
                :onReset="resetData"
            />
        </div>
        <div class="row my-3">
            <div class="col-lg-6 my-3">
                <div class="box h-100 d-flex flex-column">
                    <p class="fw-bold">Total Pemasukan Hari Ini</p>
                    <div class="box-blue-dashboard">
                        <div class="d-flex align-items-center">
                            <img
                                src="../../assets/money-dashboard.svg"
                                alt="money dashboard"
                                class="me-3"
                            />
                            <div class="d-flex flex-column">
                                <router-link
                                    :to="{
                                        path: '/customer-payment',
                                        query: {
                                            date: new Date()
                                                .toISOString()
                                                .split('T')[0],
                                        },
                                    }"
                                    class="d-flex align-items-center"
                                >
                                    <h5
                                        class="mb-0 border-bottom text-white me-3"
                                    >
                                        {{ currency(datas?.income?.total) }}
                                    </h5>
                                    <img
                                        src="../../assets/arrow-white-dashboard.svg"
                                        alt="arrow white dashboard"
                                    />
                                </router-link>
                                <p
                                    class="mb-0 mt-2 text-white"
                                    v-if="datas?.income?.total === 0"
                                >
                                    Belum ada data pemasukan untuk hari ini
                                </p>
                            </div>
                        </div>
                    </div>
                    <p class="fw-bold mt-3">Resi Terbaru</p>
                    <div
                        v-if="datas?.income?.new_receipts.length === 0"
                        class="d-flex flex-column h-100 justify-content-center"
                    >
                        <div class="d-flex justify-content-center">
                            <img
                                src="../../assets/no-data-green.png"
                                width="100"
                                alt=""
                            />
                        </div>
                        <div class="mt-4 mb-4 text-gray text-center">
                            Belum ada resi pengiriman untuk hari ini
                        </div>
                    </div>
                    <div class="table-responsive-custom" v-else>
                        <table>
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Total Pembayaran</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(receipt, index) in datas?.income
                                        ?.new_receipts"
                                    :key="index"
                                >
                                    <td>{{ receipt.receipt_code }}</td>
                                    <td>
                                        {{ currency(receipt.total_payment) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 my-3">
                <div class="h-100">
                    <div
                        class="box-blue-bottom-dashboard"
                        :class="{ 'box-shadow': hasDateRange }"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                src="../../assets/graphic-dashboard.svg"
                                alt="graphic dashboard"
                            />
                            <h5 class="mb-0 ms-3">
                                {{ currency(datas?.omset?.total) }}
                            </h5>
                        </div>
                        <p class="mt-3" v-if="hasDateRange">
                            Omset
                            {{ formatDateRange(dateRange.from, dateRange.to) }}
                        </p>
                        <p class="mt-3" v-if="!hasDateRange">Omset Bulan Ini</p>
                        <p
                            class="text-gray"
                            v-if="
                                !hasDateRange && datas?.omset?.percentage === 0
                            "
                        >
                            Tidak ada data untuk dibandingkan
                        </p>
                        <p
                            class="text-gray"
                            v-if="
                                hasDateRange && datas?.omset?.percentage === 0
                            "
                        >
                            Tidak ada data periode ini untuk dibandingkan
                        </p>
                        <div
                            class="d-flex align-items-center"
                            v-if="datas?.omset?.percentage !== 0"
                        >
                            <div
                                v-if="datas?.omset?.percentage >= 0"
                                class="badge-up rounded px-3 py-1 d-flex align-items-center"
                            >
                                <img
                                    src="../../assets/arrow-badge-up-dashboard.svg"
                                    alt="arrow badge up dashboard"
                                />{{ Math.round(datas?.omset?.percentage) }}%
                            </div>
                            <div
                                v-if="datas?.omset?.percentage < 0"
                                class="badge-down rounded px-3 py-1 d-flex align-items-center"
                            >
                                <img
                                    src="../../assets/arrow-badge-down-dashboard.svg"
                                    alt="arrow badge down dashboard"
                                />{{ Math.round(datas?.omset?.percentage) }}%
                            </div>
                            <p class="mb-0 text-gray ms-3" v-if="!hasDateRange">
                                Dibandingkan bulan lalu
                            </p>
                            <p class="mb-0 text-gray ms-3" v-if="hasDateRange">
                                Dibandingkan dengan period sebelumnya
                            </p>
                        </div>
                    </div>
                    <div
                        class="box-green-bottom-dashboard my-3"
                        :class="{ 'box-shadow': hasDateRange }"
                    >
                        <p class="mb-0" v-if="hasDateRange">
                            Total Uang Masuk
                            {{ formatDateRange(dateRange.from, dateRange.to) }}
                        </p>
                        <p class="mb-0" v-if="!hasDateRange">
                            Total Uang Masuk Bulan Ini
                        </p>
                        <h5 class="my-3 fw-bold">
                            {{ currency(datas?.income_monthly?.total) }}
                        </h5>
                        <p
                            class="text-gray"
                            v-if="
                                !hasDateRange &&
                                datas?.income_monthly?.percentage === 0
                            "
                        >
                            Tidak ada data untuk dibandingkan
                        </p>
                        <p
                            class="text-gray"
                            v-if="
                                hasDateRange &&
                                datas?.income_monthly?.percentage === 0
                            "
                        >
                            Tidak ada data periode ini untuk dibandingkan
                        </p>
                        <div
                            class="d-flex align-items-center"
                            v-if="datas?.income_monthly?.percentage !== 0"
                        >
                            <div
                                v-if="datas?.income_monthly?.percentage >= 0"
                                class="badge-up rounded px-3 py-1 d-flex align-items-center"
                            >
                                <img
                                    src="../../assets/arrow-badge-up-dashboard.svg"
                                    alt="arrow badge up dashboard"
                                />{{
                                    Math.round(
                                        datas?.income_monthly?.percentage
                                    )
                                }}%
                            </div>
                            <div
                                v-if="datas?.income_monthly?.percentage < 0"
                                class="badge-down rounded px-3 py-1 d-flex align-items-center"
                            >
                                <img
                                    src="../../assets/arrow-badge-down-dashboard.svg"
                                    alt="arrow badge down dashboard"
                                />{{
                                    Math.round(
                                        datas?.income_monthly?.percentage
                                    )
                                }}%
                            </div>
                            <p class="mb-0 text-gray ms-3" v-if="!hasDateRange">
                                Dibandingkan bulan lalu
                            </p>
                            <p class="mb-0 text-gray ms-3" v-if="hasDateRange">
                                Dibandingkan dengan period sebelumnya
                            </p>
                        </div>
                    </div>
                    <div class="box-orange-bottom-dashboard">
                        <p>Unpaid Customer</p>
                        <router-link
                            class="d-flex align-items-center"
                            :to="{
                                path: '/customer-payment',
                                query: {
                                    status: 'pending',
                                },
                            }"
                        >
                            <h5 class="mb-0 text-orange me-3">
                                {{ currency(datas?.unpaid) }}
                            </h5>
                            <img
                                src="../../assets/arrow-orange-dashboard.svg"
                                alt="arrow orange dashboard"
                            />
                        </router-link>
                        <p class="text-gray mt-3" v-if="datas?.unpaid === 0">
                            Belum ada data untuk hari ini
                        </p>
                        <p
                            class="mt-3 text-gray mb-0"
                            v-if="datas?.unpaid !== 0"
                        >
                            Per hari ini
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 my-3">
                <div class="box-red h-100 d-flex">
                    <div class="d-flex align-items-center me-3">
                        <img src="../../assets/hat-bread.svg" alt="hat bread" />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                        <p class="text-red">Hutang Vendor</p>
                        <router-link
                            :to="{
                                path: '/vendor-payment',
                                query: {
                                    status: 'pending',
                                },
                            }"
                            class="d-flex"
                        >
                            <h5 class="text-red-thin me-3 mb-0">
                                {{ currency(datas?.debt_vendor) }}
                            </h5>
                            <img
                                src="../../assets/arrow-red-dashboard.svg"
                                alt="arrow red dashboard"
                            />
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 my-3">
                <div class="box h-100 d-flex">
                    <div class="d-flex align-items-center me-3">
                        <img
                            src="../../assets/hat-bread-green.svg"
                            alt="hat bread green"
                        />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                        <p class="text-red">Total Dibayarkan Ke Vendor</p>
                        <div class="d-flex">
                            <h5 class="me-3 mb-0 font-inherit">
                                {{ currency(datas?.debt_vendor_paid) }}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 my-3">
                <div
                    class="box h-100 d-flex flex-column justify-content-center"
                    :class="{ 'box-shadow': hasDateRange }"
                >
                    <div class="d-flex">
                        <img
                            src="../../assets/plane-dashboard.svg"
                            alt="plane dashboard"
                            class="me-3"
                        />
                        <div
                            class="d-flex flex-column justify-content-center w-100"
                        >
                            <p class="mb-2">Total Pendapatan Pengiriman</p>
                            <h5 class="fw-bold mb-0">
                                {{ currency(datas?.data_income?.service) }}
                            </h5>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex">
                        <img
                            src="../../assets/packing-dashboard.svg"
                            alt="packing dashboard"
                            class="me-3"
                        />
                        <div
                            class="d-flex flex-column justify-content-center w-100"
                        >
                            <p class="mb-2">Total Pendapatan Packing</p>
                            <h5 class="fw-bold mb-0">
                                {{ currency(datas?.data_income?.packing) }}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 my-3">
                <div class="box h-100" :class="{ 'box-shadow': hasDateRange }">
                    <div
                        class="d-flex align-items-center justify-content-between"
                    >
                        <p class="mb-0">Grafik Pendapatan</p>
                        <div class="position-relative">
                            <button
                                class="btn d-flex align-items-center btn-filter-chart px-4"
                                @click="isDropdownOpen = !isDropdownOpen"
                            >
                                <img
                                    src="../../assets/filter-dashboard.svg"
                                    alt="filter active dashboard"
                                    class="me-3"
                                />{{ selectedChartType }}
                            </button>
                            <div
                                class="box position-absolute z-1 mt-2"
                                v-if="isDropdownOpen"
                            >
                                <p
                                    class="cursor-pointer"
                                    @click="changeChartType('all')"
                                >
                                    Semua Pendapatan
                                </p>
                                <hr />
                                <p
                                    class="cursor-pointer"
                                    @click="changeChartType('service')"
                                >
                                    Pendapatan Pengiriman
                                </p>
                                <hr />
                                <p
                                    class="cursor-pointer"
                                    @click="changeChartType('packing')"
                                >
                                    Pendapatan Packing
                                </p>
                            </div>
                        </div>
                    </div>
                    <LineChart
                        :short-days="shortDays"
                        :full-days="fullDays"
                        :data="chart"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Api from "../../api/Api";
import "moment/locale/id";
import DateRangePicker from "../../components/DatePicker/DateRangePicker.vue";
import LineChart from "../../components/LineChart.vue";

moment.locale("id");

export default {
    components: {
        DateRangePicker,
        LineChart,
    },
    data() {
        return {
            isPageLoading: false,
            datas: null,
            dateRange: {
                from: null,
                to: null,
            },
            date: moment(),
            isDropdownOpen: false,
            shortDays: [],
            fullDays: [],
            chart: [],
            selectedChartType: "Semua Pendapatan",
        };
    },
    computed: {
        hasDateRange() {
            return this.dateRange && this.dateRange.from && this.dateRange.to;
        },
    },
    created() {
        this.getDatas();
        const userFromLocalStorage = localStorage.getItem("user");
        if (userFromLocalStorage) {
            this.user = JSON.parse(userFromLocalStorage);
        }
    },
    methods: {
        changeChartType(type) {
            this.isDropdownOpen = false;

            switch (type) {
                case "all":
                    this.selectedChartType = "Semua Pendapatan";
                    this.chart = this.datas.data_income_chart.datas;
                    break;
                case "service":
                    this.selectedChartType = "Pendapatan Pengiriman";
                    this.chart = this.datas.data_income_chart.services;
                    break;
                case "packing":
                    this.selectedChartType = "Pendapatan Packing";
                    this.chart = this.datas.data_income_chart.packings;
                    break;
            }
        },
        formatDateRange(fromDate, toDate) {
            if (!fromDate || !toDate) return "";

            const from = moment(fromDate);
            const to = moment(toDate);

            return `${from.format("D")} ${from.format(
                "MMMM YYYY"
            )} - ${to.format("D")} ${to.format("MMMM YYYY")}`;
        },
        filterData(dateRange) {
            this.dateRange = dateRange;
            this.getDatas();
        },
        resetData() {
            this.dateRange.from = null;
            this.dateRange.to = null;
            this.getDatas();
        },
        getDatas() {
            this.isPageLoading = true;

            let payload;

            if (this.dateRange && this.dateRange.from && this.dateRange.to) {
                payload = {
                    start_date: moment(this.dateRange.from).format(
                        "YYYY-MM-DD"
                    ),
                    end_date: moment(this.dateRange.to).format("YYYY-MM-DD"),
                };
            } else {
                payload = {
                    start_date: this.date.format("YYYY-MM-DD"),
                    end_date: this.date.format("YYYY-MM-DD"),
                };
            }

            Api.get(`${process.env.VUE_APP_SERVICE_URL}/dashboard/finance`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.datas = res.data.data;
                    this.shortDays = res.data.data.data_income_chart.fields;
                    this.fullDays = res.data.data.data_income_chart.labels;
                    this.chart = res.data.data.data_income_chart.datas;
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-filter-chart {
    border: 1px solid #40bf76;
    background-color: transparent;
    color: #40bf76;
}
.btn-filter-chart:hover {
    border: 1px solid #40bf76;
    background-color: transparent;
    color: #40bf76;
}
.font-inherit {
    color: #212529;
}
.text-red-thin {
    color: #cc0000;
    border-bottom: 1px solid #cc0000;
}
.text-red {
    color: #3e4e5c;
}
.text-orange {
    color: #bab112;
    border-bottom: 1px solid #bab112;
}
.text-gray {
    color: #a3b4c2 !important;
}
.badge-up {
    border: 1px solid #b2e6c8;
    background-color: #d9f2e4;
    color: #267347;
}
.badge-down {
    border: 1px solid #ebadad;
    background-color: #f5d6d6;
    color: #7b1e1e;
}
.table-responsive-custom table {
    width: 100%;
    border-collapse: collapse;
}

.table-responsive-custom thead tr {
    border-top: 1px solid #c1cdd7;
    border-bottom: 1px solid #c1cdd7;
    background-color: #f3f5f7;
}

.table-responsive-custom th,
.table-responsive-custom td {
    padding: 8px;
    text-align: left;
}
.box-blue-dashboard {
    background-color: #1291ff;
    border-radius: 16px;
    padding: 15px 15px;
}
.box-red {
    background-color: #ffe5e5;
    border-radius: 16px;
    padding: 15px 15px;
    border: 1px solid #c1cdd7;
}
.box-blue-bottom-dashboard {
    border: 1px solid #c1cdd7;
    border-bottom: 3px solid #66b8ff;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 15px 15px;
}
.box-green-bottom-dashboard {
    border: 1px solid #c1cdd7;
    border-bottom: 3px solid #8cd9ad;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 15px 15px;
}
.box-orange-bottom-dashboard {
    border: 1px solid #c1cdd7;
    border-bottom: 3px solid #bab112;
    background-color: #faf8d1;
    border-radius: 16px;
    padding: 15px 15px;
}
.box-shadow {
    box-shadow: 1px 1px 10px 0px rgba(18, 145, 255, 0.3);
}
.vertical-divider {
    height: 100%;
    border: 1px solid #c1cdd7;
    margin: 0;
}
@media (max-width: 800px) {
    .d-flex.w-100:not(:last-child) {
        border-bottom: 1px solid #c1cdd7;
        padding-bottom: 1rem;
    }
}
hr {
    border: 1px solid #c1cdd7;
}
.text-black {
    color: #212529 !important;
}
.col-md-5.col-md-5-custom {
    width: 36.666667% !important;
}
.col-md-7.col-md-7-custom {
    width: 63.333333% !important;
}
@media only screen and (max-width: 800px) {
    .col-md-5.col-md-5-custom {
        width: 100% !important;
    }
    .col-md-7.col-md-7-custom {
        width: 100% !important;
    }
}
.dashboard-container {
    padding: 48px 20px 40px 20px;
}

.box-orange {
    border: 1px solid #ffcf99;
    background-color: #fff2e5;
    border-radius: 16px;
    padding: 15px 15px;
}
.box-danger {
    background-color: #ff8600;
    border-radius: 16px;
    padding: 15px 15px;
}
</style>
