<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <h5 class="fw-bold mb-4">Tambah Vendor</h5>
            <div class="row">
                <div class="col-lg-4 mb-4">
                    <label class="mb-2"
                        >Nama Vendor <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama vendor"
                        v-model="detailVendor.name"
                    />
                </div>
                <div class="col-lg-4 mb-4">
                    <label class="mb-2"
                        >Nomor Telepon <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nomor telepon"
                        v-model="detailVendor.phone"
                    />
                </div>
                <div class="col-lg-4 mb-4">
                    <label class="mb-2"
                        >Email <span class="text-yellow">*</span></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        placeholder="Masukkan email"
                        v-model="detailVendor.email"
                    />
                </div>
                <div class="col-lg-4 mb-4 position-relative">
                    <label class="mb-2"
                        >Jenis Vendor <span class="text-yellow">*</span></label
                    >
                    <div
                        class="form-control"
                        :class="{
                            'text-gray': selectedVendorTypes.length === 0,
                        }"
                        @click="toggleVendorTypeDropdown"
                        ref="vendorTypeSelect"
                    >
                        <span v-if="selectedVendorTypes.length === 0"
                            >Pilih jenis vendor</span
                        >
                        <span class="text-capitalize" v-else>
                            {{ selectedVendorTypes.join(", ") }}
                        </span>
                    </div>
                    <div
                        class="box position-absolute w-100 mt-2"
                        v-if="isVendorTypeDropdownOpen"
                        ref="vendorTypeDropdown"
                    >
                        <div class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="custom-checkbox me-2"
                                id="trucking"
                                value="trucking"
                                v-model="selectedVendorTypes"
                            />
                            <label for="trucking">Trucking</label>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="custom-checkbox me-2"
                                id="kargo"
                                value="kargo"
                                v-model="selectedVendorTypes"
                            />
                            <label for="kargo">Kargo</label>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="custom-checkbox me-2"
                                id="pelayaran"
                                value="pelayaran"
                                v-model="selectedVendorTypes"
                            />
                            <label for="pelayaran">Pelayaran</label>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="custom-checkbox me-2"
                                id="dooring"
                                value="dooring"
                                v-model="selectedVendorTypes"
                            />
                            <label for="dooring">Dooring</label>
                        </div>
                        <hr />
                    </div>
                </div>
                <div class="col-lg-4 mb-4">
                    <label class="mb-2"
                        >Provinsi Vendor
                        <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="vendorProvince"
                        :options="vendorProvinces"
                        label="name"
                        track-by="id"
                        placeholder="Cari provinsi vendor"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-4 mb-4">
                    <label class="mb-2"
                        >Kota Vendor <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="vendorRegency"
                        :options="vendorRegencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota vendor"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-12 mb-4">
                    <label class="mb-2"
                        >Alamat Vendor <span class="text-yellow">*</span></label
                    >
                    <textarea
                        class="form-control"
                        placeholder="Masukkan alamat vendor"
                        v-model="detailVendor.address"
                    ></textarea>
                </div>
            </div>
            <hr />
            <h5 class="fw-bold mb-4">Informasi PIC Vendor</h5>
            <div v-for="(pic, index) in picList" :key="'pic-' + index">
                <div class="row mb-4">
                    <div class="col-lg-6 mb-2">
                        <label class="mb-2"
                            >{{
                                picList.length > 1 && index == 0
                                    ? "Nama PIC Utama Vendor"
                                    : "Nama PIC Vendor"
                            }}
                            <span class="text-yellow">*</span></label
                        >
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Masukkan nama PIC vendor"
                            v-model="pic.name"
                        />
                    </div>
                    <div class="col-lg-6 mb-2">
                        <div class="row">
                            <div
                                :class="{
                                    'col-lg-12':
                                        picList.length === 1 || index === 0,
                                    'col-lg-10': picList.length > 1,
                                }"
                            >
                                <label class="mb-2">
                                    {{
                                        picList.length > 1 && index == 0
                                            ? "Nomor Telepon PIC Utama"
                                            : "Nomor Telepon PIC Vendor"
                                    }}
                                    <span class="text-danger fw-bold">*</span>
                                </label>
                                <div class="input-group mb-0">
                                    <span class="input-group-text">+62</span>
                                    <input
                                        type="text"
                                        class="form-control form-phone"
                                        placeholder="Masukkan nomor telepon PIC"
                                        v-model="pic.phone"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-2" v-if="index > 0">
                                <label class="fw-bold mb-2 invisible"
                                    >invisible</label
                                >
                                <div
                                    class="btn btn-outline-green"
                                    @click="removePic(index)"
                                >
                                    <img
                                        src="../assets/trash-multiple.svg"
                                        alt="trash multiple"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr v-if="index < picList.length - 1" />
            </div>
            <div class="col-lg-12 mb-4" v-if="picList.length < 5">
                <div class="btn-outline-green btn w-100" @click="addPic">
                    <i class="fa fa-plus"></i>
                    Tambah PIC
                </div>
            </div>
            <hr />
            <h5 class="fw-bold mb-4">Informasi Nomor Rekening Vendor</h5>
            <div v-for="(bank, index) in bankList" :key="'bank-' + index">
                <div class="row mb-4">
                    <div class="col-lg-4 mb-2 position-relative">
                        <label class="mb-2"
                            >{{
                                bankList.length > 1 && index == 0
                                    ? "Nama Bank Utama"
                                    : "Nama Bank"
                            }}
                            <span class="text-yellow">*</span></label
                        >
                        <multiselect
                            v-model="bank.bank_name_obj"
                            :options="bankNames"
                            label="name"
                            track-by="name"
                            placeholder="Pilih bank"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-4 mb-2 position-relative">
                        <label class="mb-2"
                            >{{
                                bankList.length > 1 && index == 0
                                    ? "Nomor Rekening Utama"
                                    : "Nomor Rekening"
                            }}
                            <span class="text-yellow">*</span></label
                        >
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Masukkan nomor rekening"
                            v-model="bank.account_number"
                        />
                    </div>
                    <div class="col-lg-4 mb-2 position-relative">
                        <div class="row">
                            <div
                                :class="{
                                    'col-lg-12':
                                        bankList.length === 1 || index === 0,
                                    'col-lg-9': bankList.length > 1,
                                }"
                            >
                                <label class="mb-2"
                                    >{{
                                        bankList.length > 1 && index == 0
                                            ? "Nama Pemilik Rekening Utama"
                                            : "Nama Pemilik Rekening"
                                    }}
                                    <span class="text-yellow">*</span></label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Masukkan nama pemilik rekening"
                                    v-model="bank.account_name"
                                />
                            </div>
                            <div class="col-lg-2" v-if="index > 0">
                                <label class="fw-bold mb-2 invisible"
                                    >invisible</label
                                >
                                <div
                                    class="btn btn-outline-green"
                                    @click="removeBank(index)"
                                >
                                    <img
                                        src="../assets/trash-multiple.svg"
                                        alt="trash multiple"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr v-if="index < bankList.length - 1" />
            </div>
            <div class="col-lg-12 mb-4" v-if="bankList.length < 5">
                <div class="btn-outline-green btn w-100" @click="addBank">
                    <i class="fa fa-plus"></i>
                    Tambah Nomor Rekening
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button
                    class="btn btn-primary px-4"
                    :disabled="!isFormValid"
                    @click="modalConfirm = true"
                >
                    Simpan Perubahan
                </button>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Perubahan data vendor akan disimpan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="editData()"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Perubahan data vendor berhasil disimpan
                    </h5>
                </div>
                <router-link
                    to="/vendor-management"
                    href="javascript:void(0)"
                    class="btn btn-primary w-100 btn-lg mt-4"
                >
                    Tutup
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import magnifierDisabled from "../assets/magnifier-disabled.svg";
import magnifier from "../assets/magnifier.svg";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            isPageLoading: false,
            magnifierIcon: magnifierDisabled,
            magnifierActiveIcon: magnifier,
            modalConfirm: false,
            modalSuccess: false,
            isVendorTypeDropdownOpen: false,
            selectedVendorTypes: [],
            detailVendor: {
                name: null,
                phone: null,
                email: null,
                address: null,
            },
            picList: [
                {
                    name: null,
                    phone: null,
                },
            ],
            bankList: [
                {
                    bank_name_obj: null,
                    account_number: null,
                    account_name: null,
                },
            ],
            isLoading: false,
            bankNames: [],
            vendorProvince: null,
            vendorProvinces: [],
            vendorRegency: null,
            vendorRegencies: [],
        };
    },
    created() {
        this.getBanks();
        this.getRegencies();
        this.getProvinces();
    },
    mounted() {
        document.addEventListener("click", this.closeDropdownOnClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.closeDropdownOnClickOutside);
    },
    computed: {
        isFormValid() {
            if (
                !this.detailVendor.name ||
                !this.detailVendor.phone ||
                !this.detailVendor.email ||
                !this.detailVendor.address ||
                this.selectedVendorTypes.length === 0 ||
                !this.vendorProvince ||
                !this.vendorRegency
            ) {
                return false;
            }

            for (const pic of this.picList) {
                if (!pic.name || !pic.phone) {
                    return false;
                }
            }

            for (const bank of this.bankList) {
                if (
                    !bank.bank_name_obj ||
                    !bank.account_number ||
                    !bank.account_name
                ) {
                    return false;
                }
            }

            return true;
        },
    },
    methods: {
        toggleVendorTypeDropdown() {
            this.isVendorTypeDropdownOpen = !this.isVendorTypeDropdownOpen;
        },
        closeDropdownOnClickOutside(event) {
            if (
                this.isVendorTypeDropdownOpen &&
                this.$refs.vendorTypeSelect &&
                this.$refs.vendorTypeDropdown &&
                !this.$refs.vendorTypeSelect.contains(event.target) &&
                !this.$refs.vendorTypeDropdown.contains(event.target)
            ) {
                this.isVendorTypeDropdownOpen = false;
            }
        },
        addPic() {
            if (this.picList.length < 5) {
                this.picList.push({
                    name: null,
                    phone: null,
                });
            }
        },
        removePic(index) {
            if (index > 0) {
                this.picList.splice(index, 1);
            }
        },
        addBank() {
            if (this.bankList.length < 5) {
                this.bankList.push({
                    bank_name_obj: null,
                    account_number: null,
                    account_name: null,
                });
            }
        },
        removeBank(index) {
            if (index > 0) {
                this.bankList.splice(index, 1);
            }
        },
        getRegencies() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.vendorRegencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getProvinces() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/province`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.vendorProvinces = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getBanks() {
            this.isPageLoading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/bank`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.bankNames = res.data.data.map((bank) => {
                        return { name: bank };
                    });
                    this.getDetailVendor();
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDetailVendor() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/vendors/detail`, {
                params: {
                    vendor_id: this.$route.params.id,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    const data = res.data.data;

                    // Set basic vendor info
                    this.detailVendor.name = data.name;
                    this.detailVendor.phone = data.phone;
                    this.detailVendor.email = data.email;
                    this.detailVendor.address = data.address;

                    // Set vendor types
                    this.selectedVendorTypes = data.vendor_type || [];

                    // Set province and regency
                    if (data.province_id) {
                        this.vendorProvince = this.vendorProvinces.find(
                            (item) => item.id === data.province_id
                        );
                    }

                    if (data.regency_id) {
                        this.vendorRegency = this.vendorRegencies.find(
                            (item) => item.id === data.regency_id
                        );
                    }

                    // Set PICs
                    if (data.pics && data.pics.length > 0) {
                        this.picList = data.pics.map((pic) => ({
                            name: pic.name,
                            phone: pic.phone,
                        }));
                    }

                    // Set banks
                    if (data.banks && data.banks.length > 0) {
                        this.bankList = data.banks.map((bank) => {
                            return {
                                bank_name_obj:
                                    this.bankNames.find(
                                        (b) => b.name === bank.bank_name
                                    ) || null,
                                account_number: bank.account_number,
                                account_name: bank.account_name,
                            };
                        });
                    }

                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        editData() {
            this.isLoading = true;

            // Format bank data
            const bankData = this.bankList.map((bank) => ({
                bank_name: bank.bank_name_obj ? bank.bank_name_obj.name : "",
                account_number: bank.account_number,
                account_name: bank.account_name,
            }));

            var data = {
                vendor_id: this.$route.params.id,
                name: this.detailVendor.name,
                phone: this.detailVendor.phone,
                email: this.detailVendor.email,
                address: this.detailVendor.address,
                vendor_type: this.selectedVendorTypes,
                province_id: this.vendorProvince
                    ? this.vendorProvince.id
                    : null,
                regency_id: this.vendorRegency ? this.vendorRegency.id : null,
                pics: this.picList,
                banks: bankData,
            };

            Api.post(`${process.env.VUE_APP_SERVICE_URL}/vendors/edit`, data, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then(() => {
                    this.isLoading = false;
                    this.modalSuccess = true;
                    this.modalConfirm = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}

.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}

.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
hr {
    color: #c1cdd7;
}
.outline-add {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
}
.rotate-180 {
    transform: rotate(180deg);
}
.transition-transform {
    transition: transform 0.3s ease;
}
.outline-yellow {
    background-color: transparent !important;
    border: 1px solid #ff8600 !important;
    color: #ff8600 !important;
}
.b-l-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
::v-deep .multi-form-custom.magnifier {
    .multiselect__tags {
        padding-left: 40px !important;
    }
    .multiselect__select {
        padding: 0;
    }
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    cursor: pointer;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.bb-r {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
</style>
