<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_loading_data"></div>
        <div id="loader" v-if="is_loading_data"></div>
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6 mt-4">
                <div class="box-manifest">
                    <center>
                        <div class="size-20 fw-bold">Informasi Driver</div>
                        <div
                            class="user-avatar-sidebar mt-3"
                            :style="{
                                'background-image':
                                    'url(' +
                                    require('../../assets/user.jpg') +
                                    ')',
                            }"
                        ></div>
                    </center>
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-80 fw-medium">Nama Driver</td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                {{ detail.driver_information.name }}
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-80 fw-medium">No. Telp Driver</td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                {{ detail.driver_information.phone }}
                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0 w-80 fw-medium">Armada</td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                {{ detail.driver_information.fleet }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-4">
            <div class="d-flex">
                <h5 class="fw-bold">Daftar Orderan</h5>
                <div class="ms-auto text-primary">
                    {{ order_list.length }} Lokasi Tujuan
                </div>
            </div>
            <div>
                Tanggal Penugasan Driver :
                {{
                    moment(dataParams.assigned_driver, "DD/MM/YYYY").format(
                        "DD MMMM YYYY"
                    )
                }}
            </div>
            <div class="box mt-4">
                <h5 class="fw-bold mb-3">Filter</h5>
                <div class="row">
                    <div class="col-lg-3 my-1">
                        <label class="mb-2">Tipe Order</label>
                        <multiselect
                            v-model="orderType"
                            :options="orderTypes"
                            label="name"
                            track-by="id"
                            placeholder="Pilih tipe order"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-3 my-1">
                        <label class="mb-2">Tipe Layanan</label>
                        <multiselect
                            v-model="serviceType"
                            :options="serviceTypes"
                            label="name"
                            track-by="id"
                            placeholder="Pilih tipe layanan"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-3 my-1 position-relative">
                        <label class="mb-2">Status Order</label>
                        <div
                            class="form-control cursor-pointer overflow-auto text-nowrap"
                            :class="{
                                'text-gray': selectedStatuses.length === 0,
                            }"
                            @click="toggleBox"
                            ref="selector"
                        >
                            {{ displayText }}
                        </div>
                        <div
                            class="box p-3 mt-2 position-absolute"
                            v-show="isBoxVisible"
                            ref="dropdownBox"
                        >
                            <div class="d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    class="custom-checkbox me-2"
                                    v-model="tempSelectedStatuses"
                                    value="Menunggu Dijemput"
                                    id="Menunggu Dijemput"
                                />
                                <label
                                    for="Menunggu Dijemput"
                                    class="cursor-pointer"
                                    >Menunggu Dijemput</label
                                >
                            </div>
                            <hr />
                            <div class="d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    class="custom-checkbox me-2"
                                    v-model="tempSelectedStatuses"
                                    value="Sedang Dijemput"
                                    id="Sedang Dijemput"
                                />
                                <label
                                    for="Sedang Dijemput"
                                    class="cursor-pointer"
                                    >Sedang Dijemput</label
                                >
                            </div>
                            <hr />
                            <div class="d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    class="custom-checkbox me-2"
                                    v-model="tempSelectedStatuses"
                                    value="Selesai Dijemput"
                                    id="Selesai Dijemput"
                                />
                                <label
                                    for="Selesai Dijemput"
                                    class="cursor-pointer"
                                    >Selesai Dijemput</label
                                >
                            </div>
                            <hr />
                            <div class="d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    class="custom-checkbox me-2"
                                    v-model="tempSelectedStatuses"
                                    value="Off Loading"
                                    id="Off Loading"
                                />
                                <label for="Off Loading" class="cursor-pointer"
                                    >Off Loading</label
                                >
                            </div>
                            <div class="row mt-3">
                                <div class="col-lg-6">
                                    <button
                                        class="btn btn-outline-yellow w-100"
                                        @click="resetSelection"
                                        :disabled="
                                            tempSelectedStatuses.length === 0
                                        "
                                    >
                                        Reset
                                    </button>
                                </div>
                                <div class="col-lg-6">
                                    <button
                                        class="btn btn-green w-100"
                                        @click="saveSelection"
                                        :disabled="
                                            tempSelectedStatuses.length === 0
                                        "
                                    >
                                        Simpan
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 my-1">
                        <label class="mb-2 invisible">Status Order</label>
                        <div class="row">
                            <div class="col-lg-6">
                                <button
                                    class="btn btn-outline-yellow w-100"
                                    :disabled="
                                        !orderType &&
                                        !serviceType &&
                                        selectedStatuses.length == 0
                                    "
                                    @click="handleClearClick"
                                >
                                    Clear
                                </button>
                            </div>
                            <div class="col-lg-6">
                                <button
                                    class="btn btn-green w-100"
                                    :disabled="
                                        !orderType &&
                                        !serviceType &&
                                        selectedStatuses.length == 0
                                    "
                                    @click="handleFilterClick"
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="box mt-4 p-0"
                v-for="(item, index) in order_list"
                :key="index"
            >
                <div class="header-card">
                    <div class="d-flex align-items-center">
                        <div class="fw-bold size-16 capitalize me-3">
                            Penjemputan
                            {{
                                item.service_type === "pengiriman_barang"
                                    ? "Barang"
                                    : "Motor"
                            }}
                        </div>
                        <div
                            class="rounded-pill px-4 py-1 me-3 text-capitalize"
                            :class="{
                                'badge-green': item.type === 'dooring',
                                'badge-blue': item.type === 'pickup',
                            }"
                        >
                            {{ item.type }}
                        </div>
                        <div
                            class="rounded-pill px-4 py-1 me-3"
                            :class="{
                                'badge-pastel':
                                    item.status === 'Menunggu Dijemput',
                                'badge-lightblue':
                                    item.status === 'Sedang Dijemput',
                                'badge-lightgreen':
                                    item.status === 'Selesai Dijemput',
                                'badge-grayblue': item.status === 'Off Loading',
                            }"
                        >
                            {{ item.status }}
                        </div>
                    </div>
                </div>
                <div class="p-4 pt-2 pb-2">
                    <table class="w-100 ps-0 fw-bold" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-80 fw-normal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="d-flex">
                                            <div class="me-2">
                                                <img
                                                    src="../../assets/user-small.svg"
                                                    alt="user small"
                                                />
                                            </div>
                                            <div>{{ item.customer }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="d-flex">
                                            <div class="me-2">
                                                <img
                                                    src="../../assets/call-small.svg"
                                                    alt="call small"
                                                />
                                            </div>
                                            <div>+{{ item.phone }}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0 w-80 fw-normal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="d-flex">
                                            <div class="me-2">
                                                <img
                                                    src="../../assets/location-small.svg"
                                                    alt="location small"
                                                />
                                            </div>
                                            <div class="d-flex flex-column">
                                                <p class="mb-0">
                                                    Penjemputan Ke
                                                </p>
                                                <div class="text-green fw-bold">
                                                    {{ item.origin_regency }},
                                                    {{ item.origin_province }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="d-flex">
                                            <div class="me-2">
                                                <img
                                                    src="../../assets/location-small.svg"
                                                    alt="location small"
                                                />
                                            </div>
                                            <div class="d-flex flex-column">
                                                <p class="mb-0">
                                                    Pengantaran Ke
                                                </p>
                                                <div class="text-green fw-bold">
                                                    {{ item.dest_regency }},
                                                    {{ item.dest_province }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <div class="box-cream">
                            <div class="row">
                                <div class="col-6">CRM Specialist</div>
                                <div class="col-6">
                                    <div class="d-flex align-items-center">
                                        <img
                                            src="../../assets/specialist.svg"
                                            alt="specialist"
                                            class="me-2"
                                        />
                                        {{ item.specialist }}
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="d-flex align-items-center">
                                <img
                                    src="../../assets/document-specialist.svg"
                                    alt="document specialist"
                                    class="me-2"
                                />
                                {{ item.description }}
                            </div>
                        </div>
                        <tr>
                            <td class="ps-0 w-80 fw-normal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="d-flex">
                                            <div class="me-2">
                                                <img
                                                    src="../../assets/dimension-small.svg"
                                                    alt="dimension-small"
                                                    v-if="
                                                        item.service_type ===
                                                        'pengiriman_barang'
                                                    "
                                                />
                                                <img
                                                    src="../../assets/bike-small.svg"
                                                    alt="bike-small"
                                                    v-if="
                                                        item.service_type ===
                                                        'pengiriman_motor'
                                                    "
                                                />
                                            </div>
                                            <div
                                                v-if="
                                                    item.service_type ===
                                                    'pengiriman_barang'
                                                "
                                            >
                                                {{
                                                    item?.dimension?.width
                                                        ? item?.dimension?.width
                                                        : 0
                                                }}cm X
                                                {{
                                                    item?.dimension?.length
                                                        ? item?.dimension
                                                              ?.length
                                                        : 0
                                                }}cm X
                                                {{
                                                    item?.dimension?.height
                                                        ? item?.dimension
                                                              ?.height
                                                        : 0
                                                }}cm
                                            </div>
                                            <div
                                                v-if="
                                                    item.service_type ===
                                                    'pengiriman_motor'
                                                "
                                            >
                                                {{ item.vehicle }}
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex mt-2"
                                            v-if="
                                                item.service_type ===
                                                'pengiriman_barang'
                                            "
                                        >
                                            <div class="me-2">
                                                <img
                                                    src="../../assets/document-small.svg"
                                                    alt="document small"
                                                />
                                            </div>
                                            <div>{{ item.note }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="d-flex">
                                            <div class="me-2">
                                                <img
                                                    src="../../assets/weight-small.svg"
                                                    alt="weight small"
                                                    v-if="
                                                        item.service_type ===
                                                        'pengiriman_barang'
                                                    "
                                                />
                                                <img
                                                    src="../../assets/cc-small.svg"
                                                    alt="cc small"
                                                    v-if="
                                                        item.service_type ===
                                                        'pengiriman_motor'
                                                    "
                                                />
                                            </div>
                                            <div>
                                                {{
                                                    item.service_type ===
                                                    "pengiriman_motor"
                                                        ? item.cc === "lower"
                                                            ? "100 CC - 149 CC"
                                                            : item.cc ===
                                                              "middle"
                                                            ? "150 CC - 249 CC"
                                                            : item.cc === "high"
                                                            ? "Diatas 250 CC"
                                                            : item.cc
                                                        : item.weight
                                                }}
                                                {{
                                                    item.service_type ===
                                                    "pengiriman_motor"
                                                        ? ""
                                                        : "Kg"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="border-top" v-if="item.isShow">
                            <td class="ps-0 w-80 fw-normal">
                                <div class="size-16 fw-bold">
                                    Status Penjemputan
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div
                        class="d-flex"
                        v-for="(pickup, index) in item.pickup_status"
                        :key="index"
                    >
                        <div class="d-flex flex-column" v-if="item.isShow">
                            <p class="mb-0">{{ pickup.date }}</p>
                            <p class="mb-0">{{ pickup.time }}</p>
                        </div>
                        <div v-if="item.isShow">
                            <img
                                src="../../assets/location-active.svg"
                                alt="location active"
                                class="mx-4"
                                v-if="item.pickup_status.length === 1"
                            />
                            <img
                                src="../../assets/location-passive.svg"
                                alt="location active"
                                class="mx-4"
                                v-if="
                                    index === item.pickup_status.length - 1 &&
                                    item.pickup_status.length !== 1
                                "
                            />
                            <img
                                src="../../assets/location-long-active.svg"
                                alt="location long active"
                                class="mx-4"
                                v-if="
                                    index === 0 &&
                                    item.pickup_status.length !== 1 &&
                                    item.pickup_status.length !== 2
                                "
                            />
                            <img
                                src="../../assets/location-long-passive.svg"
                                alt="location long passive"
                                class="mx-4"
                                v-if="index !== 0 && pickup.details"
                            />
                            <img
                                src="../../assets/location-short.svg"
                                alt="location short"
                                class="mx-4"
                                v-if="
                                    !pickup.details &&
                                    index !== item.pickup_status.length - 1 &&
                                    item.pickup_status.length !== 2
                                "
                            />
                            <img
                                src="../../assets/location-short-active.svg"
                                alt="location short"
                                class="mx-4"
                                v-if="
                                    !pickup.details &&
                                    index !== item.pickup_status.length - 1 &&
                                    item.pickup_status.length === 2
                                "
                            />
                        </div>
                        <div class="d-flex flex-column" v-if="item.isShow">
                            <p class="fw-bold mb-0">{{ pickup.status }}</p>
                            <p class="mb-0">{{ pickup.description }}</p>
                            <div class="d-flex gap-2">
                                <div
                                    class="d-flex my-3 gap-3 position-relative photo-container"
                                    v-for="(photo, photoIndex) in pickup
                                        ?.details?.images"
                                    :key="photoIndex"
                                    @click="
                                        openPreview(
                                            pickup?.details?.images,
                                            photoIndex
                                        )
                                    "
                                >
                                    <img
                                        :src="photo"
                                        :alt="photo"
                                        class="photo-images rounded w-100"
                                    />
                                    <img
                                        src="../../assets/eye-preview.svg"
                                        alt="eye preview"
                                        class="eye-preview position-absolute top-50 start-50 translate-middle"
                                    />
                                </div>
                            </div>

                            <i class="mb-0">{{ pickup?.details?.remark }}</i>
                        </div>
                    </div>
                </div>
                <div class="border-top">
                    <div class="p-3 text-center">
                        <a
                            href="javascript:void(0)"
                            @click="showContent(index)"
                            class="text-dark"
                        >
                            <div
                                class="d-flex align-items-center justify-content-center"
                            >
                                <template v-if="item.isShow == true">
                                    <div>Lihat Lebih Sedikit</div>
                                    <i class="fa fa-angle-up size-20 ms-1"></i>
                                </template>
                                <template v-if="item.isShow == false">
                                    <div>Lihat Lebih Banyak</div>
                                    <i
                                        class="fa fa-angle-down size-20 ms-1"
                                    ></i>
                                </template>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="preview-modal"
            v-if="showPreview"
            @click.self="closePreview"
        >
            <div class="preview-content">
                <button class="close-button" @click="closePreview">
                    &times;
                </button>
                <div class="preview-container">
                    <div class="preview-controls">
                        <button
                            @click="prevImage"
                            :disabled="currentImageIndex === 0"
                        >
                            &lt;
                        </button>
                        <span
                            >{{ currentImageIndex + 1 }} /
                            {{ previewImages.length }}</span
                        >
                        <button
                            @click="nextImage"
                            :disabled="
                                currentImageIndex === previewImages.length - 1
                            "
                        >
                            &gt;
                        </button>
                    </div>
                    <div class="preview-image-container" ref="imageContainer">
                        <img
                            :src="previewImages[currentImageIndex]"
                            :alt="'Preview ' + (currentImageIndex + 1)"
                            ref="previewImage"
                            @wheel="handleZoom"
                            @mousedown="startPan"
                            @mousemove="pan"
                            @mouseup="endPan"
                            @mouseleave="endPan"
                        />
                    </div>
                    <div class="zoom-controls">
                        <button @click="zoomIn">+</button>
                        <button @click="zoomOut">-</button>
                        <button @click="resetZoom">Reset</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
    name: "assignmentDetail",
    components: {
        Multiselect,
    },
    data() {
        return {
            originalOrderList: [],
            moment: moment,
            detail: {
                driver_information: {},
            },
            order_list: [],
            is_loading_data: false,
            dataParams: {},
            orderType: null,
            orderTypes: [
                {
                    id: "dooring",
                    name: "Dooring",
                },
                { id: "pickup", name: "Pick Up" },
            ],
            serviceType: null,
            serviceTypes: [
                {
                    id: "pengiriman_barang",
                    name: "Pengiriman Barang",
                },
                { id: "pengiriman_motor", name: "Pengiriman Motor" },
            ],
            isBoxVisible: false,
            selectedStatuses: [],
            displayText: "Pilih Status Order",
            tempSelectedStatuses: [],
            showPreview: false,
            previewImages: [],
            currentImageIndex: 0,
            scale: 1,
            translateX: 0,
            translateY: 0,
            isPanning: false,
            startX: 0,
            startY: 0,
            lastX: 0,
            lastY: 0,
        };
    },
    created() {
        this.getDetail();
    },
    mounted() {
        const data = sessionStorage.getItem("dataParams");
        this.dataParams = JSON.parse(data);
        document.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
    },
    methods: {
        openPreview(images, imageIndex) {
            if (!images || images.length === 0) return;

            this.previewImages = images;
            this.currentImageIndex = imageIndex || 0;
            this.showPreview = true;
            this.resetZoom();
            document.body.style.overflow = "hidden";
        },

        closePreview() {
            this.showPreview = false;
            this.resetZoom();
            document.body.style.overflow = "auto";
        },

        prevImage() {
            if (this.currentImageIndex > 0) {
                this.currentImageIndex--;
                this.resetZoom();
            }
        },

        nextImage() {
            if (this.currentImageIndex < this.previewImages.length - 1) {
                this.currentImageIndex++;
                this.resetZoom();
            }
        },

        handleZoom(event) {
            event.preventDefault();
            const delta = Math.sign(event.deltaY) * -0.1;
            this.zoom(delta);
        },

        zoomIn() {
            this.zoom(0.1);
        },

        zoomOut() {
            this.zoom(-0.1);
        },

        zoom(delta) {
            const newScale = this.scale + delta;
            if (newScale >= 0.5 && newScale <= 3) {
                this.scale = newScale;
                this.updateTransform();
            }
        },

        resetZoom() {
            this.scale = 1;
            this.translateX = 0;
            this.translateY = 0;
            this.updateTransform();
        },

        startPan(event) {
            this.isPanning = true;
            this.startX = event.clientX - this.translateX;
            this.startY = event.clientY - this.translateY;
        },

        pan(event) {
            if (!this.isPanning) return;

            this.translateX = event.clientX - this.startX;
            this.translateY = event.clientY - this.startY;
            this.updateTransform();
        },

        endPan() {
            this.isPanning = false;
        },

        updateTransform() {
            if (this.$refs.previewImage) {
                this.$refs.previewImage.style.transform = `translate(${this.translateX}px, ${this.translateY}px) scale(${this.scale})`;
            }
        },
        handleClickOutside(event) {
            const dropdownBox = this.$refs.dropdownBox;
            const selector = this.$refs.selector;

            if (
                dropdownBox &&
                selector &&
                this.isBoxVisible &&
                !dropdownBox.contains(event.target) &&
                !selector.contains(event.target)
            ) {
                this.isBoxVisible = false;
            }
        },
        toggleBox() {
            this.isBoxVisible = !this.isBoxVisible;
        },
        saveSelection() {
            this.selectedStatuses = [...this.tempSelectedStatuses];
            if (this.selectedStatuses.length > 0) {
                const statusMap = {
                    waiting: "Menunggu Dijemput",
                    pickup: "Sedang Dijemput",
                    done: "Selesai Dijemput",
                };

                const mappedStatuses = this.selectedStatuses.map(
                    (status) => statusMap[status] || status
                );
                this.displayText = mappedStatuses.join(", ");
            } else {
                this.displayText = "Pilih Status Order";
            }
            this.isBoxVisible = false;
        },
        showContent(index) {
            this.order_list[index].isShow = !this.order_list[index].isShow;
        },
        applyFilters() {
            let filteredList = [...this.originalOrderList];

            if (
                this.orderType ||
                this.serviceType ||
                this.selectedStatuses.length > 0
            ) {
                filteredList = filteredList.filter((item) => {
                    const matchOrderType =
                        !this.orderType || item.type === this.orderType.id;
                    const matchServiceType =
                        !this.serviceType ||
                        item.service_type === this.serviceType.id;
                    const matchStatus =
                        !this.selectedStatuses.length ||
                        this.selectedStatuses.includes(item.status);
                    return matchOrderType && matchServiceType && matchStatus;
                });
            }

            this.order_list = filteredList.map((item) => ({
                ...item,
                isShow: false,
            }));
        },
        handleFilterClick() {
            this.applyFilters();
        },

        handleClearClick() {
            this.orderType = null;
            this.serviceType = null;
            this.selectedStatuses = [];
            this.applyFilters();
        },
        async getDetail() {
            const params = sessionStorage.getItem("dataParams");
            const dataParams = JSON.parse(params);
            this.is_loading_data = true;

            const filterParams = {
                driver_fleet_id: dataParams.driver_fleet_id,
                date: dataParams.assigned_driver,
            };

            try {
                const response = await Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/pickup/assigned/detail`,
                    {
                        params: filterParams,
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                );

                this.detail = response.data.data;
                this.originalOrderList = response.data.data.order_list;
                this.applyFilters();
                this.is_loading_data = false;
            } catch (err) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: err.response.data.message,
                });
            }
        },
        resetSelection() {
            this.tempSelectedStatuses = [];
            this.displayText = "Pilih Status Order";
        },
    },
};
</script>

<style lang="scss" scoped>
.preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.preview-content {
    position: relative;
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 1001;
}

.preview-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preview-controls {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    color: white;
}

.preview-controls button {
    background: none;
    border: 1px solid white;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
}

.preview-controls button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.preview-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.preview-image-container img {
    max-height: 80vh;
    max-width: 100%;
    object-fit: contain;
    transition: transform 0.1s ease-out;
    cursor: grab;
}

.preview-image-container img:active {
    cursor: grabbing;
}

.zoom-controls {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.zoom-controls button {
    background: none;
    border: 1px solid white;
    color: white;
    padding: 5px 15px;
    cursor: pointer;
}

.photo-container {
    cursor: pointer;
}
.photo-images {
    object-fit: cover;
    border: 1px solid#8E8E94;
    background-color: #61616a;
    filter: brightness(0.5);
}
.photo-container {
    width: 150px;
    height: 100px;
}
hr {
    color: #c1cdd7;
}
.badge-green {
    background-color: #40bf76;
    color: #fffafa;
}
.badge-blue {
    background-color: #1291ff;
    color: #fffafa;
}
.badge-pastel {
    background-color: #ffe7cc;
    color: #995100;
}
.badge-lightblue {
    background-color: #cce7ff;
    color: #005299;
}
.badge-lightgreen {
    background-color: #d9f2e4;
    color: #267347;
}
.badge-grayblue {
    background-color: #e0e6eb;
    color: #3e4e5c;
}
.form-control::-webkit-scrollbar {
    display: none;
}
.form-control {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.user-avatar-sidebar {
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    border: 2px solid #f8f9fa;
}
.w-80 {
    width: 150px;
}
.w-30 {
    width: 30px;
}
.header-card {
    background: #f5f5f5;
    width: 100%;
    padding: 14px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.box-cream {
    background-color: #fff2e5;
    border-radius: 16px;
    padding: 15px 20px;
}
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}
.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}
.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
</style>
