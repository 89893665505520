<template>
    <div class="padding-container">
        <div class="content-load" v-if="isLoading"></div>
        <div id="loader" v-if="isLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest position-relative z-1 mt-4">
            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex flex-column">
                    <h5 class="fw-bold text-primary mb-3">
                        {{ detail?.name }}
                    </h5>
                    <div class="d-flex flex-wrap gap-2 align-items-center">
                        <div
                            class="blue-badge rounded px-3 py-1 text-capitalize"
                            v-for="(vendorType, index) in detail?.vendor_type"
                            :key="index"
                        >
                            {{ vendorType }}
                        </div>
                    </div>
                </div>
                <div
                    class="d-flex align-items-center gap-2"
                    v-if="isRole(action)"
                >
                    <router-link
                        :to="'/vendor-management/edit/' + detail?.id"
                        class="btn btn-green d-flex gap-2 align-items-center"
                    >
                        <img
                            src="../assets/pencil-transparent.svg"
                            alt="pencil transparent"
                        />
                        Edit Data
                    </router-link>
                    <img
                        src="../assets/trash-detail.svg"
                        alt="trash detail"
                        @click="modalConfirm = true"
                        class="cursor-pointer"
                    />
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-lg-6">
                    <table class="w-100 ps-0 fw-bold" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">No Telp Vendor</td>
                            <td class="w-30">:</td>
                            <td>
                                <a
                                    :href="'tel:' + detail?.phone"
                                    class="text-link"
                                    >{{ detail?.phone }}</a
                                >
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Provinsi Vendor</td>
                            <td class="w-30">:</td>
                            <td>{{ detail?.province_name }}</td>
                        </tr>
                        <tr>
                            <td class="ps-0 w-80">Alamat Vendor</td>
                            <td class="w-30">:</td>
                            <td>
                                {{ detail?.address }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-lg-6">
                    <table class="w-100 ps-0 fw-bold" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Email Vendor</td>
                            <td class="w-30">:</td>
                            <td>
                                <a
                                    :href="'mail:' + detail?.email"
                                    class="text-link"
                                    >{{ detail?.email }}</a
                                >
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Kota/Kab</td>
                            <td class="w-30">:</td>
                            <td>{{ detail?.regency_name }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <p class="my-3 text-gray sub-title position-relative">
                <span>PIC Vendor</span>
            </p>
            <div class="row">
                <div class="col-lg-6">
                    <table class="w-100 ps-0 fw-bold" cellpadding="10">
                        <tr
                            class="border-bottom"
                            v-for="(pic, index) in detail?.pics"
                            :key="index"
                        >
                            <td class="ps-0 w-80">
                                {{
                                    index == 0 && detail?.pics.length == 1
                                        ? "PIC"
                                        : index == 0 &&
                                          detail?.pics.length !== 1
                                        ? "PIC Utama"
                                        : "PIC Lainnya"
                                }}
                            </td>
                            <td class="w-30">:</td>
                            <td>{{ pic.name }}</td>
                        </tr>
                    </table>
                </div>
                <div class="col-lg-6">
                    <table class="w-100 ps-0 fw-bold" cellpadding="10">
                        <tr
                            class="border-bottom"
                            v-for="(pic, index) in detail?.pics"
                            :key="index"
                        >
                            <td class="ps-0 w-80">
                                {{
                                    index == 0 && detail?.pics.length == 1
                                        ? "Kontak PIC"
                                        : index == 0 &&
                                          detail?.pics.length !== 1
                                        ? "Kontak PIC Utama"
                                        : "Kontak PIC Lainnya"
                                }}
                            </td>
                            <td class="w-30">:</td>
                            <td>
                                <a
                                    :href="'tel:' + pic.phone"
                                    class="text-link"
                                    >{{ pic.phone }}</a
                                >
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <p class="my-3 text-gray sub-title position-relative">
                <span>Informasi Rekening Vendor</span>
            </p>
            <div class="row">
                <div class="col-lg-6">
                    <table class="w-100 ps-0 fw-bold" cellpadding="10">
                        <tr
                            class="border-bottom"
                            v-for="(bank, index) in detail?.banks"
                            :key="index"
                        >
                            <td class="ps-0 w-80">
                                {{
                                    index == 0 && detail?.banks.length == 1
                                        ? "Bank & Rekening"
                                        : index == 0 &&
                                          detail?.banks.length !== 1
                                        ? "Bank & Rekening Utama"
                                        : "Bank & Rekening Lainnya"
                                }}
                            </td>
                            <td class="w-30">:</td>
                            <td>
                                {{ bank.bank_name }} | {{ bank.account_number }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-lg-6">
                    <table class="w-100 ps-0 fw-bold" cellpadding="10">
                        <tr
                            class="border-bottom"
                            v-for="(bank, index) in detail?.banks"
                            :key="index"
                        >
                            <td class="ps-0 w-80">
                                {{
                                    index == 0 && detail?.banks.length == 1
                                        ? "Nama Rekening "
                                        : index == 0 &&
                                          detail?.banks.length !== 1
                                        ? "Nama Rekening Utama"
                                        : "Nama Rekening  Lainnya"
                                }}
                            </td>
                            <td class="w-30">:</td>
                            <td>{{ bank.account_name }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="box-manifest position-relative z-1 mt-4">
            <div class="d-flex align-items-center justify-content-between">
                <h5 class="fw-bold mb-0">Informasi Rute</h5>
            </div>
            <hr />
            <div v-if="detail?.routes.length === 0">
                <div class="d-flex justify-content-center mt-5">
                    <img src="../assets/empty-route.svg" alt="empty" />
                </div>
                <p class="text-gray text-center mt-5">
                    Belum ada list rute & keberangkatan
                </p>
            </div>
            <div
                v-else
                class="mb-4"
                v-for="(route, index) in detail?.routes"
                :key="index"
            >
                <div
                    class="d-flex justify-content-between header-sidebar px-3 py-2"
                    :class="{
                        'bb-r': !expandedSections.includes(index),
                    }"
                    @click="toggleSection(index)"
                >
                    <p class="mb-0 fw-bold">
                        {{ route.origin_regency_name }} -
                        {{ route.destination_province_name }} ({{
                            route.total_destination
                        }}
                        Kab/Kota)
                    </p>
                    <div class="d-flex align-items-center gap-2">
                        <router-link
                            :to="'/route/edit?route_ids=' + route.route_ids"
                            class="btn btn-green d-flex gap-2 align-items-center"
                            v-if="isRole(action)"
                        >
                            <img
                                src="../assets/pencil-transparent.svg"
                                alt="pencil transparent"
                            />
                            Edit Rute
                        </router-link>
                        <img
                            src="../assets/chevron-down.svg"
                            alt="chevron down"
                            :class="{
                                'rotate-180': expandedSections.includes(index),
                            }"
                        />
                    </div>
                </div>
                <div
                    class="body-sidebar px-3 py-2"
                    v-show="expandedSections.includes(index)"
                >
                    <div class="row">
                        <div class="col-lg-6">
                            <table class="w-100 ps-0 fw-bold" cellpadding="10">
                                <tr class="border-bottom">
                                    <td class="ps-0 w-80">Jalur & Layanan</td>
                                    <td class="w-30">:</td>
                                    <td class="text-capitalize">
                                        {{
                                            route.service_type.replace(
                                                /_/g,
                                                " "
                                            )
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-lg-6">
                            <table class="w-100 ps-0 fw-bold" cellpadding="10">
                                <tr class="border-bottom">
                                    <td class="ps-0 w-80">Armada</td>
                                    <td class="w-30">:</td>
                                    <td>{{ route.vehicle_name }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="table-responsive-custom mt-3">
                        <table class="table table-bordered">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">
                                        Kota/Kab
                                    </th>
                                    <th class="text-center" scope="col">
                                        Harga
                                    </th>
                                    <th class="text-center" scope="col">
                                        Lead Time
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(routeList, index) in route?.routes"
                                    :key="index"
                                >
                                    <td>
                                        {{ routeList.destination_regency_name }}
                                    </td>
                                    <td>{{ currency(routeList.price) }}</td>
                                    <td>{{ routeList.leadtime }} Hari</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Semua data pada vendor ini akan dihapus
                    </h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="deleteData()"
                            :disabled="isLoadingDelete"
                        >
                            <span
                                v-if="isLoadingDelete"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Semua data pada vendor ini berhasil dihapus
                    </h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/vendor-management"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";

export default {
    props: ["roles"],
    data() {
        return {
            detail: null,
            isLoading: false,
            action: ["super-admin", "vendor-management"],
            modalConfirm: false,
            modalSuccess: false,
            isLoadingDelete: false,
            expandedSections: [],
        };
    },
    created() {
        this.getDetail();
        this.expandedSections = [0];
    },
    methods: {
        toggleSection(index) {
            if (this.expandedSections.includes(index)) {
                this.expandedSections = this.expandedSections.filter(
                    (i) => i !== index
                );
            } else {
                this.expandedSections.push(index);
            }
        },
        deleteData() {
            this.isLoadingDelete = true;
            var data = {
                vendor_id: this.$route.params.id,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/delete`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoadingDelete = false;
                    this.modalConfirm = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.isLoadingDelete = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        getDetail() {
            this.isLoading = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/detail?vendor_id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.isLoading = false;
                    this.detail = res.data.data;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.bb-r {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.rotate-180 {
    transform: rotate(180deg);
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    cursor: pointer;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.sub-title span {
    padding-right: 20px;
    background-color: #f9fafb;
}
.sub-title:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.7em;
    border-top: 1px solid #dee2e6;
    z-index: -1;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.blue-badge {
    background-color: #daecf6;
    border: 1px solid #daecf6;
    color: #3c87b1;
}
hr {
    color: #dee2e6 !important;
    margin: 1.5rem 0;
}
</style>
