<template>
    <div class="padding-container">
        <div class="box-manifest mt-3">
            <div class="d-flex">
                <p
                    class="cursor-pointer mb-0 text-center p-2 tab"
                    :class="{ active: activeTab === 'orderList' }"
                    @click="setActiveTab('orderList')"
                >
                    Daftar Orderan
                </p>
                <p
                    class="cursor-pointer mb-0 text-center p-2 tab"
                    :class="{ active: activeTab === 'changeRequest' }"
                    @click="setActiveTab('changeRequest')"
                >
                    Request Perubahan
                </p>
            </div>
            <div class="row justify-content-between gy-3 mt-2">
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-12 col-lg-6 my-2">
                            <div class="position-relative">
                                <div class="input-group">
                                    <input
                                        type="text"
                                        class="form-control form-control-lg pl-search w-search border-search"
                                        placeholder="Cari nama customer"
                                        v-model="search"
                                        @keyup.enter="filterData()"
                                    />
                                    <button
                                        class="btn btn-primary px-4"
                                        type="button"
                                        @click="filterData()"
                                    >
                                        Cari
                                    </button>
                                </div>
                                <span
                                    class="search-icon left position-absolute top-50 start-0 translate-middle-y ms-3"
                                >
                                    <i class="pi pi-search"></i>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-lg-3 my-2">
                            <button
                                class="btn btn-outline-yellow btn-filter transparent w-100"
                                @click="isFilter = !isFilter"
                            >
                                <img
                                    src="../../assets/dashboard/filter.png"
                                    class="me-1"
                                    width="18"
                                    alt=""
                                />
                                Filter
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-flex justify-content-end">
                    <button
                        class="btn btn-primary px-3 btn-with-search my-2 width-m-100"
                        :disabled="selectedRows.length === 0"
                        @click="modalPickDriver = true"
                    >
                        Tugaskan Driver
                    </button>
                </div>
            </div>
            <p class="mt-3 mb-0">
                Menampilkan
                <span class="fw-bold">{{ lists.length }}</span>
                Data
            </p>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-lg-4 mb-2">
                        <label class="fw-bold mb-2">Tanggal Request</label>
                        <DatePicker
                            v-model="date"
                            placeholder-text="Pilih tanggal request"
                        />
                    </div>
                    <div class="col-lg-4 mb-2">
                        <label class="fw-bold mb-2">CRM Specialist</label>
                        <multiselect
                            v-model="specialist"
                            :options="specialists"
                            label="name"
                            track-by="id"
                            placeholder="Pilih CRM specialist"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-4 mb-2">
                        <label class="fw-bold mb-2">Tags</label>
                        <multiselect
                            v-model="tag"
                            :options="tags"
                            multiple
                            placeholder="Pilih tags"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-4 mb-2">
                        <label class="fw-bold mb-2">Rekomendasi Armada</label>
                        <multiselect
                            v-model="fleetRecommend"
                            :options="fleetRecommends"
                            label="name"
                            track-by="code"
                            placeholder="Pilih rekomendasi armada"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-lg-4 mb-2">
                        <label class="invisible mb-2">invisible</label>
                        <div class="row">
                            <div class="col-lg-6 mbm-1">
                                <button
                                    class="btn btn-outline-yellow px-5 w-100"
                                    :disabled="
                                        date == null &&
                                        specialist == null &&
                                        tag == null &&
                                        fleetRecommend == null
                                    "
                                    @click="clearFilter()"
                                >
                                    Clear
                                </button>
                            </div>
                            <div class="col-lg-6">
                                <button
                                    class="btn btn-green px-5 w-100"
                                    :disabled="
                                        date == null &&
                                        specialist == null &&
                                        tag == null &&
                                        fleetRecommend == null
                                    "
                                    @click="filterData()"
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="isLoading">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">
                                    <div class="d-flex justify-content-center">
                                        <input
                                            type="checkbox"
                                            class="custom-checkbox"
                                            :checked="masterChecked"
                                            @change="toggleMasterCheckbox"
                                            :disabled="lists.length === 0"
                                        />
                                    </div>
                                </th>
                                <th class="text-center" scope="col">No</th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    :class="{
                                        'bg-change-request':
                                            activeTab === 'changeRequest',
                                    }"
                                >
                                    Tanggal Request
                                </th>
                                <th
                                    v-if="activeTab === 'changeRequest'"
                                    class="text-center bg-change-request"
                                    scope="col"
                                >
                                    Nama Driver Sebelumnya
                                </th>
                                <th
                                    v-if="activeTab === 'changeRequest'"
                                    class="text-center bg-change-request"
                                    scope="col"
                                >
                                    Alasan Perubahan
                                </th>
                                <th class="text-center" scope="col">
                                    Nama Customer
                                </th>
                                <th class="text-center" scope="col">
                                    CRM Specialist
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Asal
                                </th>
                                <th class="text-center" scope="col">
                                    Daerah Penjemputan
                                </th>
                                <th class="text-center" scope="col">
                                    Estimasi Berat Barang
                                </th>
                                <th class="text-center" scope="col">
                                    Deskripsi
                                </th>
                                <th class="text-center" scope="col">Tags</th>
                                <th class="text-center" scope="col">
                                    Rekomendasi Armada
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(list, index) in lists" :key="index">
                                <td>
                                    <div class="d-flex justify-content-center">
                                        <input
                                            type="checkbox"
                                            class="custom-checkbox"
                                            :checked="
                                                isRowSelected(
                                                    activeTab ===
                                                        'changeRequest'
                                                        ? list.delivery_assignation_id
                                                        : list.result_id
                                                )
                                            "
                                            @change="
                                                toggleRowCheckbox(
                                                    activeTab ===
                                                        'changeRequest'
                                                        ? list.delivery_assignation_id
                                                        : list.result_id
                                                )
                                            "
                                        />
                                    </div>
                                </td>
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="text-center">
                                    {{ list.requested_at }}
                                </td>
                                <td v-if="activeTab === 'changeRequest'">
                                    {{ list.driver_name }}
                                </td>
                                <td v-if="activeTab === 'changeRequest'">
                                    {{ list.reason }}
                                </td>
                                <td>{{ list.customer_name }}</td>
                                <td>{{ list.specialist }}</td>
                                <td>{{ list.origin }}</td>
                                <td>
                                    {{ list.address }}
                                </td>
                                <td class="text-center">
                                    {{ list.weight }}
                                    Kg
                                </td>
                                <td>{{ list.description }}</td>
                                <td>
                                    <div class="d-flex">
                                        <div
                                            v-for="(tag, index) in list.tags"
                                            :key="index"
                                            class="badge-tags badge-tags rounded-pill px-3 mx-1"
                                        >
                                            {{ tag }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {{ list.recommendation_fleet }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="lists.length == 0" class="text-center mt-5">
                    <img
                        src="../../assets/no-data-green.png"
                        width="100"
                        alt=""
                    />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar penugasan driver
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalPickDriver">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm driver">
                <div
                    class="d-flex align-items-center justify-content-between mb-3"
                >
                    <h5 class="fw-bold mb-0">Pilih Driver</h5>
                    <img
                        src="../../assets/close-icon.svg"
                        alt="close icon"
                        class="cursor-pointer"
                        @click="modalPickDriver = false"
                    />
                </div>
                <div class="position-relative mb-3">
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search"
                            placeholder="Cari dan pilih driver"
                            v-model="driverSearch"
                            @input="searchDriver"
                        />
                    </div>
                    <span
                        class="search-icon left position-absolute top-50 start-0 translate-middle-y ms-3"
                    >
                        <i class="pi pi-search text-gray"></i>
                    </span>
                </div>
                <div class="form-control p-3 scrollable-container">
                    <div v-if="filteredFleets.length > 0">
                        <div
                            v-for="(fleet, index) in filteredFleets"
                            :key="index"
                        >
                            <label class="container-radio">
                                <div class="d-flex">
                                    <div class="d-flex flex-column">
                                        <p class="mb-0">
                                            {{ fleet.driver }} |
                                            {{ fleet.fleet }}
                                        </p>
                                        <p class="text-gray mb-0">
                                            {{ fleet.pickup_count }} Orderan
                                        </p>
                                    </div>
                                </div>
                                <input
                                    type="radio"
                                    name="radio"
                                    :value="fleet.driver_fleet_id"
                                    v-model="selectedDriver"
                                />
                                <span class="checkmark"></span>
                            </label>
                            <hr v-if="index !== filteredFleets.length - 1" />
                        </div>
                    </div>
                    <div v-else class="text-center mt-4">
                        <img
                            src="../../assets/no-data-green.png"
                            width="60"
                            alt=""
                        />
                        <div class="mt-2 mb-4 text-gray">
                            Driver tidak ditemukan
                        </div>
                    </div>
                    <div class="text-center mt-4" v-if="fleets.length === 0">
                        <img
                            src="../../assets/no-data-green.png"
                            width="60"
                            alt=""
                        />
                        <div class="mt-2 mb-4 text-gray">
                            Belum ada daftar driver
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button
                        class="btn btn-primary px-4 btn-with-search mt-4"
                        :disabled="!selectedDriver"
                        @click="
                            (modalAssignConfirmation = true),
                                (modalPickDriver = false)
                        "
                    >
                        Tugaskan Driver
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalAssignConfirmation">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Driver
                        {{ activeTab === "changeRequest" ? "Baru" : "" }} Akan
                        Ditugaskan
                    </h5>
                    <div class="text-gray">
                        Pastikan data orderan dan driver yang dipilih sudah
                        benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="
                                (modalAssignConfirmation = false),
                                    (modalPickDriver = true)
                            "
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isAssign"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Driver
                        {{ activeTab === "changeRequest" ? "Baru" : "" }}
                        Berhasil Ditugaskan
                    </h5>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="(modalSuccess = false), getLists()"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import DatePicker from "../../components/DatePicker/DatePicker.vue";
import Multiselect from "vue-multiselect";
import ListLoader from "../../components/ListLoader.vue";

export default {
    components: {
        DatePicker,
        Multiselect,
        ListLoader,
    },
    data() {
        return {
            activeTab: "orderList",
            search: "",
            isFilter: false,
            date: null,
            specialist: null,
            specialists: [],
            tag: null,
            tags: [],
            fleetRecommend: null,
            fleetRecommends: [],
            driverSearch: "",
            filteredFleets: [],
            fleets: [],
            isLoading: false,
            lists: [],
            modalPickDriver: false,
            selectedRows: [],
            masterChecked: false,
            selectedDriver: null,
            modalAssignConfirmation: false,
            modalSuccess: false,
            isAssign: false,
        };
    },
    created() {
        this.getSpecialists();
        this.getTags();
        this.getFleetRecommends();
        this.getLists();
        this.getFleets();
    },
    methods: {
        setActiveTab(tab) {
            this.activeTab = tab;
            this.clearFilter();
        },
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getTags() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/tag`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.tags = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getFleetRecommends() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/fleet_type`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.fleetRecommends = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getLists() {
            let payload = {
                search: this.search,
            };
            if (this.date) {
                const localDate = new Date(this.date).toLocaleDateString(
                    "id-ID"
                );
                payload.date_request = localDate.split("/").reverse().join("-");
            }
            if (this.tag) {
                payload.tags = this.tag;
            }
            if (this.specialist) {
                payload.specialist_id = this.specialist?.id;
            }
            if (this.fleetRecommend) {
                payload.fleet = this.fleetRecommend?.code;
            }
            this.isLoading = true;
            const endpoint =
                this.activeTab === "changeRequest"
                    ? `${process.env.VUE_APP_SERVICE_URL}/pickup/assignee/request_driver`
                    : `${process.env.VUE_APP_SERVICE_URL}/pickup/assignee`;

            Api.get(endpoint, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.lists =
                        this.activeTab === "changeRequest"
                            ? res.data.data
                            : res.data.data;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.isLoading = false;
                });
        },
        clearFilter() {
            this.date = null;
            this.specialist = null;
            this.tag = null;
            this.fleetRecommend = null;
            this.selectedRows = [];
            this.masterChecked = false;
            this.getLists();
        },
        filterData() {
            this.selectedRows = [];
            this.masterChecked = false;
            this.getLists();
        },
        getFleets() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/driver_fleet`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.fleets = res.data.data;
                    this.filteredFleets = this.fleets;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        toggleMasterCheckbox() {
            this.masterChecked = !this.masterChecked;
            this.selectedRows = this.masterChecked
                ? this.lists.map((list) =>
                      this.activeTab === "changeRequest"
                          ? list.delivery_assignation_id
                          : list.result_id
                  )
                : [];
        },
        toggleRowCheckbox(itemId) {
            const position = this.selectedRows.indexOf(itemId);
            if (position === -1) {
                this.selectedRows.push(itemId);
            } else {
                this.selectedRows.splice(position, 1);
            }
            this.masterChecked = this.selectedRows.length === this.lists.length;
        },
        isRowSelected(itemId) {
            return this.selectedRows.includes(itemId);
        },
        searchDriver() {
            if (!this.driverSearch) {
                this.filteredFleets = this.fleets;
                return;
            }

            const searchTerm = this.driverSearch.toLowerCase();
            this.filteredFleets = this.fleets.filter((fleet) => {
                return (
                    fleet.driver.toLowerCase().includes(searchTerm) ||
                    fleet.fleet.toLowerCase().includes(searchTerm) ||
                    fleet.pickup_count.toString().includes(searchTerm)
                );
            });
        },
        storeData() {
            let data = {
                driver_id: this.selectedDriver,
                [this.activeTab === "changeRequest"
                    ? "delivery_assignation_id"
                    : "result_id"]: this.selectedRows,
            };
            this.isAssign = true;
            const endpoint =
                this.activeTab === "changeRequest"
                    ? `${process.env.VUE_APP_SERVICE_URL}/pickup/assigned/request_driver`
                    : `${process.env.VUE_APP_SERVICE_URL}/pickup/assignee`;
            Api.post(endpoint, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then(() => {
                    this.isAssign = false;
                    this.modalSuccess = true;
                    this.modalAssignConfirmation = false;
                })
                .catch((err) => {
                    this.isAssign = false;
                    this.modalAssignConfirmation = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
    watch: {
        activeTab() {
            this.selectedRows = [];
            this.masterChecked = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.bg-change-request {
    background-color: #fee1e1 !important;
}
.scrollable-container {
    max-height: calc(5 * 90px);
    overflow-y: auto;
}
.modal-vue .modal-body-chat.vsm.driver {
    width: 500px !important;
}
hr {
    color: #c1cdd7 !important;
}
.container-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 50%;
    left: 0%;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #a3b4c2;
    transform: translate(0%, -50%);
}

.container-radio input:checked ~ .checkmark {
    background-color: transparent;
    border: 2px solid #329a5f;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container-radio input:checked ~ .checkmark:after {
    display: block;
}

.container-radio .checkmark:after {
    top: 2.6px;
    left: 2.6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #329a5f;
}
.badge-tags {
    background-color: #e0e6eb !important;
    color: #3e4e5c !important;
    border: 1px solid #c2cdd6 !important;
}
.tab {
    color: #a3b4c2 !important;
    border-bottom: 1px solid #c1cdd7 !important;
}
.tab.active {
    color: #40bf76 !important;
    border-bottom: 2px solid #40bf76 !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.btn-filter.transparent {
    background: transparent !important;
}
.width-m-100 {
    width: 100%;
    @media (min-width: 992px) {
        width: fit-content;
    }
}
.mbm-1 {
    margin-top: 0;
    @media (max-width: 992px) {
        margin-bottom: 1rem !important;
    }
}
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}
.custom-checkbox:disabled {
    cursor: no-drop;
}
.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}
.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
</style>
