<template>
    <div class="padding-container">
        <div class="box-manifest">
            <h5 class="fw-bold">Daftar Rute</h5>
            <div class="row gy-3 mt-3">
                <div class="col-12 col-lg-5">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari rute asal/nama vendor"
                                v-model="search"
                                @keyup.enter="applySearch"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="applySearch"
                            >
                                Cari
                            </button>
                        </div>
                        <span
                            class="search-icon left position-absolute top-50 start-0 translate-middle-y ms-3"
                        >
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="col-12 col-lg-3 col-xl-2">
                    <button
                        class="btn btn-outline-yellow btn-filter transparent w-100"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
                <div
                    v-if="isRole(action)"
                    class="col-12 col-lg-4 col-xl-5 d-flex justify-content-end"
                >
                    <router-link
                        to="/route/add"
                        class="btn btn-primary px-3 btn-pesanan d-flex align-items-center justify-content-center"
                    >
                        <i class="pi pi-plus me-1 size-12"></i> Tambah Rute
                    </router-link>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="container-fluid">
                    <div class="row gy-3">
                        <div class="col-12 col-lg-3">
                            <label class="fw-bold mb-1"
                                >Jadwal Keberangkatan</label
                            >
                            <DateQARangePicker
                                v-model="date"
                                placeholder-text="Pilih tanggal"
                            />
                        </div>
                        <div class="col-12 col-lg-3">
                            <label class="fw-bold mb-1">Pilih Asal</label>
                            <multiselect
                                v-model="originRegency"
                                :options="regencies"
                                label="name"
                                track-by="id"
                                placeholder="Pilih kota asal"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-12 col-lg-3">
                            <label class="fw-bold mb-1">Kota Tujuan</label>
                            <multiselect
                                v-model="destinationRegency"
                                :options="regencies"
                                label="name"
                                track-by="id"
                                placeholder="Pilih kota tujuan"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-12 col-lg-3">
                            <label class="fw-bold mb-1">Armada</label>
                            <multiselect
                                v-model="fleet"
                                :options="fleets"
                                label="name"
                                track-by="code"
                                placeholder="Pilih Armada"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="d-flex flex-column flex-lg-row gap-2">
                                <button
                                    class="btn btn-outline-yellow flex-grow-1"
                                    :disabled="
                                        date.from === null &&
                                        date.to === null &&
                                        originRegency === null &&
                                        destinationRegency === null &&
                                        fleet === null
                                    "
                                    @click="clearFilters"
                                >
                                    Clear
                                </button>
                                <button
                                    class="btn btn-green flex-grow-1"
                                    :disabled="
                                        date.from === null &&
                                        date.to === null &&
                                        originRegency === null &&
                                        destinationRegency === null &&
                                        fleet === null
                                    "
                                    @click="applyFilters"
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center mt-4 flex-column flex-lg-row">
                <div>
                    Menampilkan
                    <span class="fw-bold"
                        >{{ displayStart }}-{{ displayEnd }}</span
                    >
                    Data dari
                    <span class="fw-bold">{{ totalItems }}</span>
                    Data
                </div>
                <div class="ms-auto ml-0-mobile mt-3 mt-lg-0">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Baris per halaman</div>
                        <select
                            class="form-control form-select w-select outline-yellow"
                            v-model="limit"
                            @change="handleLimitChange"
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="isLoading">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Kota Asal
                                </th>
                                <th class="text-center" scope="col">
                                    Provinsi Tujuan
                                </th>
                                <th class="text-center" scope="col">
                                    Nama Vendor
                                </th>
                                <th class="text-center" scope="col">
                                    Range Harga Vendor
                                </th>
                                <th class="text-center" scope="col">Layanan</th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="isRole(action)"
                                >
                                    Jadwal Terdekat
                                    <img
                                        src="../assets/sort.svg"
                                        alt="sort"
                                        class="ms-2 cursor-pointer"
                                        @click="toggleSortSchedule"
                                    />
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="isRole(action)"
                                >
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(list, index) in paginatedData"
                                :key="index"
                            >
                                <td class="text-center align-middle">
                                    {{ displayStart + index }}
                                </td>
                                <td class="align-middle">
                                    {{ list.origin_regency }}
                                </td>
                                <td class="align-middle">
                                    <div class="d-flex flex-column">
                                        <p class="mb-0 fw-bold">
                                            {{ list.destination_province }}
                                        </p>
                                        <p class="mb-0">
                                            {{ list.total_destination_regency }}
                                            Kota/Kab
                                        </p>
                                    </div>
                                </td>
                                <td class="align-middle">
                                    {{ list.vendor_name }}
                                </td>
                                <td class="align-middle text-end">
                                    {{ list.price_range }}
                                </td>
                                <td class="align-middle">
                                    <div
                                        class="badge-blue rounded-pill py-1 px-3 text-capitalize mb-2 text-center"
                                    >
                                        {{ list.service.replace(/_/g, " ") }}
                                    </div>
                                    <p class="mb-0 text-center">
                                        {{ list.vehicle_name }}
                                    </p>
                                </td>
                                <td class="align-middle" v-if="isRole(action)">
                                    <div
                                        class="d-flex cursor-pointer"
                                        v-if="!list.nearest_schedule"
                                        @click="openSidebarSchedule(list.ids)"
                                    >
                                        <p class="mb-0 text-link me-2">
                                            Edit Jadwal
                                        </p>
                                        <img
                                            src="../assets/edit.svg"
                                            width="20"
                                        />
                                    </div>
                                    <div
                                        class="d-flex cursor-pointer"
                                        v-else
                                        @click="openSidebarSchedule(list.ids)"
                                    >
                                        <p class="mb-0 text-link me-2">
                                            {{ list.nearest_schedule }}
                                        </p>
                                        <img
                                            src="../assets/edit.svg"
                                            width="20"
                                        />
                                    </div>
                                </td>
                                <td
                                    class="text-center align-middle"
                                    v-if="isRole(action)"
                                >
                                    <div class="d-flex">
                                        <div class="cursor-pointer">
                                            <img
                                                src="../assets/eye-action.svg"
                                                width="20"
                                                @click="
                                                    openSidebarDetail(list.ids)
                                                "
                                            />
                                        </div>
                                        <div
                                            class="ms-2 d-inline-block cursor-pointer"
                                            @click="openModalConfirm(list.ids)"
                                        >
                                            <img
                                                src="../assets/dashboard/trash-icon.png"
                                                width="20"
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center mt-5" v-if="paginatedData.length == 0">
                    <img
                        src="../assets/no-data-green.png"
                        width="100"
                        alt="no data green"
                    />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar vendor
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="paginatedData.length !== 0"
                        v-model="currentPage"
                        :number-of-pages="totalPages"
                        base-url="#"
                        first-number
                        align="right"
                        @change="handlePageChange"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
        <div
            class="sidebar-overlay"
            v-if="sidebarSchedule"
            @click="closeSidebarSchedule()"
        ></div>
        <div
            class="sidebar"
            v-if="sidebarSchedule"
            :class="{ 'sidebar-open': sidebarSchedule }"
        >
            <div
                class="d-flex justify-content-between align-items-center p-3 border-bottom sticky-header"
            >
                <h5 class="mb-0 fw-bold">Edit Jadwal Keberangkatan</h5>
                <div
                    type="button"
                    class="btn-close"
                    @click="closeSidebarSchedule()"
                ></div>
            </div>
            <div class="p-3" v-if="isLoadingSchedule">
                <div class="d-flex justify-content-center mt-5">
                    <img src="../assets/empty.svg" alt="empty" />
                </div>
                <p class="text-gray text-center mt-5">
                    Sedang mengambil data...
                </p>
            </div>
            <div class="p-3 mb-5-rem" v-if="!isLoadingSchedule">
                <h5 class="text-primary my-3 mb-4 text-center text-capitalize">
                    {{ schedule.vendor_name }}
                </h5>
                <div class="d-flex align-items-center">
                    <p class="mb-0">
                        {{ schedule.origin_regency_name }} -
                        {{ schedule.destination_province_name }}
                    </p>
                </div>
                <hr />
                <div
                    class="d-flex justify-content-between align-items-center mb-3"
                >
                    <h5 class="fw-bold mb-0">Jadwal Keberangkatan</h5>
                    <div
                        class="d-flex align-items-center cursor-pointer"
                        v-if="
                            schedule.schedules &&
                            schedule.schedules.length !== 0
                        "
                        @click="toggleUpdate()"
                    >
                        <img
                            src="../assets/update-icon.svg"
                            alt="update icon"
                            class="me-2"
                        />
                        <p class="mb-0">Update</p>
                    </div>
                </div>
                <div class="d-flex mb-3" v-if="isUpdate">
                    <img
                        src="../assets/schedule-dash-active.svg"
                        alt="schedule active"
                        class="me-3"
                    />
                    <div class="d-flex flex-column w-100">
                        <label class="mb-2"
                            >Jadwal Keberangkatan
                            <span class="text-yellow">*</span></label
                        >
                        <DatePicker
                            v-model="scheduleDate"
                            placeholder-text="Pilih tanggal dan jam"
                            disabled-past
                            with-time
                        />
                    </div>
                </div>
                <div
                    class="d-flex"
                    v-if="schedule.schedules && schedule.schedules.length === 0"
                >
                    <img
                        src="../assets/schedule-active.svg"
                        alt="schedule active"
                        class="me-3"
                    />
                    <div class="d-flex flex-column w-100">
                        <label class="mb-2"
                            >Jadwal Keberangkatan
                            <span class="text-yellow">*</span></label
                        >
                        <DatePicker
                            v-model="scheduleDate"
                            placeholder-text="Pilih tanggal dan jam"
                            disabled-past
                            with-time
                        />
                    </div>
                </div>
                <div
                    class="d-flex"
                    v-else
                    v-for="(schedule, index) in schedule.schedules"
                    :key="index"
                >
                    <img
                        :src="getScheduleImage(schedule, index)"
                        :alt="getScheduleImage(schedule, index)"
                        class="me-3"
                    />
                    <p class="mb-0 text-schedule">{{ schedule.schedule }}</p>
                </div>
            </div>
            <div class="p-3 sticky-footer" v-if="!isLoadingSchedule">
                <button
                    class="btn btn-primary w-100"
                    @click="modalConfirmSchedule = true"
                    :disabled="!scheduleDate"
                >
                    Simpan Jadwal
                </button>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirmSchedule">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Update Informasi jadwal keberangkatan akan disimpan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmSchedule = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isLoadingUpdateSchedule"
                            @click="updateSchedule()"
                        >
                            <span
                                v-if="isLoadingUpdateSchedule"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccessSchedule">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Informasi jadwal keberangkatan berhasil diupdate
                    </h5>
                </div>
                <div class="mt-4">
                    <div
                        class="btn btn-primary w-100 btn-lg"
                        @click="
                            (modalSuccessSchedule = false),
                                getLists(),
                                (sidebarSchedule = false)
                        "
                    >
                        Tutup
                    </div>
                </div>
            </div>
        </div>
        <div
            class="sidebar-overlay"
            v-if="sidebarDetail"
            @click="closeSidebarDetail()"
        ></div>
        <div
            class="sidebar"
            v-if="sidebarDetail"
            :class="{ 'sidebar-open': sidebarDetail }"
        >
            <div
                class="d-flex justify-content-between align-items-center p-3 border-bottom sticky-header"
            >
                <h5 class="mb-0 fw-bold">Informasi Rute</h5>
                <div
                    type="button"
                    class="btn-close"
                    @click="closeSidebarDetail()"
                ></div>
            </div>
            <div class="p-3" v-if="isLoadingDetail">
                <div class="d-flex justify-content-center mt-5">
                    <img src="../assets/empty.svg" alt="empty" />
                </div>
                <p class="text-gray text-center mt-5">
                    Sedang mengambil data...
                </p>
            </div>
            <div class="p-3 mb-5-rem" v-if="!isLoadingDetail">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="text-primary mt-3 mb-0 text-capitalize">
                        {{ detail.vendor.vendor_name }}
                    </h5>
                    <router-link
                        :to="
                            'vendor-management/detail/' +
                            detail.vendor.vendor_id
                        "
                        class="mt-3 mb-0 text-link"
                        >Detail Vendor</router-link
                    >
                </div>
                <hr />
                <div class="row justify-content-between align-items-center">
                    <div class="col-lg-6 mb-3 d-flex align-items-center">
                        <img class="me-3" src="../assets/call.svg" alt="call" />
                        <a
                            :href="'tel:' + detail.vendor.vendor_phone"
                            class="text-link"
                            >{{ detail.vendor.vendor_phone }}</a
                        >
                    </div>
                    <div class="col-lg-6 mb-3 d-flex align-items-center">
                        <img class="me-3" src="../assets/mail.svg" alt="mail" />
                        <a
                            :href="'mail:' + detail.vendor.vendor_email"
                            class="text-link"
                            >{{ detail.vendor.vendor_email }}</a
                        >
                    </div>
                    <div class="col-lg-6 mb-3 d-flex align-items-center">
                        <img class="me-3" src="../assets/pin.svg" alt="pin" />
                        {{ detail.vendor.regency_name }}
                    </div>
                    <div class="col-lg-6 mb-3 d-flex align-items-center">
                        <img class="me-3" src="../assets/pin.svg" alt="pin" />
                        {{ detail.vendor.province_name }}
                    </div>
                    <div class="col-lg-12 mb-3 d-flex align-items-start">
                        <img class="me-3" src="../assets/pin.svg" alt="pin" />
                        {{ detail.vendor.address }}
                    </div>
                </div>
                <hr />
                <h5 class="mb-3 fw-bold" v-if="!isLoadingDetail">
                    Rute & Keberangkatan
                </h5>
                <div class="mb-4" v-if="!isLoadingDetail">
                    <div class="header-sidebar px-3 py-2">
                        <p class="mb-0">
                            {{
                                detail.route_calculate
                                    .route_destination_province_name
                            }}
                            ({{ detail.route_calculate.route_regency_total }}
                            Kab/Kota)
                        </p>
                    </div>
                    <div class="body-sidebar px-3 py-2">
                        <div class="row">
                            <div
                                class="col-lg-6 col-6 mb-3 d-flex align-items-center"
                            >
                                <img src="../assets/route.svg" alt="route" />
                                <p class="mb-0 ms-2 text-capitalize">
                                    {{
                                        detail.route_calculate.service_type.replace(
                                            /_/g,
                                            " "
                                        )
                                    }}
                                </p>
                            </div>
                            <div
                                class="col-lg-6 col-6 mb-3 d-flex align-items-center"
                            >
                                <p class="mb-0 ms-2">
                                    {{ detail.route_calculate.vehicle_name }}
                                </p>
                            </div>
                        </div>
                        <div class="table-responsive-custom">
                            <table class="table table-bordered">
                                <thead class="table-light">
                                    <tr>
                                        <th class="text-center" scope="col">
                                            Kota/Kab
                                        </th>
                                        <th class="text-center" scope="col">
                                            Harga
                                        </th>
                                        <th class="text-center" scope="col">
                                            Lead Time
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(route, index) in filteredRoutes"
                                        :key="index"
                                    >
                                        <td>
                                            {{ route.destination_regency_name }}
                                        </td>
                                        <td>
                                            {{ currency(route.route_price) }}
                                        </td>
                                        <td>{{ route.leadtime }} Hari</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3 sticky-footer" v-if="!isLoadingDetail">
                <router-link
                    :to="'/route/edit?route_ids=' + detail.route_ids"
                    class="btn btn-primary w-100"
                >
                    Edit Rute
                </router-link>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Informasi rute vendor ini akan dihapus
                    </h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isLoadingDelete"
                            @click="deleteData()"
                        >
                            <span
                                v-if="isLoadingDelete"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Informasi rute vendor berhasil dihapus
                    </h5>
                </div>
                <div class="mt-4">
                    <div
                        class="btn btn-primary w-100 btn-lg"
                        @click="
                            modalSuccess = false;
                            getLists();
                        "
                    >
                        Tutup
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";
import Multiselect from "vue-multiselect";
import DateQARangePicker from "../components/DatePicker/DateQARangePicker.vue";
import DatePicker from "../components/DatePicker/DatePicker.vue";
import scheduleActive from "../assets/schedule-active.svg";
import schedulePassive from "../assets/schedule-passive.svg";
import scheduleDashActive from "../assets/schedule-dash-active.svg";
import scheduleDashPassive from "../assets/schedule-dash-passive.svg";
import moment from "moment";
import "moment/locale/id";

export default {
    components: {
        ListLoader,
        Multiselect,
        DateQARangePicker,
        DatePicker,
    },
    props: ["roles"],
    data() {
        return {
            search: null,
            searchValue: null,
            isFilter: false,
            originRegency: null,
            destinationRegency: null,
            regencies: [],
            fleet: null,
            fleets: [],
            date: {
                from: null,
                to: null,
            },
            limit: 5,
            isLoading: false,
            allLists: [],
            filteredLists: [],
            currentPage: 1,
            sortSchedule: null,
            activeFilters: {
                originRegency: null,
                destinationRegency: null,
                fleet: null,
                dateFrom: null,
                dateTo: null,
                search: null,
                vendorType: null,
            },
            isLoadingSchedule: false,
            schedule: null,
            sidebarSchedule: false,
            scheduleDate: null,
            isUpdate: false,
            scheduleActive: scheduleActive,
            schedulePassive: schedulePassive,
            scheduleDashActive: scheduleDashActive,
            scheduleDashPassive: scheduleDashPassive,
            modalConfirmSchedule: false,
            isLoadingUpdateSchedule: false,
            modalSuccessSchedule: false,
            sidebarDetail: false,
            detail: null,
            isLoadingDetail: false,
            action: ["super-admin", "vendor-management", "finance"],
            modalConfirm: false,
            modalSuccess: false,
            deletedData: null,
            isLoadingDelete: false,
        };
    },
    computed: {
        filteredRoutes() {
            return this.detail.routes.filter((route) => !route.deleted);
        },
        totalItems() {
            return this.filteredLists.length;
        },
        totalPages() {
            return Math.ceil(this.totalItems / this.limit);
        },
        paginatedData() {
            const start = (this.currentPage - 1) * this.limit;
            const end = start + parseInt(this.limit);
            return this.filteredLists.slice(start, end);
        },
        displayStart() {
            if (this.totalItems === 0) return 0;
            return (this.currentPage - 1) * this.limit + 1;
        },
        displayEnd() {
            const end = this.currentPage * this.limit;
            return end > this.totalItems ? this.totalItems : end;
        },
    },
    created() {
        this.getRegencies();
        this.getFleets();
        this.getLists();
        this.$watch(
            () => this.$route.query,
            (newQuery) => {
                if (newQuery.type !== this.activeFilters.vendorType) {
                    this.activeFilters.vendorType = newQuery.type || null;
                    this.applyAllFilters();
                }
            }
        );

        this.activeFilters.vendorType = this.$route.query.type || null;
        moment.locale("id");
    },
    watch: {
        $route(to, from) {
            if (to.query.type !== from.query.type) {
                this.activeFilters.vendorType = to.query.type || null;
                this.applyAllFilters();
            }
        },
        isUpdate(newValue) {
            if (!newValue) {
                this.scheduleDate = null;
            }
        },
    },
    methods: {
        deleteData() {
            this.isLoadingDelete = true;
            var data = {
                route_ids: this.deletedData,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/delete`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoadingDelete = false;
                    this.modalSuccess = true;
                    this.modalConfirm = false;
                })
                .catch((err) => {
                    this.isLoadingDelete = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        openModalConfirm(value) {
            this.modalConfirm = true;
            this.deletedData = value;
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        updateSchedule() {
            this.isLoadingUpdateSchedule = true;
            const data = {
                vendor_id: this.schedule.vendor_id,
                service_type: this.schedule.service_type,
                origin_regency_id: this.schedule.origin_regency_id,
                destination_province_id: this.schedule.destination_province_id,
                schedule: moment(this.scheduleDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                ),
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/schedule/create`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoadingUpdateSchedule = false;
                    this.modalSuccessSchedule = true;
                    this.modalConfirmSchedule = false;
                })
                .catch((err) => {
                    this.isLoadingUpdateSchedule = false;
                    this.modalConfirmSchedule = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getRegencies() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.regencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getFleets() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/fleet_type`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.fleets = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getLists() {
            this.isLoading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/vendors/routes`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.isLoading = false;
                    this.allLists = res.data.data;
                    this.applyAllFilters();
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        applyAllFilters() {
            let filtered = [...this.allLists];
            if (this.activeFilters.vendorType) {
                filtered = filtered.filter(
                    (item) =>
                        item.vendor_type &&
                        item.vendor_type.includes(this.activeFilters.vendorType)
                );
            }
            if (this.activeFilters.originRegency) {
                filtered = filtered.filter(
                    (item) =>
                        item.origin_regency === this.activeFilters.originRegency
                );
            }
            if (this.activeFilters.destinationRegency) {
                filtered = filtered.filter((item) =>
                    item.destination_regency_ids.includes(
                        this.activeFilters.destinationRegency
                    )
                );
            }
            if (this.activeFilters.fleet) {
                filtered = filtered.filter(
                    (item) => item.vehicle_name === this.activeFilters.fleet
                );
            }
            if (this.activeFilters.dateFrom && this.activeFilters.dateTo) {
                const fromDate = new Date(this.activeFilters.dateFrom);
                const toDate = new Date(this.activeFilters.dateTo);

                filtered = filtered.filter((item) => {
                    if (!item.nearest_schedule_raw) return false;
                    const dateParts = item.nearest_schedule_raw.split(" ");
                    const day = parseInt(dateParts[0]);
                    const monthMap = {
                        Januari: 0,
                        Februari: 1,
                        Maret: 2,
                        April: 3,
                        Mei: 4,
                        Juni: 5,
                        Juli: 6,
                        Agustus: 7,
                        September: 8,
                        Oktober: 9,
                        November: 10,
                        Desember: 11,
                    };

                    const month = monthMap[dateParts[1]];
                    const year = parseInt(dateParts[2]);

                    const scheduleDate = new Date(year, month, day);

                    return scheduleDate >= fromDate && scheduleDate <= toDate;
                });
            }
            if (this.activeFilters.search) {
                const searchTerm = this.activeFilters.search.toLowerCase();
                filtered = filtered.filter(
                    (item) =>
                        item.origin_regency
                            .toLowerCase()
                            .includes(searchTerm) ||
                        item.vendor_name.toLowerCase().includes(searchTerm)
                );
            }
            if (this.sortSchedule) {
                filtered.sort((a, b) => {
                    if (!a.nearest_schedule_raw && !b.nearest_schedule_raw)
                        return 0;
                    if (!a.nearest_schedule_raw) return 1;
                    if (!b.nearest_schedule_raw) return -1;
                    const aDateParts = a.nearest_schedule_raw.split(" ");
                    const bDateParts = b.nearest_schedule_raw.split(" ");

                    const monthMap = {
                        Januari: 0,
                        Februari: 1,
                        Maret: 2,
                        April: 3,
                        Mei: 4,
                        Juni: 5,
                        Juli: 6,
                        Agustus: 7,
                        September: 8,
                        Oktober: 9,
                        November: 10,
                        Desember: 11,
                    };

                    const aDate = new Date(
                        parseInt(aDateParts[2]),
                        monthMap[aDateParts[1]],
                        parseInt(aDateParts[0])
                    );

                    const bDate = new Date(
                        parseInt(bDateParts[2]),
                        monthMap[bDateParts[1]],
                        parseInt(bDateParts[0])
                    );
                    return this.sortSchedule === "asc"
                        ? aDate - bDate
                        : bDate - aDate;
                });
            }

            this.filteredLists = filtered;
            this.currentPage = 1;
        },
        toggleSortSchedule() {
            if (!this.sortSchedule) {
                this.sortSchedule = "asc";
            } else if (this.sortSchedule === "asc") {
                this.sortSchedule = "desc";
            } else {
                this.sortSchedule = null;
            }
            this.applyAllFilters();
        },
        applySearch() {
            this.activeFilters.search = this.search;
            this.applyAllFilters();
        },
        applyFilters() {
            this.activeFilters.originRegency = this.originRegency
                ? this.originRegency.name
                : null;
            this.activeFilters.destinationRegency = this.destinationRegency
                ? this.destinationRegency.id
                : null;
            this.activeFilters.fleet = this.fleet ? this.fleet.code : null;
            this.activeFilters.dateFrom = this.date.from;
            this.activeFilters.dateTo = this.date.to;

            this.applyAllFilters();
        },
        clearFilters() {
            this.originRegency = null;
            this.destinationRegency = null;
            this.fleet = null;
            this.date = {
                from: null,
                to: null,
            };
            this.activeFilters.originRegency = null;
            this.activeFilters.destinationRegency = null;
            this.activeFilters.fleet = null;
            this.activeFilters.dateFrom = null;
            this.activeFilters.dateTo = null;

            this.applyAllFilters();
        },
        handleLimitChange() {
            this.currentPage = 1;
        },
        handlePageChange(page) {
            this.currentPage = page;
        },
        getSchedules(routeIds) {
            this.isLoadingSchedule = true;
            const payload = {
                route_ids: routeIds,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/schedule/detail`,
                {
                    params: payload,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.isLoadingSchedule = false;
                    this.schedule = res.data.data;
                })
                .catch((err) => {
                    this.isLoadingSchedule = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDetail(routeIds) {
            this.isLoadingDetail = true;
            const payload = {
                route_ids: routeIds,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/detail`,
                {
                    params: payload,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.isLoadingDetail = false;
                    this.detail = res.data.data;
                })
                .catch((err) => {
                    this.isLoadingDetail = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        openSidebarSchedule(routeIds) {
            this.isUpdate = false;
            this.sidebarSchedule = true;
            this.getSchedules(routeIds);
        },
        closeSidebarSchedule() {
            this.isUpdate = false;
            this.sidebarSchedule = false;
            this.schedule = null;
        },
        openSidebarDetail(routeIds) {
            this.sidebarDetail = true;
            this.getDetail(routeIds);
        },
        closeSidebarDetail() {
            this.sidebarDetail = false;
            this.detail = null;
        },
        toggleUpdate() {
            this.isUpdate = !this.isUpdate;
        },
        getScheduleImage(schedule, index) {
            const isLastSchedule = index === this.schedule.schedules.length - 1;
            const isSingleSchedule = this.schedule.schedules.length === 1;

            const scheduleDate = moment(
                schedule.schedule,
                "DD MMMM YYYY | HH:mm"
            );
            const currentDate = moment();

            if (!scheduleDate.isValid()) {
                return this.scheduleDashPassive;
            }

            if (scheduleDate.isBefore(currentDate)) {
                if (isLastSchedule) {
                    return this.schedulePassive;
                }
                return this.scheduleDashPassive;
            }

            return isSingleSchedule || isLastSchedule
                ? this.scheduleActive
                : this.scheduleDashActive;
        },
    },
};
</script>

<style lang="scss" scoped>
.width-m-100 {
    width: 100%;
    @media (min-width: 992px) {
        width: fit-content;
    }
}
.ml-0-mobile {
    margin-left: 0 !important;
}
@media only screen and (min-width: 992px) {
    .ml-0-mobile {
        margin-left: auto !important;
    }
}
.sticky-header {
    position: sticky;
    top: 0;
    background-color: #f9fafb;
    z-index: 1;
}
.sticky-footer {
    position: fixed;
    bottom: 0;
    background: #f9fafb;
    width: 100%;
    max-width: 500px;
    padding: 1rem;
    border-top: 1px solid #dee2e6;

    @media (max-width: 576px) {
        max-width: 100%;
        padding: 1rem;
    }
}
.text-schedule {
    color: #40bf76;
}
::v-deep .multi-form-custom.magnifier {
    .multiselect__tags {
        padding-left: 40px !important;
    }
    .multiselect__select {
        padding: 0;
    }
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.b-l-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.b-r-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.rotate-180 {
    transform: rotate(180deg);
}

.transition-transform {
    transition: transform 0.3s ease;
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
hr {
    color: #dee2e6 !important;
    margin: 1.5rem 0;
}
.input-sidebar {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.bb-r {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1040;
}
.sidebar {
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 500px;
    height: 100vh;
    background-color: #f9fafb;
    z-index: 1050;
    transition: right 0.3s ease-in-out;
    box-shadow: -8px 0 10px -6px rgba(0, 0, 0, 0.1);
    margin-top: 55px;

    @media (max-width: 576px) {
        margin-top: 0;
        width: 100%;
        max-width: 100%;
    }
}

.sidebar-open {
    right: 0;
}
.modal-vue {
    position: fixed;
    z-index: 9999;
}
.btn-green:disabled {
    background: #e0e6eb !important;
    border: none !important;
    color: #849bae !important;
}
td {
    background-color: transparent !important;
}
th {
    background-color: #f3f5f7 !important;
}
.service {
    background-color: #cce7ff !important;
    border: 1px solid #99d0ff !important;
    color: #005299 !important;
}
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.outline-yellow:focus {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.mb-5-rem {
    margin-bottom: 40rem;
}
.badge-blue {
    background-color: #cce7ff;
    border: 1px solid #99d0ff;
    color: #005299;
}
.btn-filter.transparent {
    background: transparent !important;
}
</style>
