<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <div class="d-flex align-items-center justify-content-between mb-4">
                <div class="d-flex">
                    <h5 class="fw-bold mb-0">
                        Detail Manifest - {{ detail.manifest_code }}
                    </h5>
                    <div
                        class="rounded-pill process d-flex align-items-center ms-4 px-4"
                        v-if="detail.status === 'process'"
                    >
                        Dalam Proses
                    </div>
                </div>
                <div
                    class="rounded-pill ontheway d-flex align-items-center ms-4 px-4"
                    v-if="detail.status === 'ontheway'"
                >
                    Dalam Perjalanan
                </div>
                <div
                    class="rounded-pill end d-flex align-items-center ms-4 px-4"
                    v-if="detail.status === 'finish'"
                >
                    Selesai
                </div>
                <div
                    class="d-flex"
                    v-if="detail.status === 'process' && isRole(action)"
                >
                    <button
                        class="btn btn-delete rounded d-flex align-items-center me-4"
                        @click="modalConfirm = true"
                    >
                        <img
                            src="../assets/trash-button.svg"
                            alt="trash button"
                            class="me-2"
                        />
                        Hapus
                    </button>
                    <router-link
                        :to="`/manifest/edit/` + detail.id"
                        class="btn btn-edit rounded d-flex align-items-center"
                    >
                        <img
                            src="../assets/edit-button.svg"
                            alt="trash button"
                            class="me-2"
                        />
                        Edit
                    </router-link>
                </div>
            </div>
            <div class="row">
                <div class="mb-4 col-lg-6 position-relative">
                    <label class="mb-2">
                        Kota/Kabupaten Asal Manifest
                        <span class="text-yellow">*</span>
                    </label>
                    <div class="position-relative">
                        <img
                            src="../assets/magnifier.svg"
                            alt="icon"
                            class="input-icon"
                        />
                        <input
                            type="text"
                            class="form-control form-control-lg input-read-only pl-40"
                            :value="detail.origin_regency"
                            readonly
                        />
                    </div>
                </div>
                <div class="mb-4 col-lg-6">
                    <label class="mb-2"
                        >Kota/Kabupaten Tujuan Manifest
                        <span class="text-yellow">*</span></label
                    >
                    <div class="position-relative">
                        <img
                            src="../assets/magnifier.svg"
                            alt="icon"
                            class="input-icon"
                        />
                        <input
                            type="text"
                            class="form-control form-control-lg input-read-only pl-40"
                            :value="detail.destination_regency"
                            readonly
                        />
                    </div>
                </div>
                <div class="mb-4 col-lg-12">
                    <div class="row col-lg-6">
                        <label class="mb-2">Pilih Vendor</label>
                        <div class="position-relative">
                            <img
                                src="../assets/magnifier.svg"
                                alt="icon"
                                class="input-icon-vendor"
                            />
                            <input
                                type="text"
                                class="form-control form-control-lg input-read-only pl-40"
                                :value="detail.vendor"
                                readonly
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="mb-4 col-lg-6">
                <div class="box-manifest">
                    <h5 class="fw-bold mb-4">Nomor Resi Yang Dipilih</h5>
                    <div>
                        <div
                            v-for="(receipt, index) in detail.orders"
                            :key="index"
                            class="d-flex justify-content-between align-items-center py-2"
                            :class="{
                                'border-bottom':
                                    index < detail.orders.length - 1,
                            }"
                        >
                            <p class="mb-0 fw-bold">
                                {{ receipt.receipt }}
                            </p>
                            <p class="mb-0 me-2">{{ receipt.regency }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-4 col-lg-6">
                <div class="box-manifest">
                    <h5 class="fw-bold mb-0">Ringkasan Manifest</h5>
                    <table class="w-100 mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="pl-0">
                                <div class="d-flex align-items-center">
                                    <img
                                        src="../assets/rute.svg"
                                        alt="rute"
                                        class="me-2"
                                    />
                                    Rute Manifest
                                </div>
                            </td>
                            <td>:</td>
                            <td class="fw-bold">
                                {{ detail.origin_regency }} -
                                {{ detail.destination_regency }}
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="pl-0">
                                <div class="d-flex align-items-center">
                                    <img
                                        src="../assets/jumlah.svg"
                                        alt="jumlah"
                                        class="me-2"
                                    />
                                    Jumlah Resi
                                </div>
                            </td>
                            <td>:</td>
                            <td class="fw-bold">
                                {{ detail.orders.length }} Resi
                            </td>
                        </tr>
                        <tr>
                            <td class="pl-0">
                                <div class="d-flex align-items-center">
                                    <img
                                        src="../assets/arrive.svg"
                                        alt="arrive"
                                        class="me-2"
                                    />
                                    Waktu Tiba
                                </div>
                            </td>
                            <td>:</td>
                            <td class="fw-bold">
                                <input
                                    type="text"
                                    class="form-control form-control-lg input-read-only"
                                    readonly
                                    :value="
                                        formattedDate(
                                            detail.estimated_time_original
                                        )
                                    "
                                />
                            </td>
                        </tr>
                    </table>
                    <div class="row mt-2">
                        <div
                            class="col-lg-6"
                            :class="{
                                'col-lg-6': detail.type === 'dooring',
                                'col-lg-12': detail.type === 'transit',
                            }"
                        >
                            <button
                                class="print btn d-flex primary align-items-center text-white py-2 px-3 w-100 justify-content-center align-items-center"
                                :disabled="isPrint"
                                @click="getManifest()"
                            >
                                <img
                                    src="../assets/print.svg"
                                    alt="print"
                                    class="me-3"
                                />
                                Cetak Manifest
                            </button>
                        </div>
                        <div class="col-lg-6" v-if="detail.type === 'dooring'">
                            <button
                                class="print btn d-flex primary align-items-center text-white py-2 px-3 w-100 justify-content-center align-items-center"
                                :disabled="isPrint"
                                @click="getSttb()"
                            >
                                <img
                                    src="../assets/print.svg"
                                    alt="print"
                                    class="me-3"
                                />
                                Cetak STTB
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">Manifest Akan Dihapus</h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus manifest ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            @click="deleteManifest()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isLoading"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isLoading"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">Manifest Berhasil Dihapus</h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/manifest"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import moment from "moment";

export default {
    props: ["roles"],
    data() {
        return {
            isPageLoading: false,
            modalConfirm: false,
            modalSuccess: false,
            isLoading: false,
            detail: {
                orders: [],
            },
            moment: moment,
            action: ["tracking", "super-admin"],
            isPrint: false,
        };
    },
    created() {
        this.getDetailManifest();
    },
    methods: {
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        formattedDate(param) {
            return moment(param).format("DD MMMM YYYY");
        },
        deleteManifest() {
            this.isLoading = true;

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/manifest/delete`,
                { manifest_id: this.$route.params.id },
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDetailManifest() {
            this.isPageLoading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/manifest/detail`, {
                params: {
                    manifest_id: this.$route.params.id,
                },
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.detail = res.data.data;
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getManifest() {
            this.isPrint = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/manifest/print`, {
                params: {
                    manifest_code: this.detail.manifest_code,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    const data = res.data.data;
                    const link = document.createElement("a");
                    link.href = data.link;
                    link.rel = "noopener noreferrer";
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    this.isPrint = false;
                })
                .catch((err) => {
                    this.isPrint = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getSttb() {
            this.isPrint = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/manifest/sttb`, {
                params: {
                    manifest_code: this.detail.manifest_code,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    const data = res.data.data;
                    const link = document.createElement("a");
                    link.href = data.link;
                    link.rel = "noopener noreferrer";
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    this.isPrint = false;
                })
                .catch((err) => {
                    this.isPrint = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.primary:hover,
.primary:focus,
.primary {
    background-color: #1291ff !important;
    border: transparent !important;
    color: white !important;
}
.end {
    background-color: #d9f2e4;
    color: #267347;
    border: 1px solid #b2e6c8;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.process {
    background-color: #ffe7cc;
    border: 1px solid #ffcf99;
    color: #995100;
}
.ontheway {
    background-color: #cce7ff;
    border: 1px solid #99d0ff;
    color: #005299;
}
.btn-delete {
    border: 1px solid #ff8600;
    background-color: transparent;
    color: #ff8600;
    border-radius: 8px !important;
}
.btn-edit {
    background-color: #1291ff;
    color: #ffffff;
    border-radius: 8px !important;
}
.btn-delete:hover {
    border: 1px solid #ff8600;
    background-color: transparent;
    color: #ff8600;
    border-radius: 8px !important;
}
.btn-edit:hover {
    background-color: #1291ff;
    color: #ffffff;
    border-radius: 8px !important;
}
.btn-delete:active {
    border: 1px solid #ff8600 !important;
    background-color: transparent !important;
    color: #ff8600 !important;
    border-radius: 8px !important;
}
.btn-edit:active {
    background-color: #1291ff !important;
    color: #ffffff !important;
    border-radius: 8px !important;
}
.input-read-only {
    background-color: #f3f5f7 !important;
    border: 1px solid #c1cdd7 !important;
}
.input-read-only:focus {
    background-color: #f3f5f7 !important;
    border: 1px solid #c1cdd7 !important;
}
.input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
}
.input-icon-vendor {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
}
.pl-40 {
    padding-left: 40px;
}
</style>
