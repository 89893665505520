<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div
            class="d-flex flex-wrap justify-content-between align-items-center"
        >
            <div class="mb-3">
                <h5 class="fw-bold">
                    Halo {{ user.name }} 👋,
                    <span>Selamat Datang Kembali!</span>
                </h5>
                <p class="text-gray mb-0">
                    Menampilkan data tanggal
                    <span v-if="hasDateRange" class="fw-bold text-primary">
                        {{ formatDateRange(dateRange.from, dateRange.to) }}
                    </span>
                    <span class="fw-bold" v-else>{{
                        date.format("DD MMMM YYYY")
                    }}</span>
                </p>
            </div>
            <DateRangePicker
                v-model="dateRange"
                :onFilter="filterData"
                :onReset="resetData"
            />
        </div>
        <div class="row my-3">
            <div class="col-lg-4 my-3">
                <div class="box-orange h-100">
                    <p class="fw-bold">Penugasan Driver</p>
                    <div class="box-danger">
                        <div class="d-flex">
                            <img
                                src="../../assets/bell-dashboard.svg"
                                alt="bell dashboard"
                                class="me-3"
                            />
                            <div class="d-flex flex-column">
                                <p class="text-white mb-2">
                                    Perlu Penugasan Driver
                                </p>
                                <router-link
                                    to="/assignment/driver"
                                    class="d-flex align-items-center"
                                >
                                    <h5 class="text-white border-bottom me-2">
                                        {{ datas.driver_assignation }}
                                        Penjemputan
                                    </h5>
                                    <img
                                        src="../../assets/arrow-dashboard.svg"
                                        alt="arrow dashboard"
                                        width="20"
                                        height="20"
                                    />
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 my-3">
                <div
                    class="box h-100 d-flex"
                    :class="{ 'box-shadow': hasDateRange }"
                >
                    <div
                        class="d-flex flex-column flex-lg-row justify-content-between align-items-center gap-3 w-100"
                    >
                        <div class="d-flex w-100">
                            <img
                                src="../../assets/pack-dashboard.svg"
                                alt="pack dashboard"
                                class="me-3"
                            />
                            <div class="d-flex flex-column">
                                <p class="mb-2">Total Barang</p>
                                <h5 class="fw-bold">
                                    {{ datas.item.total_item }} Koli
                                </h5>
                            </div>
                        </div>
                        <hr class="d-none d-md-block vertical-divider" />
                        <div class="d-flex w-100">
                            <img
                                src="../../assets/weight-dashboard.svg"
                                alt="weight dashboard"
                                class="me-3"
                            />
                            <div class="d-flex flex-column">
                                <p class="mb-2">Total Berat Barang</p>
                                <h5 class="fw-bold">
                                    {{ datas.item.total_weight }} Kg
                                </h5>
                            </div>
                        </div>
                        <hr class="d-none d-md-block vertical-divider" />
                        <div class="d-flex w-100">
                            <img
                                src="../../assets/cubic-dashboard.svg"
                                alt="cubic dashboard"
                                class="me-3"
                            />
                            <div class="d-flex flex-column">
                                <p class="mb-2">Total Kubikasi</p>
                                <h5 class="fw-bold">
                                    {{ datas.item.total_cubic }} M3
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 my-3">
                <div class="box h-100" :class="{ 'box-shadow': hasDateRange }">
                    <p v-if="hasDateRange">
                        Penjemputan
                        {{ formatDateRange(dateRange.from, dateRange.to) }}
                    </p>
                    <p v-else>Penjemputan Hari Ini</p>
                    <h5 class="text-black me-2 fw-bold" v-if="hasDateRange">
                        {{ datas.pickup.process + datas.pickup.success }}
                        Penjemputan
                    </h5>
                    <router-link
                        v-else
                        to="/assignment/list"
                        class="d-flex align-items-center mb-3"
                    >
                        <h5
                            class="text-black border-black border-bottom me-2 fw-bold"
                        >
                            {{ datas.pickup.process + datas.pickup.success }}
                            Penjemputan
                        </h5>
                        <img
                            src="../../assets/arrow-dashboard-2.svg"
                            alt="arrow dashboard"
                            width="20"
                            height="20"
                        />
                    </router-link>
                    <div class="d-flex align-items-center mb-3">
                        <img
                            src="../../assets/truck-process.svg"
                            alt="truck process"
                            class="me-3"
                        />
                        <div class="d-flex flex-column">
                            <p class="mb-0">
                                {{ datas.pickup.process }} Jemputan
                            </p>
                            <p class="text-gray mb-0">Dalam Proses</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <img
                            src="../../assets/truck-finish.svg"
                            alt="truck finish"
                            class="me-3"
                        />
                        <div class="d-flex flex-column">
                            <p class="mb-0">
                                {{ datas.pickup.success }} Jemputan
                            </p>
                            <p class="text-gray mb-0">Telah Selesai</p>
                        </div>
                    </div>
                    <donut-chart
                        :data="pickupData"
                        customCenterTextLabel="Total Penjemputan"
                    />
                </div>
            </div>
            <div class="col-lg-8 my-3">
                <div class="box h-100" :class="{ 'box-shadow': hasDateRange }">
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <p class="mb-0 fw-bold" v-if="hasDateRange">
                            Daftar Jemputan Driver
                        </p>
                        <p class="mb-0 fw-bold" v-else>
                            Jemputan Driver Hari Ini
                        </p>
                        <button
                            class="btn btn-green d-flex align-items-center"
                            @click="exportToExcel"
                            :disabled="datas.pickup_todays.length === 0"
                        >
                            <img
                                src="../../assets/download.svg"
                                alt="download"
                                class="me-2"
                            />
                            Download Data
                        </button>
                    </div>
                    <div
                        class="table-responsive-custom table-scrollable mt-4"
                        v-if="datas.pickup_todays.length !== 0"
                    >
                        <table class="table table-bordered">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Nama Driver
                                    </th>
                                    <th class="text-center" scope="col">
                                        Jumlah Penjemputan
                                    </th>
                                    <th class="text-center" scope="col">
                                        Dalam Proses
                                    </th>
                                    <th class="text-center" scope="col">
                                        Selesai
                                    </th>
                                    <th class="text-center" scope="col">
                                        Persentase
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(
                                        listTable, index
                                    ) in datas.pickup_todays"
                                    :key="index"
                                >
                                    <td class="text-center">{{ index + 1 }}</td>
                                    <td class="text-center">
                                        <p class="mb-0">
                                            {{ listTable.name }}
                                        </p>
                                    </td>
                                    <td class="text-center">
                                        {{ listTable.total }}
                                    </td>
                                    <td class="text-center">
                                        {{ listTable.process }}
                                    </td>
                                    <td class="text-center">
                                        {{ listTable.success }}
                                    </td>
                                    <td class="text-center">
                                        {{
                                            calculatePercentage(
                                                listTable.success,
                                                listTable.total
                                            )
                                        }}%
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else>
                        <div class="d-flex justify-content-center mt-5">
                            <img src="../../assets/empty.svg" alt="empty" />
                        </div>
                        <p class="text-gray text-center mt-5">
                            Jemputan driver tidak tersedia
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DonutChart from "../../components/DonutChart.vue";
import moment from "moment";
import Api from "../../api/Api";
import "moment/locale/id";
import * as XLSX from "xlsx";
import DateRangePicker from "../../components/DatePicker/DateRangePicker.vue";

moment.locale("id");

export default {
    components: {
        DonutChart,
        DateRangePicker,
    },
    data() {
        return {
            isPageLoading: false,
            user: null,
            dateRange: {
                from: null,
                to: null,
            },
            date: moment(),
            datas: {
                driver_assignation: null,
                item: {
                    total_item: null,
                    total_weight: null,
                    total_cubic: null,
                },
                pickup: {
                    process: null,
                    success: null,
                },
                pickup_todays: [],
            },
        };
    },
    computed: {
        hasDateRange() {
            return this.dateRange && this.dateRange.from && this.dateRange.to;
        },
        pickupData() {
            return [
                {
                    label: "Dalam Proses Penjemputan",
                    value: this.datas.pickup.process || 0,
                    color: "#FF8600",
                },
                {
                    label: "Telah Selesai Dijemput",
                    value: this.datas.pickup.success || 0,
                    color: "#40BF76",
                },
            ];
        },
    },
    created() {
        this.getDatas();
        const userFromLocalStorage = localStorage.getItem("user");
        if (userFromLocalStorage) {
            this.user = JSON.parse(userFromLocalStorage);
        }
    },
    methods: {
        calculatePercentage(success, total) {
            if (total === 0) return 0;
            return Math.round((success / total) * 100);
        },
        formatDateRange(fromDate, toDate) {
            if (!fromDate || !toDate) return "";

            const from = moment(fromDate);
            const to = moment(toDate);

            return `${from.format("D")} ${from.format(
                "MMMM YYYY"
            )} - ${to.format("D")} ${to.format("MMMM YYYY")}`;
        },
        filterData(dateRange) {
            this.dateRange = dateRange;
            this.getDatas();
        },
        resetData() {
            this.dateRange.from = null;
            this.dateRange.to = null;
            this.getDatas();
        },
        getDatas() {
            this.isPageLoading = true;

            let payload;

            if (this.dateRange && this.dateRange.from && this.dateRange.to) {
                payload = {
                    start_date: moment(this.dateRange.from).format(
                        "YYYY-MM-DD"
                    ),
                    end_date: moment(this.dateRange.to).format("YYYY-MM-DD"),
                };
            } else {
                payload = {
                    start_date: this.date.format("YYYY-MM-DD"),
                    end_date: this.date.format("YYYY-MM-DD"),
                };
            }

            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/dashboard/head_driver`,
                {
                    params: payload,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.datas = res.data.data;
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        exportToExcel() {
            const exportData = this.datas.pickup_todays.map((row, index) => ({
                No: index + 1,
                "Nama Driver": row.name,
                "Jumlah Penjemputan": row.total,
                "Dalam Proses": row.process,
                Selesai: row.success,
                Persentase: `${this.calculatePercentage(
                    row.success,
                    row.total
                )}%`,
            }));

            const worksheet = XLSX.utils.json_to_sheet(exportData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
                workbook,
                worksheet,
                "Jemputan Driver"
            );
            XLSX.writeFile(
                workbook,
                `Jemputan_Driver_${this.date.format("DD-MM-YYYY")}.xlsx`
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.box-shadow {
    box-shadow: 1px 1px 10px 0px rgba(18, 145, 255, 0.3);
}
.table-responsive-custom {
    &.table-scrollable {
        max-height: 410px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th {
            border: 1px solid #c1cdd7;
            background-color: #f3f5f7;
        }

        td {
            border: 1px solid #c1cdd7;
            background-color: #f9fafb;
        }

        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.vertical-divider {
    height: 100%;
    border: 1px solid #c1cdd7;
    margin: 0;
}
@media (max-width: 800px) {
    .d-flex.w-100:not(:last-child) {
        border-bottom: 1px solid #c1cdd7;
        padding-bottom: 1rem;
    }
}
hr {
    height: 100%;
    border: 1px solid #c1cdd7;
}
.text-black {
    color: #212529 !important;
}
.col-md-5.col-md-5-custom {
    width: 36.666667% !important;
}
.col-md-7.col-md-7-custom {
    width: 63.333333% !important;
}
@media only screen and (max-width: 800px) {
    .col-md-5.col-md-5-custom {
        width: 100% !important;
    }
    .col-md-7.col-md-7-custom {
        width: 100% !important;
    }
}
.dashboard-container {
    padding: 48px 20px 40px 20px;
}

.box-orange {
    border: 1px solid #ffcf99;
    background-color: #fff2e5;
    border-radius: 16px;
    padding: 15px 15px;
}
.box-danger {
    background-color: #ff8600;
    border-radius: 16px;
    padding: 15px 15px;
}
</style>
