<template>
    <div class="padding-container">
        <div class="box-manifest d-flex justify-content-center">
            <Indonesia :providedProvinces="vendors" />
        </div>
        <div class="box-manifest mt-3" id="vendor-table">
            <h5 class="fw-bold">Daftar Vendor</h5>
            <div class="row align-items-center mt-4 justify-content-between">
                <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari nama vendor"
                                v-model="search"
                                @keyup.enter="getSearch()"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="getSearch()"
                            >
                                Cari
                            </button>
                        </div>
                        <span class="search-icon left">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div
                    class="col-12 col-lg-8 text-end-lg width-m-100"
                    v-if="isRole(action)"
                >
                    <router-link
                        to="/vendor-management/add"
                        class="btn btn-primary px-3 width-m-100"
                    >
                        <i class="pi pi-plus me-2"></i>
                        Tambah Vendor
                    </router-link>
                </div>
            </div>
            <div class="row align-items-center mt-4">
                <div class="col-12 col-lg-6 text-start-lg">
                    Menampilkan
                    <span class="fw-bold">{{ pageStart }}-{{ pageEnd }}</span>
                    Data dari
                    <span class="fw-bold">{{ lists.total_data }}</span>
                    Data
                </div>
                <div
                    class="col-12 col-lg-6 d-flex justify-content-end-lg align-items-center mt-3 mt-lg-0"
                >
                    <div class="me-2">Baris per halaman</div>
                    <select
                        class="form-control form-select w-auto outline-yellow"
                        v-model="limit"
                        @change="onLimitChange"
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
            </div>
            <div class="mt-4" v-if="isLoader">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nama Vendor
                                </th>
                                <th class="text-center" scope="col">
                                    Lokasi Vendor
                                </th>
                                <th class="text-center" scope="col">
                                    Nama PIC
                                </th>
                                <th class="text-center" scope="col">
                                    Kontak PIC
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="isRole(action)"
                                >
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(vendor, index) in lists.list_data"
                                :key="index"
                            >
                                <td class="text-center align-middle">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-center align-middle">
                                    <div
                                        class="d-flex align-items-center gap-2"
                                        @click="openSidebar(vendor.id)"
                                    >
                                        <div class="text-link cursor-pointer">
                                            {{ vendor.name }}
                                        </div>
                                        <img
                                            v-if="isRole(action)"
                                            src="../assets/pencil-edit.svg"
                                            alt="pencil edit"
                                            class="cursor-pointer"
                                        />
                                    </div>
                                </td>
                                <td class="text-center align-middle">
                                    <div
                                        class="d-flex flex-column align-items-center justify-content-center"
                                    >
                                        <p class="mb-0">
                                            {{ vendor.regency_name }},
                                        </p>
                                        <p class="mb-0">
                                            {{ vendor.province_name }}
                                        </p>
                                    </div>
                                </td>
                                <td class="text-center align-middle">
                                    {{ vendor.pics[0].name }}
                                </td>
                                <td class="text-center align-middle">
                                    <a
                                        :href="'tel:' + vendor.pics[0].phone"
                                        class="text-link"
                                        >{{ vendor.pics[0].phone }}</a
                                    >
                                </td>
                                <td
                                    class="text-center align-middle"
                                    v-if="isRole(action)"
                                >
                                    <div
                                        class="d-flex align-items-center gap-2 justify-content-center"
                                    >
                                        <router-link
                                            :to="
                                                '/vendor-management/detail/' +
                                                vendor.id
                                            "
                                        >
                                            <img
                                                src="../assets/eye-action.svg"
                                                width="20"
                                                class="cursor-pointer"
                                            />
                                        </router-link>
                                        <img
                                            src="../assets/dashboard/trash-icon.png"
                                            width="20"
                                            @click="openModalConfirm(vendor.id)"
                                            class="cursor-pointer"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    class="text-center mt-5"
                    v-if="lists.list_data.length === 0"
                >
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar vendor
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="lists.list_data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="getLists()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Semua data pada vendor ini akan dihapus
                    </h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="deleteData(deletedData)"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Semua data pada vendor ini berhasil dihapus
                    </h5>
                </div>
                <div class="mt-4">
                    <a
                        href="/vendor-management"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirmEdit">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Perubahan data vendor akan disimpan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmEdit = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="storeData()"
                            :disabled="isLoadingEdit"
                        >
                            <span
                                v-if="isLoadingEdit"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccessEdit">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Perubahan data vendor berhasil disimpan
                    </h5>
                </div>
                <div class="mt-4">
                    <a
                        href="/vendor-management"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
        <div
            class="sidebar-overlay"
            v-if="isSidebarOpen"
            @click="closeSidebar()"
        ></div>

        <div
            class="sidebar"
            v-if="isSidebarOpen"
            :class="{ 'sidebar-open': isSidebarOpen }"
        >
            <div class="sidebar-content">
                <div class="p-3" v-if="isLoaderDetail">
                    <div class="d-flex justify-content-center mt-5">
                        <img src="../assets/empty.svg" alt="empty" />
                    </div>
                    <p class="text-gray text-center mt-5">
                        Sedang mengambil data...
                    </p>
                </div>
                <div
                    v-if="!isLoaderDetail"
                    class="d-flex justify-content-between align-items-center p-3 border-bottom sticky-header"
                >
                    <div class="d-flex align-items-center">
                        <router-link
                            :to="'/vendor-management/detail/' + vendorDetail.id"
                        >
                            <img
                                src="../assets/full-preview.svg"
                                alt="full preview"
                                class="cursor-pointer"
                            />
                        </router-link>
                        <h5 class="fw-bold mb-0 ms-3">Preview Vendor</h5>
                    </div>
                    <div class="d-flex align-items-center">
                        <img
                            v-if="isRole(action)"
                            src="../assets/trash-preview.svg"
                            alt="trash preview"
                            class="cursor-pointer"
                            @click="openModalConfirm(vendorDetail.id)"
                        />
                        <div
                            type="button"
                            class="btn-close ms-3"
                            @click="closeSidebar()"
                        ></div>
                    </div>
                </div>
                <div class="p-3 sidebar-body" v-if="!isLoaderDetail">
                    <h5 class="text-primary my-3">
                        {{ vendorDetail.name }}
                    </h5>
                    <div class="d-flex flex-wrap gap-2 align-items-center mb-3">
                        <div
                            class="blue-badge rounded px-3 py-1 text-capitalize"
                            v-for="(
                                vendorType, index
                            ) in vendorDetail.vendor_type"
                            :key="index"
                        >
                            {{ vendorType }}
                        </div>
                    </div>
                    <hr />
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-6 mb-3 d-flex align-items-center">
                            <img
                                class="me-3"
                                src="../assets/call.svg"
                                alt="call"
                            />
                            <a
                                v-if="!editStates.phone"
                                :href="'tel:' + vendorDetail.phone"
                                class="text-link"
                                >{{ vendorDetail.phone }}</a
                            >
                            <input
                                v-else
                                type="number"
                                class="w-100"
                                v-model="formData.phone"
                            />
                            <img
                                v-if="editStates.phone && isRole(action)"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit.svg"
                                alt="edit"
                                @click="toggleEdit('phone')"
                            />
                            <img
                                v-if="!editStates.phone && isRole(action)"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit-pencil-passive.svg"
                                alt="edit pencil passive"
                                @click="toggleEdit('phone')"
                            />
                        </div>
                        <div class="col-lg-6 mb-3 d-flex align-items-center">
                            <img
                                class="me-3"
                                src="../assets/mail.svg"
                                alt="mail"
                            />
                            <a
                                v-if="!editStates.email"
                                :href="'mail:' + vendorDetail.email"
                                class="text-link"
                                >{{ vendorDetail.email }}</a
                            >
                            <input
                                v-else
                                type="email"
                                class="w-100"
                                v-model="formData.email"
                            />
                            <img
                                v-if="editStates.email"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit.svg"
                                alt="edit"
                                @click="toggleEdit('email')"
                            />
                            <img
                                v-if="!editStates.email && isRole(action)"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit-pencil-passive.svg"
                                alt="edit pencil passive"
                                @click="toggleEdit('email')"
                            />
                        </div>
                        <div class="col-lg-6 mb-3 d-flex align-items-center">
                            <img
                                class="me-3"
                                src="../assets/pin.svg"
                                alt="pin"
                            />
                            <div
                                v-if="!editStates.province"
                                class="position-relative"
                            >
                                {{ vendorDetail.province_name }}
                            </div>
                            <multiselect
                                v-else
                                v-model="formData.province"
                                :options="provinces"
                                label="name"
                                track-by="id"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                            <img
                                v-if="editStates.province"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit.svg"
                                alt="edit"
                                @click="toggleEdit('province')"
                            />
                            <img
                                v-if="!editStates.province && isRole(action)"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit-pencil-passive.svg"
                                alt="edit pencil passive"
                                @click="toggleEdit('province')"
                            />
                        </div>
                        <div class="col-lg-6 mb-3 d-flex align-items-center">
                            <img
                                class="me-3"
                                src="../assets/pin.svg"
                                alt="pin"
                            />
                            <div
                                v-if="!editStates.regency"
                                class="position-relative"
                            >
                                {{ vendorDetail.regency_name }}
                            </div>
                            <multiselect
                                v-else
                                v-model="formData.regency"
                                :options="regencies"
                                label="name"
                                track-by="id"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                            <img
                                v-if="editStates.regency"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit.svg"
                                alt="edit"
                                @click="toggleEdit('regency')"
                            />
                            <img
                                v-if="!editStates.regency && isRole(action)"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit-pencil-passive.svg"
                                alt="edit pencil passive"
                                @click="toggleEdit('regency')"
                            />
                        </div>
                        <div class="col-lg-12 mb-3 d-flex align-items-start">
                            <img
                                class="me-3"
                                src="../assets/pin.svg"
                                alt="pin"
                            />
                            <div v-if="!editStates.address">
                                {{ vendorDetail.address }}
                            </div>
                            <textarea
                                v-else
                                class="w-100"
                                v-model="formData.address"
                            ></textarea>
                            <img
                                v-if="editStates.address"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit.svg"
                                alt="edit"
                                @click="toggleEdit('address')"
                            />
                            <img
                                v-if="!editStates.address && isRole(action)"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit-pencil-passive.svg"
                                alt="edit pencil passive"
                                @click="toggleEdit('address')"
                            />
                        </div>
                    </div>
                    <p class="mb-3 text-gray sub-title position-relative">
                        <span>PIC Vendor</span>
                    </p>
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-6 mb-3 d-flex align-items-center">
                            <img
                                class="me-3"
                                src="../assets/person.svg"
                                alt="person"
                            />
                            <div v-if="!editStates.picName">
                                {{ vendorDetail.pics[0].name }}
                            </div>
                            <input
                                v-else
                                type="text"
                                class="w-100"
                                v-model="formData.picName"
                            />
                            <img
                                v-if="editStates.picName"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit.svg"
                                alt="edit"
                                @click="toggleEdit('picName')"
                            />
                            <img
                                v-if="!editStates.picName && isRole(action)"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit-pencil-passive.svg"
                                alt="edit pencil passive"
                                @click="toggleEdit('picName')"
                            />
                        </div>
                        <div class="col-lg-6 mb-3 d-flex align-items-center">
                            <img
                                class="me-3"
                                src="../assets/call.svg"
                                alt="call"
                            />
                            <div v-if="!editStates.picPhone">
                                {{ vendorDetail.pics[0].phone }}
                            </div>
                            <input
                                v-else
                                type="text"
                                class="w-100"
                                v-model="formData.picPhone"
                            />
                            <img
                                v-if="editStates.picPhone"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit.svg"
                                alt="edit"
                                @click="toggleEdit('picPhone')"
                            />
                            <img
                                v-if="!editStates.picPhone && isRole(action)"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit-pencil-passive.svg"
                                alt="edit pencil passive"
                                @click="toggleEdit('picPhone')"
                            />
                        </div>
                    </div>
                    <p class="mb-0 text-gray sub-title position-relative mb-3">
                        <span>Informasi Rekening Vendor</span>
                    </p>
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-6 mb-3 d-flex align-items-center">
                            <img
                                class="me-3"
                                src="../assets/bank.svg"
                                alt="bank"
                            />
                            <div
                                v-if="!editStates.bankInfo"
                                class="position-relative"
                            >
                                {{ vendorDetail.banks[0].bank_name }} |
                                {{ vendorDetail.banks[0].account_number }}
                            </div>
                            <div v-else class="d-flex align-items-center w-100">
                                <multiselect
                                    v-model="formData.bankInfo.bankName"
                                    :options="bankNames"
                                    label="name"
                                    track-by="id"
                                    class="multi-form-custom"
                                    :show-labels="false"
                                >
                                </multiselect>
                                |
                                <input
                                    type="number"
                                    class="w-100"
                                    v-model="formData.bankInfo.accountNumber"
                                />
                            </div>
                            <img
                                v-if="editStates.bankInfo"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit.svg"
                                alt="edit"
                                @click="toggleEdit('bankInfo')"
                            />
                            <img
                                v-if="!editStates.bankInfo && isRole(action)"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit-pencil-passive.svg"
                                alt="edit pencil passive"
                                @click="toggleEdit('bankInfo')"
                            />
                        </div>
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-6 d-flex align-items-center">
                            <img
                                class="me-3"
                                src="../assets/id-card.svg"
                                alt="id card"
                            />
                            <div v-if="!editStates.accountName">
                                {{ vendorDetail.banks[0].account_name }}
                            </div>
                            <input
                                v-else
                                type="text"
                                class="w-100"
                                v-model="formData.accountName"
                            />
                            <img
                                v-if="editStates.accountName"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit.svg"
                                alt="edit"
                                @click="toggleEdit('accountName')"
                            />
                            <img
                                v-if="!editStates.accountName && isRole(action)"
                                class="ms-3 cursor-pointer"
                                src="../assets/edit-pencil-passive.svg"
                                alt="edit pencil passive"
                                @click="toggleEdit('accountName')"
                            />
                        </div>
                    </div>
                    <hr />
                    <h5 class="mb-3 fw-bold">Rute & Keberangkatan</h5>
                    <div v-if="vendorDetail.routes.length === 0">
                        <div class="d-flex justify-content-center mt-5">
                            <img src="../assets/empty-route.svg" alt="empty" />
                        </div>
                        <p class="text-gray text-center mt-5">
                            Belum ada list rute & keberangkatan
                        </p>
                    </div>
                    <div
                        v-else
                        class="mb-4"
                        v-for="(route, index) in vendorDetail.routes"
                        :key="index"
                    >
                        <div
                            class="d-flex justify-content-between header-sidebar px-3 py-2"
                            :class="{
                                'bb-r': !expandedSections.includes(index),
                            }"
                            @click="toggleSection(index)"
                        >
                            <div class="d-flex flex-column">
                                <p class="mb-0">
                                    {{ route.origin_regency_name }} -
                                    {{ route.destination_province_name }}
                                </p>
                                <p class="mb-0">
                                    ({{ route.total_destination }} Kab/Kota)
                                </p>
                            </div>
                            <img
                                src="../assets/chevron-down.svg"
                                alt="chevron down"
                                :class="{
                                    'rotate-180':
                                        expandedSections.includes(index),
                                }"
                            />
                        </div>
                        <div
                            class="body-sidebar px-3 py-2"
                            v-show="expandedSections.includes(index)"
                        >
                            <div class="row">
                                <div
                                    class="col-lg-6 col-6 mb-3 d-flex align-items-center"
                                >
                                    <img
                                        src="../assets/route.svg"
                                        alt="route"
                                    />
                                    <p class="mb-0 ms-2 text-capitalize">
                                        {{
                                            route.service_type.replace(
                                                /_/g,
                                                " "
                                            )
                                        }}
                                    </p>
                                </div>
                                <div
                                    class="col-lg-6 col-6 mb-3 d-flex align-items-center"
                                >
                                    <p class="mb-0 ms-2">
                                        {{ route.vehicle_name }}
                                    </p>
                                </div>
                            </div>
                            <div class="table-responsive-custom">
                                <table class="table table-bordered">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                Kota/Kab
                                            </th>
                                            <th class="text-center" scope="col">
                                                Harga
                                            </th>
                                            <th class="text-center" scope="col">
                                                Lead Time
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                routeList, index
                                            ) in route.routes"
                                            :key="index"
                                        >
                                            <td>
                                                {{
                                                    routeList.destination_regency_name
                                                }}
                                            </td>
                                            <td>
                                                {{ currency(routeList.price) }}
                                            </td>
                                            <td>
                                                {{ routeList.leadtime }} Hari
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="sticky-footer" v-if="isRole(action)">
                        <div class="row w-100">
                            <div class="col-lg-12">
                                <div
                                    class="btn btn-primary w-100"
                                    @click="modalConfirmEdit = true"
                                >
                                    Simpan Perubahan
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";
import Indonesia from "../components/Maps/Indonesia.vue";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        Indonesia,
        Multiselect,
    },
    props: ["roles"],
    data() {
        return {
            vendors: [],
            vendorDetail: null,
            expandedSections: [],
            isSidebarOpen: false,
            modalConfirm: false,
            modalSuccess: false,
            pageStart: 0,
            pageEnd: 0,
            limit: 5,
            totalPage: 0,
            currentPage: 1,
            search: null,
            isLoader: false,
            lists: {
                list_data: [],
            },
            deletedData: null,
            isLoading: false,
            action: ["super-admin", "vendor-management"],
            isLoaderDetail: false,
            provinces: [],
            regencies: [],
            bankNames: [],
            editStates: {
                phone: false,
                email: false,
                regency: false,
                province: false,
                address: false,
                picName: false,
                picPhone: false,
                bankInfo: false,
                accountName: false,
            },
            formData: {
                phone: "",
                email: "",
                regency: "",
                province: "",
                address: "",
                picName: "",
                picPhone: "",
                bankInfo: {
                    bankName: "",
                    accountNumber: "",
                },
                accountName: "",
            },
            modalConfirmEdit: false,
            modalSuccessEdit: false,
            isLoadingEdit: false,
        };
    },
    created() {
        this.getLists();
        this.getVendors();
        this.getProvinces();
        this.getRegencies();
        this.getBanks();
    },
    methods: {
        toggleEdit(field) {
            if (!this.editStates[field]) {
                switch (field) {
                    case "phone":
                        this.formData.phone = this.vendorDetail.phone;
                        break;
                    case "email":
                        this.formData.email = this.vendorDetail.email;
                        break;
                    case "regency":
                        this.formData.regency = {
                            name: this.vendorDetail.regency_name,
                            id: this.vendorDetail.regency_id,
                        };
                        break;
                    case "province":
                        this.formData.province = {
                            name: this.vendorDetail.province_name,
                            id: this.vendorDetail.province_id,
                        };
                        break;
                    case "address":
                        this.formData.address = this.vendorDetail.address;
                        break;
                    case "picName":
                        this.formData.picName = this.vendorDetail.pics[0].name;
                        break;
                    case "picPhone":
                        this.formData.picPhone =
                            this.vendorDetail.pics[0].phone;
                        break;
                    case "bankInfo":
                        this.formData.bankInfo.bankName = {
                            name: this.vendorDetail.banks[0].bank_name,
                            id: this.vendorDetail.banks[0].bank_name,
                        };
                        this.formData.bankInfo.accountNumber =
                            this.vendorDetail.banks[0].account_number;
                        break;
                    case "accountName":
                        this.formData.accountName =
                            this.vendorDetail.banks[0].account_name;
                        break;
                }
            }
            this.editStates[field] = !this.editStates[field];
        },
        storeData() {
            if (this.vendorDetail.id) {
                this.vendorDetail.vendor_id = this.vendorDetail.id;
                delete this.vendorDetail.id;
            }
            if (this.editStates.phone) {
                this.vendorDetail.phone = this.formData.phone;
            }
            if (this.editStates.email) {
                this.vendorDetail.email = this.formData.email;
            }
            if (this.editStates.regency) {
                this.vendorDetail.regency_name = this.formData.regency.name;
                this.vendorDetail.regency_id = this.formData.regency.id;
            }
            if (this.editStates.province) {
                this.vendorDetail.province_name = this.formData.province.name;
                this.vendorDetail.province_id = this.formData.province.id;
            }
            if (this.editStates.address) {
                this.vendorDetail.address = this.formData.address;
            }
            if (this.editStates.picName) {
                this.vendorDetail.pics[0].name = this.formData.picName;
            }
            if (this.editStates.picPhone) {
                this.vendorDetail.pics[0].phone = this.formData.picPhone;
            }
            if (this.editStates.bankInfo) {
                this.vendorDetail.banks[0].bank_name =
                    this.formData.bankInfo.bankName.id;
                this.vendorDetail.banks[0].account_number =
                    this.formData.bankInfo.accountNumber;
            }
            if (this.editStates.accountName) {
                this.vendorDetail.banks[0].account_name =
                    this.formData.accountName;
            }
            Object.keys(this.editStates).forEach((key) => {
                this.editStates[key] = false;
            });
            this.updateVendor(this.vendorDetail);
        },
        updateVendor(vendorDetail) {
            this.isLoadingEdit = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/edit`,
                vendorDetail,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoadingEdit = false;
                    this.modalConfirmEdit = false;
                    this.modalSuccessEdit = true;
                })
                .catch((err) => {
                    this.isLoadingEdit = false;
                    this.modalConfirmEdit = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getBanks() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/bank`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.bankNames = res.data.data.map((bank) => {
                        return { name: bank, id: bank };
                    });
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getRegencies() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.regencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getProvinces() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/province`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.provinces = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        getSearch() {
            this.currentPage = 1;
            this.getLists();
        },
        openModalConfirm(value) {
            this.modalConfirm = true;
            this.deletedData = value;
        },
        toggleSection(index) {
            if (this.expandedSections.includes(index)) {
                this.expandedSections = this.expandedSections.filter(
                    (i) => i !== index
                );
            } else {
                this.expandedSections.push(index);
            }
        },
        openSidebar(vendorId) {
            this.isSidebarOpen = true;
            this.getDetail(vendorId);
            this.expandedSections = [0];
        },
        closeSidebar() {
            this.isSidebarOpen = false;
            this.expandedSections = [];
        },
        deleteData(value) {
            this.isLoading = true;
            var data = {
                vendor_id: value,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/delete`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoading = false;
                    this.modalSuccess = true;
                    this.modalConfirm = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        onLimitChange() {
            this.currentPage = 1;
            this.getLists();
        },
        getLists() {
            this.$nextTick(() => {
                const element = document.getElementById("vendor-table");
                if (element) {
                    element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                    });
                }
            });
            let payload = {
                page: this.currentPage,
                search: this.search ? this.search : null,
                limit: this.limit ? this.limit : null,
                island:
                    this.$route.query.island === "all"
                        ? null
                        : this.$route.query.island,
                province_id: this.$route.query.province_id,
            };
            this.isLoader = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/vendors`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.isLoader = false;
                    this.lists = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    const totalItems = this.lists.list_data.length;
                    const totalData = res.data.data.total_data;
                    this.pageStart =
                        totalItems === 0
                            ? 0
                            : (this.currentPage - 1) * this.limit + 1;
                    this.pageEnd = Math.min(
                        Number(this.pageStart) + Number(this.limit) - 1,
                        totalData
                    );
                })
                .catch((err) => {
                    this.isLoader = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDetail(vendorId) {
            this.isLoaderDetail = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/detail?vendor_id=${vendorId}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.isLoaderDetail = false;
                    this.vendorDetail = res.data.data;
                })
                .catch((err) => {
                    this.isLoaderDetail = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getVendors() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/island_mapping/geo`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.vendors = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
    watch: {
        "$route.query.island": {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.getLists();
                }
            },
            immediate: true,
        },
        "$route.query.province_id": {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.getLists();
                }
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.h-300 {
    height: 200px;
    z-index: 1;
}
.sub-title span {
    padding-right: 20px;
    background-color: #f9fafb;
}
.sub-title:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.7em;
    border-top: 1px solid #dee2e6;
    z-index: -1;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button,
input,
textarea,
select {
    -webkit-appearance: none;
    background: transparent;
    border: none;
    outline: none;
    resize: none;
}

.blue-badge {
    background-color: #daecf6;
    border: 1px solid #daecf6;
    color: #3c87b1;
}
.width-m-100 {
    width: 100%;
    @media (min-width: 992px) {
        width: fit-content;
    }
}
.text-end-lg {
    text-align: end;
}
.text-start-lg {
    text-align: start;
}
.justify-content-end-lg {
    justify-content: center;
}
.text-end-lg {
    text-align: center;
}
.text-start-lg {
    text-align: center;
}
@media only screen and (min-width: 992px) {
    .text-end-lg {
        text-align: end;
    }
    .justify-content-end-lg {
        justify-content: end;
    }
    .text-start-lg {
        text-align: start;
    }
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.bg-transparent-custom {
    background: transparent !important;
}
.rotate-180 {
    transform: rotate(180deg);
}

.transition-transform {
    transition: transform 0.3s ease;
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    cursor: pointer;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
hr {
    color: #dee2e6 !important;
    margin: 1.5rem 0;
}
.input-sidebar {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.bb-r {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1040;
}

@media (max-width: 576px) {
    .sidebar-overlay {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.overflow-y-auto {
    overflow-y: auto !important;
}

.sticky-footer {
    position: fixed;
    bottom: 0;
    background: #f9fafb;
    width: 100%;
    max-width: 500px;
    padding: 1rem;
    border-top: 1px solid #dee2e6;
    z-index: 1;

    @media (max-width: 576px) {
        max-width: 100%;
        padding: 1rem;
    }
}

.sidebar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 500px;
    height: 100vh;
    background-color: #f9fafb;
    z-index: 1050;
    transition: right 0.3s ease-in-out;
    box-shadow: -8px 0 10px -6px rgba(0, 0, 0, 0.1);
    margin-top: 55px;

    @media (max-width: 576px) {
        margin-top: 0;
        width: 100%;
        max-width: 100%;
    }
}

.sidebar-content {
    height: calc(100vh - 55px);
    display: flex;
    flex-direction: column;
    position: relative;
}

.sticky-header {
    position: sticky;
    top: 0;
    background-color: #f9fafb;
    z-index: 1;
}

.sidebar-body {
    overflow-y: auto;
    padding-bottom: 120px !important;

    @media (max-width: 576px) {
        padding-bottom: 150px !important;
    }
}

.sidebar-open {
    right: 0;
}

.sidebar-open {
    right: 0;
}
.modal-vue {
    position: fixed;
    z-index: 9999;
}
.btn-green:disabled {
    background: #e0e6eb !important;
    border: none !important;
    color: #849bae !important;
}
td {
    background-color: transparent !important;
}
th {
    background-color: #f3f5f7 !important;
}
.service {
    background-color: #cce7ff !important;
    border: 1px solid #99d0ff !important;
    color: #005299 !important;
}
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
::v-deep .multiselect__select {
    display: none;
}
::v-deep .multiselect__tags {
    border: none !important;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 !important;
}
::v-deep .multiselect__input {
    border: none !important;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 !important;
}
::v-deep .multiselect__single {
    background-color: transparent;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.outline-yellow:focus {
    background-color: transparent !important;
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
@media only screen and (min-width: 992px) {
    .border-custom {
        border-right: 1px solid #c1cdd7;
        padding-right: 40px;
    }
}
@media only screen and (max-width: 992px) {
    .hr-custom {
        color: #c1cdd7 !important;
        display: block !important;
    }
}

.hr-custom {
    display: none;
}
</style>
