<template>
    <div class="padding-container">
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <h5 class="fw-bold mb-4">Tambah Vendor</h5>
            <div class="row">
                <div class="col-lg-4 mb-4">
                    <label class="mb-2"
                        >Nama Vendor <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama vendor"
                        v-model="vendorName"
                    />
                </div>
                <div class="col-lg-4 mb-4">
                    <label class="fw-bold mb-2"
                        >Nomor Telepon <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nomor telepon"
                        @input="validatePhone()"
                        v-model="phoneNumber"
                    />
                </div>
                <div class="col-lg-4 mb-4">
                    <label class="mb-2"
                        >Email <span class="text-yellow">*</span></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        placeholder="Masukkan email"
                        v-model="email"
                    />
                </div>
                <div class="col-lg-4 mb-4 position-relative">
                    <label class="mb-2"
                        >Jenis Vendor <span class="text-yellow">*</span></label
                    >
                    <div
                        class="form-control"
                        :class="{ 'text-gray': vendorType.length === 0 }"
                        @click="toggleVendorTypeDropdown"
                        ref="vendorTypeSelect"
                    >
                        <span v-if="vendorType.length === 0"
                            >Pilih jenis vendor</span
                        >
                        <span v-else>{{ formatSelectedVendorTypes }}</span>
                    </div>
                    <div
                        class="box position-absolute w-100 mt-2"
                        v-if="showVendorTypeDropdown"
                        ref="vendorTypeDropdown"
                    >
                        <div class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="custom-checkbox me-2"
                                id="trucking"
                                value="trucking"
                                v-model="vendorType"
                            />
                            <label for="trucking">Trucking</label>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="custom-checkbox me-2"
                                id="kargo"
                                value="kargo"
                                v-model="vendorType"
                            />
                            <label for="kargo">Kargo</label>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="custom-checkbox me-2"
                                id="pelayaran"
                                value="pelayaran"
                                v-model="vendorType"
                            />
                            <label for="pelayaran">Pelayaran</label>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="custom-checkbox me-2"
                                id="dooring"
                                value="dooring"
                                v-model="vendorType"
                            />
                            <label for="dooring">Dooring</label>
                        </div>
                        <hr />
                    </div>
                </div>
                <div class="col-lg-4 mb-4">
                    <label class="mb-2"
                        >Provinsi Vendor
                        <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="vendorProvince"
                        :options="vendorProvinces"
                        label="name"
                        track-by="id"
                        placeholder="Cari provinsi vendor"
                        class="multi-form-custom"
                        :show-labels="false"
                        @input="getRegencies()"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-4 mb-4">
                    <label class="mb-2"
                        >Kota Vendor <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="vendorRegency"
                        :options="vendorRegencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota vendor"
                        class="multi-form-custom"
                        :show-labels="false"
                        :disabled="!vendorProvince"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-12 mb-4">
                    <label class="mb-2"
                        >Alamat Vendor <span class="text-yellow">*</span></label
                    >
                    <textarea
                        class="form-control"
                        placeholder="Masukkan alamat vendor"
                        v-model="vendorAddress"
                    ></textarea>
                </div>
            </div>
            <hr />
            <h5 class="fw-bold mb-4">Informasi PIC Vendor</h5>
            <div v-for="(pic, index) in pics" :key="`pic-${index}`" class="row">
                <div class="col-lg-6 mb-4">
                    <label class="mb-2">
                        {{
                            pics.length > 1 && index == 0
                                ? "Nama PIC Utama Vendor"
                                : "Nama PIC Vendor"
                        }}
                        <span class="text-yellow">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama PIC vendor"
                        v-model="pic.name"
                    />
                </div>
                <div class="col-lg-6 mb-4">
                    <div class="row">
                        <div
                            :class="{
                                'col-lg-12': pics.length === 1 || index === 0,
                                'col-lg-10': pics.length > 1,
                            }"
                        >
                            <label class="mb-2">
                                {{
                                    pics.length > 1 && index == 0
                                        ? "Nomor Telepon PIC Utama"
                                        : "Nomor Telepon PIC Vendor"
                                }}
                                <span class="text-yellow">*</span>
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Masukkan nomor telepon PIC"
                                @input="validatePhonePic(index)"
                                v-model="pic.phone"
                            />
                        </div>
                        <div class="col-lg-2" v-if="index > 0">
                            <label class="fw-bold mb-2 invisible"
                                >invisible</label
                            >
                            <div
                                class="btn btn-outline-green"
                                @click="removePic(index)"
                            >
                                <img
                                    src="../assets/trash-multiple.svg"
                                    alt="trash multiple"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 mb-4" v-if="pics.length < 5">
                <div class="btn-outline-green btn w-100" @click="addPic">
                    <i class="fa fa-plus"></i>
                    Tambah PIC
                </div>
            </div>
            <hr />
            <h5 class="fw-bold mb-4">Informasi Nomor Rekening Vendor</h5>
            <div
                v-for="(account, index) in bankAccounts"
                :key="`account-${index}`"
                class="row"
            >
                <div class="col-lg-4 mb-4 position-relative">
                    <label class="mb-2">
                        {{
                            bankAccounts.length > 1 && index == 0
                                ? "Nama Bank Utama"
                                : "Nama Bank"
                        }}
                        <span class="text-yellow">*</span>
                    </label>
                    <multiselect
                        v-model="account.bankName"
                        :options="bankNames"
                        label="name"
                        track-by="name"
                        placeholder="Pilih bank"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-4 mb-4 position-relative">
                    <label class="mb-2">
                        {{
                            bankAccounts.length > 1 && index == 0
                                ? "Nomor Rekening Utama"
                                : "Nomor Rekening"
                        }}
                        <span class="text-yellow">*</span>
                    </label>
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Masukkan nomor rekening"
                        v-model="account.accountNumber"
                    />
                </div>
                <div class="col-lg-4 mb-4 position-relative">
                    <div class="row">
                        <div
                            :class="{
                                'col-lg-12':
                                    bankAccounts.length === 1 || index === 0,
                                'col-lg-9': bankAccounts.length > 1,
                            }"
                        >
                            <label class="mb-2">
                                {{
                                    bankAccounts.length > 1 && index == 0
                                        ? "Nama Pemilik Rekening Utama"
                                        : "Nama Pemilik Rekening"
                                }}
                                <span class="text-yellow">*</span>
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Masukkan nama pemilik rekening"
                                v-model="account.accountName"
                            />
                        </div>
                        <div class="col-lg-3" v-if="index > 0">
                            <label class="fw-bold mb-2 invisible"
                                >invisible</label
                            >
                            <div
                                class="btn btn-outline-green"
                                @click="removeAccount(index)"
                            >
                                <img
                                    src="../assets/trash-multiple.svg"
                                    alt="trash multiple"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 mb-4" v-if="bankAccounts.length < 5">
                <div class="btn-outline-green btn w-100" @click="addAccount">
                    <i class="fa fa-plus"></i>
                    Tambah Nomor Rekening
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button
                    class="btn btn-outline-green px-4 me-3"
                    :disabled="
                        !vendorName ||
                        !phoneNumber ||
                        !email ||
                        vendorType.length === 0 ||
                        !vendorProvince ||
                        !vendorRegency ||
                        !vendorAddress ||
                        !pics.every((pic) => pic.name && pic.phone) ||
                        !bankAccounts.every(
                            (account) =>
                                account.bankName &&
                                account.accountNumber &&
                                account.accountName
                        )
                    "
                    @click="modalConfirm = true"
                >
                    Simpan Data Vendor
                </button>
                <button
                    class="btn btn-primary px-4"
                    :disabled="
                        !vendorName ||
                        !phoneNumber ||
                        !email ||
                        vendorType.length === 0 ||
                        !vendorProvince ||
                        !vendorRegency ||
                        !vendorAddress ||
                        !pics.every((pic) => pic.name && pic.phone) ||
                        !bankAccounts.every(
                            (account) =>
                                account.bankName &&
                                account.accountNumber &&
                                account.accountName
                        ) ||
                        isLoading
                    "
                    @click="storeData('route')"
                >
                    <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm me-2"
                    ></span>
                    Tambahkan Rute Vendor
                </button>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">Data vendor akan disimpan</h5>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="storeData()"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data vendor berhasil dibuat
                    </h5>
                </div>
                <a
                    href="/vendor-management"
                    class="btn btn-primary w-100 btn-lg mt-4"
                >
                    Tutup
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            isLoading: false,
            modalConfirm: false,
            modalSuccess: false,
            vendorName: null,
            phoneNumber: null,
            email: null,
            vendorType: [],
            showVendorTypeDropdown: false,
            vendorProvince: null,
            vendorProvinces: [],
            vendorRegency: null,
            vendorRegencies: [],
            vendorAddress: null,
            bankNames: [],
            pics: [
                {
                    name: null,
                    phone: null,
                },
            ],
            bankAccounts: [
                {
                    bankName: null,
                    accountNumber: null,
                    accountName: null,
                },
            ],
        };
    },
    computed: {
        formatSelectedVendorTypes() {
            if (this.vendorType.length === 0) return "";
            return this.vendorType
                .map((type) => type.charAt(0).toUpperCase() + type.slice(1))
                .join(", ");
        },
    },
    created() {
        this.getBanks();
        this.getProvinces();
    },
    mounted() {
        document.addEventListener("click", this.closeDropdownOnClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.closeDropdownOnClickOutside);
    },
    methods: {
        toggleVendorTypeDropdown() {
            this.showVendorTypeDropdown = !this.showVendorTypeDropdown;
        },
        closeDropdownOnClickOutside(event) {
            if (
                this.showVendorTypeDropdown &&
                this.$refs.vendorTypeSelect &&
                this.$refs.vendorTypeDropdown &&
                !this.$refs.vendorTypeSelect.contains(event.target) &&
                !this.$refs.vendorTypeDropdown.contains(event.target)
            ) {
                this.showVendorTypeDropdown = false;
            }
        },
        getBanks() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/bank`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.bankNames = res.data.data.map((bank) => {
                        return { name: bank };
                    });
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        addPic() {
            if (this.pics.length < 5) {
                this.pics.push({
                    name: null,
                    phone: null,
                });
            }
        },
        removePic(index) {
            this.pics.splice(index, 1);
        },
        addAccount() {
            if (this.bankAccounts.length < 5) {
                this.bankAccounts.push({
                    bankName: null,
                    accountNumber: null,
                    accountName: null,
                });
            }
        },
        removeAccount(index) {
            this.bankAccounts.splice(index, 1);
        },
        validatePhonePic(index) {
            this.pics[index].phone = this.pics[index].phone.replace(
                /[^0-9]/g,
                ""
            );
        },
        validatePhone() {
            this.phoneNumber = this.phoneNumber.replace(/[^0-9]/g, "");
        },
        storeData(vendorId) {
            this.isLoading = true;
            var data = {
                name: this.vendorName,
                phone: this.phoneNumber,
                email: this.email,
                vendor_type: this.vendorType,
                province_id: this.vendorProvince?.id,
                regency_id: this.vendorRegency?.id,
                address: this.vendorAddress,
                pics: this.pics,
                banks: this.bankAccounts.map((account) => ({
                    bank_name: account?.bankName?.name,
                    account_number: account?.accountNumber,
                    account_name: account?.accountName,
                })),
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/create`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.isLoading = false;
                    if (vendorId) {
                        window.location.href = `/route/add?vendor=${res.data.data.vendor.id}`;
                    } else {
                        this.modalSuccess = true;
                        this.modalConfirm = false;
                    }
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getRegencies() {
            this.vendorRegency = null;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/regency?province_id=${this.vendorProvince.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.vendorRegencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getProvinces() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/province`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.vendorProvinces = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}

.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}

.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
hr {
    color: #c1cdd7;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
</style>
