<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <h5 class="fw-bold mb-4">Informasi Vendor</h5>
            <div class="row">
                <div class="col-lg-12 mb-4">
                    <label class="mb-2"
                        >Nama Vendor <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="name"
                        :options="names"
                        label="name"
                        track-by="id"
                        placeholder="Cari nama vendor"
                        class="multi-form-custom magnifier"
                        :show-labels="false"
                        @input="getDetail(name.id)"
                        :disabled="vendorFromUrl"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mb-4" v-if="name">
                    <label class="mb-2"
                        >Nomor Telepon Vendor
                        <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="detail.phone"
                        disabled
                    />
                </div>
                <div class="col-lg-6 mb-4" v-if="name">
                    <label class="mb-2"
                        >Email <span class="text-yellow">*</span></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        v-model="detail.email"
                        disabled
                    />
                </div>
                <div class="col-lg-6 mb-4" v-if="name">
                    <label class="mb-2"
                        >Provinsi Vendor
                        <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="detail.province_name"
                        disabled
                    />
                </div>
                <div class="col-lg-6 mb-4" v-if="name">
                    <label class="mb-2"
                        >Kota/Kabupaten Vendor
                        <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="detail.regency_name"
                        disabled
                    />
                </div>
                <div class="col-lg-12 mb-4" v-if="name">
                    <label class="mb-2"
                        >Alamat Vendor <span class="text-yellow">*</span></label
                    >
                    <textarea
                        class="form-control"
                        v-model="detail.address"
                        disabled
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-4" v-if="name">
            <h5 class="fw-bold mb-4">Rute</h5>
            <div class="mb-4" v-for="(route, index) in routes" :key="index">
                <div
                    class="d-flex align-items-center justify-content-between header-sidebar px-3 py-2"
                    :class="{ 'bb-r': !route.isVisible }"
                    @click="toggleRouteVisibility(index)"
                >
                    <h5 class="mb-0 fw-bold">Informasi Rute {{ index + 1 }}</h5>
                    <div class="d-flex align-items-center">
                        <button
                            class="btn outline-green me-3 d-flex align-items-center me-3"
                            @click="deleteRoute(index)"
                            v-if="index > 0"
                        >
                            <img
                                src="../assets/delete.svg"
                                alt="trash"
                                class="me-2"
                            />
                            Hapus Bagian
                        </button>
                        <img
                            src="../assets/chevron-down.svg"
                            alt="chevron down"
                            :class="{ 'rotate-180': !route.isVisible }"
                        />
                    </div>
                </div>
                <div class="body-sidebar px-3 py-2" v-if="route.isVisible">
                    <div class="row">
                        <div class="col-lg-6 my-3">
                            <label class="fw-bold mb-2"
                                >Jenis Layanan
                                <span class="text-yellow">*</span></label
                            >
                            <multiselect
                                v-model="route.serviceType"
                                :options="serviceTypes"
                                label="name"
                                track-by="id"
                                placeholder="Pilih jenis layanan"
                                class="multi-form-custom"
                                :show-labels="false"
                                @input="resetVehicleInfo(route)"
                            >
                            </multiselect>
                        </div>
                        <div
                            class="col-lg-6 my-3"
                            v-if="
                                route.serviceType &&
                                route.serviceType.id === 'darat'
                            "
                        >
                            <label class="fw-bold mb-2"
                                >Jenis Armada
                                <span class="text-yellow">*</span></label
                            >
                            <multiselect
                                v-model="route.fleet"
                                :options="fleets"
                                label="name"
                                track-by="code"
                                placeholder="Pilih jenis armada"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div
                            class="col-lg-6 my-3"
                            v-if="
                                route.serviceType &&
                                route.serviceType.id === 'laut'
                            "
                        >
                            <label class="fw-bold mb-2"
                                >Nama Kapal
                                <span class="text-yellow">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Masukkan nama kapal"
                                v-model="route.shipName"
                            />
                        </div>
                        <div
                            class="col-lg-6 my-3"
                            v-if="
                                route.serviceType &&
                                route.serviceType.id === 'udara'
                            "
                        >
                            <label class="fw-bold mb-2"
                                >Nama Pesawat
                                <span class="text-yellow">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Masukkan nama pesawat"
                                v-model="route.aircraftName"
                            />
                        </div>
                        <div class="col-lg-6 my-3">
                            <label class="fw-bold mb-2"
                                >Kota Asal
                                <span class="text-yellow">*</span></label
                            >
                            <multiselect
                                v-model="route.regencyRoute"
                                :options="regencies"
                                label="name"
                                track-by="id"
                                placeholder="Pilih kota asal"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-lg-6 my-3">
                            <label class="fw-bold mb-2"
                                >Rute Provinsi Tujuan
                                <span class="text-yellow">*</span></label
                            >
                            <multiselect
                                v-model="route.provinceRoute"
                                :options="provinces"
                                label="name"
                                track-by="id"
                                placeholder="Pilih rute provinsi tujuan"
                                class="multi-form-custom"
                                :show-labels="false"
                                @input="loadRegenciesList(route)"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <div
                        class="box"
                        v-if="route.regencyRoute && route.provinceRoute"
                    >
                        <h5 class="fw-bold mb-4">Samakan Data</h5>
                        <div class="row">
                            <div class="col-lg-3">
                                <label class="fw-bold mb-2">Harga</label>
                                <div class="input-group mb-3">
                                    <span class="input-group-text">Rp</span>
                                    <input
                                        type="text"
                                        class="form-control input-sidebar"
                                        placeholder="Masukkan harga"
                                        v-model="route.commonPrice"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <label class="fw-bold mb-2">Lead Time</label>
                                <div class="input-group mb-3">
                                    <input
                                        type="text"
                                        class="form-control input-sidebar-2"
                                        placeholder="Masukkan waktu"
                                        v-model="route.commonLeadTime"
                                    />
                                    <span class="input-group-text">Hari</span>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <label class="fw-bold mb-2"
                                    >Minimal Order</label
                                >
                                <div class="input-group mb-3">
                                    <input
                                        type="text"
                                        class="form-control input-sidebar-2"
                                        placeholder="Masukkan angka"
                                        v-model="route.commonMinOrder"
                                    />
                                    <span class="input-group-text">{{
                                        route.minOrderUnit
                                    }}</span>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <label class="fw-bold mb-2 invisible"
                                            >Minimal Order</label
                                        >
                                        <div
                                            class="btn btn-primary w-100"
                                            @click="applyCommonData(route)"
                                        >
                                            Terapkan
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="fw-bold mb-2 invisible"
                                            >Mini</label
                                        >
                                        <button
                                            class="btn btn-outline-yellow"
                                            @click="resetCommonData(route)"
                                        >
                                            <img
                                                src="../assets/refresh.svg"
                                                alt="refresh"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="table-responsive-custom"
                        v-if="
                            route.regencyRoute &&
                            route.provinceRoute &&
                            route.regencyLists.length > 0
                        "
                    >
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">
                                        <input
                                            type="checkbox"
                                            class="custom-checkbox"
                                            v-model="route.selectAll"
                                            @change="toggleSelectAll(route)"
                                        />
                                    </th>
                                    <th class="text-center" scope="col">
                                        Kota/Kab
                                    </th>
                                    <th scope="col">Harga</th>
                                    <th scope="col">Lead Time</th>
                                    <th
                                        scope="col"
                                        class="cursor-pointer"
                                        @click="
                                            route.changeMinOrder =
                                                !route.changeMinOrder
                                        "
                                    >
                                        <div
                                            class="d-flex justify-content-between align-items-center"
                                        >
                                            Minimal Order
                                            <div class="position-relative">
                                                <i
                                                    class="fa fa-chevron-down"
                                                ></i>
                                                <div
                                                    class="box p-3 rounded position-absolute left-35"
                                                    v-if="route.changeMinOrder"
                                                >
                                                    <p
                                                        class="mb-0"
                                                        @click="
                                                            changeMinOrderUnit(
                                                                route,
                                                                'Kg'
                                                            )
                                                        "
                                                    >
                                                        Kg
                                                    </p>
                                                    <hr />
                                                    <p
                                                        class="mb-0"
                                                        @click="
                                                            changeMinOrderUnit(
                                                                route,
                                                                'm³'
                                                            )
                                                        "
                                                    >
                                                        m³
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(
                                        city, cityIndex
                                    ) in route.regencyLists"
                                    :key="cityIndex"
                                >
                                    <td class="text-center">
                                        <input
                                            type="checkbox"
                                            class="custom-checkbox"
                                            v-model="city.selected"
                                        />
                                    </td>
                                    <td>{{ city.name }}</td>
                                    <td>
                                        <div
                                            class="d-flex align-items-center gap-2"
                                        >
                                            <p class="mb-0">
                                                Rp
                                                <span class="text-gray ms-2"
                                                    >|</span
                                                >
                                            </p>
                                            <input
                                                type="text"
                                                placeholder="Masukkan Harga"
                                                class="input-transparent w-100"
                                                v-model="city.price"
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                            class="d-flex align-items-center gap-2"
                                        >
                                            <input
                                                type="text"
                                                placeholder="Masukkan waktu"
                                                class="input-transparent"
                                                v-model="city.leadTime"
                                            />
                                            <p class="mb-0 w-100 text-end">
                                                <span class="text-gray me-2"
                                                    >|</span
                                                >Hari
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                            class="d-flex align-items-center gap-2"
                                        >
                                            <input
                                                type="text"
                                                placeholder="Minimal Order"
                                                class="input-transparent"
                                                v-model="city.minOrder"
                                            />
                                            <p class="mb-0 w-100 text-end">
                                                <span class="text-gray me-2"
                                                    >|</span
                                                >{{ route.minOrderUnit }}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <button class="w-100 outline-add btn" @click="addRoute">
                <i class="pi pi-plus me-2"></i>Tambah Rute
            </button>
            <div class="d-flex justify-content-end mt-4">
                <button
                    class="btn btn-primary px-5"
                    @click="openConfirmModal"
                    :disabled="!isFormValid"
                >
                    Simpan
                </button>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Informasi rute vendor ini akan disimpan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isLoadingSave"
                            @click="saveRoutes"
                        >
                            <span
                                v-if="isLoadingSave"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Informasi rute vendor berhasil dibuat
                    </h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/route"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            isPageLoading: false,
            name: null,
            names: [],
            detail: {},
            fleets: [],
            regencies: [],
            provinces: [],
            routes: [
                {
                    isVisible: true,
                    serviceType: null,
                    fleet: null,
                    shipName: "",
                    aircraftName: "",
                    regencyRoute: null,
                    provinceRoute: null,
                    changeMinOrder: false,
                    regencyLists: [],
                    commonPrice: "",
                    commonLeadTime: "",
                    commonMinOrder: "",
                    minOrderUnit: "Kg",
                    selectAll: false,
                },
            ],
            modalConfirm: false,
            modalSuccess: false,
            isLoadingSave: false,
            vendorFromUrl: false,
            serviceTypes: [
                { name: "Darat", id: "darat" },
                { name: "Laut", id: "laut" },
                { name: "Udara", id: "udara" },
            ],
        };
    },
    computed: {
        isFormValid() {
            for (const route of this.routes) {
                if (
                    !route.serviceType ||
                    !route.regencyRoute ||
                    !route.provinceRoute
                ) {
                    return false;
                }

                if (route.serviceType.id === "darat" && !route.fleet) {
                    return false;
                }

                if (route.serviceType.id === "laut" && !route.shipName) {
                    return false;
                }

                if (route.serviceType.id === "udara" && !route.aircraftName) {
                    return false;
                }

                let hasValidSelection = false;
                if (route.regencyLists && route.regencyLists.length > 0) {
                    for (const city of route.regencyLists) {
                        if (
                            city.selected &&
                            city.price &&
                            city.leadTime &&
                            city.minOrder
                        ) {
                            hasValidSelection = true;
                            break;
                        }
                    }
                }

                if (!hasValidSelection) {
                    return false;
                }
            }
            return true;
        },
    },
    created() {
        this.getVendors();
        this.getFleets();
        this.getRegencies();
        this.getProvinces();
        const urlParams = new URLSearchParams(window.location.search);
        const vendorId = urlParams.get("vendor");
        if (vendorId) {
            this.vendorFromUrl = true;
            this.getVendorById(vendorId);
        }
    },
    methods: {
        resetVehicleInfo(route) {
            route.fleet = null;
            route.shipName = "";
            route.aircraftName = "";
        },
        getVendorById(vendorId) {
            this.isPageLoading = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/list_vendor`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    const vendorList = res.data.data;
                    this.names = vendorList;

                    const vendor = vendorList.find(
                        (v) => v.id === parseInt(vendorId) || v.id === vendorId
                    );
                    if (vendor) {
                        this.name = vendor;
                        this.getDetail(vendor.id);
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Vendor dengan ID tersebut tidak ditemukan",
                        });
                    }
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response
                            ? err.response.data.message
                            : "Failed to load vendor data",
                    });
                });
        },

        openConfirmModal() {
            this.modalConfirm = true;
        },
        addRoute() {
            this.routes.push({
                isVisible: true,
                serviceType: null,
                fleet: null,
                shipName: "",
                aircraftName: "",
                regencyRoute: null,
                provinceRoute: null,
                changeMinOrder: false,
                regencyLists: [],
                commonPrice: "",
                commonLeadTime: "",
                commonMinOrder: "",
                minOrderUnit: "Kg",
                selectAll: false,
            });
        },
        deleteRoute(index) {
            if (this.routes.length > 1) {
                this.routes.splice(index, 1);
            } else {
                this.$notify({
                    group: "foo",
                    type: "warning",
                    title: "Peringatan",
                    text: "Minimal harus ada satu rute",
                });
            }
        },
        toggleRouteVisibility(index) {
            this.routes[index].isVisible = !this.routes[index].isVisible;
        },
        loadRegenciesList(route) {
            if (route.provinceRoute && route.provinceRoute.id) {
                this.isPageLoading = true;
                Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/master/geo/regency?province_id=${route.provinceRoute.id}`,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        route.regencyLists = res.data.data.map((item) => {
                            return {
                                ...item,
                                selected: false,
                                price: "",
                                leadTime: "",
                                minOrder: "",
                            };
                        });
                        this.isPageLoading = false;
                    })
                    .catch((err) => {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response
                                ? err.response.data.message
                                : "Failed to load regencies",
                        });
                        this.isPageLoading = false;
                    });
            }
        },
        applyCommonData(route) {
            if (!route.regencyLists || route.regencyLists.length === 0) {
                return;
            }

            route.regencyLists.forEach((city) => {
                city.price = route.commonPrice;
                city.leadTime = route.commonLeadTime;
                city.minOrder = route.commonMinOrder;
            });
        },
        resetCommonData(route) {
            route.commonPrice = "";
            route.commonLeadTime = "";
            route.commonMinOrder = "";
        },
        toggleSelectAll(route) {
            route.regencyLists.forEach((city) => {
                city.selected = route.selectAll;
            });
        },
        changeMinOrderUnit(route, unit) {
            route.minOrderUnit = unit;
            route.changeMinOrder = false;
        },
        saveRoutes() {
            this.isLoadingSave = true;
            let routesData = [];

            this.routes.forEach((route) => {
                if (
                    route.serviceType &&
                    route.regencyRoute &&
                    route.provinceRoute &&
                    (route.serviceType.id !== "darat" || route.fleet) &&
                    (route.serviceType.id !== "laut" || route.shipName) &&
                    (route.serviceType.id !== "udara" || route.aircraftName)
                ) {
                    let vehicle = "";
                    if (route.serviceType.id === "darat") {
                        vehicle = route.fleet.code;
                    } else if (route.serviceType.id === "laut") {
                        vehicle = route.shipName;
                    } else if (route.serviceType.id === "udara") {
                        vehicle = route.aircraftName;
                    }

                    route.regencyLists.forEach((city) => {
                        if (
                            city.selected &&
                            city.price &&
                            city.leadTime &&
                            city.minOrder
                        ) {
                            routesData.push({
                                vendor_id: this.name.id,
                                service_type: route.serviceType.id,
                                vehicle: vehicle,
                                origin_regency_id: route.regencyRoute.id,
                                destination_regency_id: city.id,
                                destination_province_id: route.provinceRoute.id,
                                price: parseInt(city.price),
                                leadtime: parseInt(city.leadTime),
                                order_minimum: parseInt(city.minOrder),
                                order_minimum_type:
                                    route.minOrderUnit === "Kg"
                                        ? "kilogram"
                                        : "metercubic",
                            });
                        }
                    });
                }
            });

            if (routesData.length === 0) {
                return;
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/create`,
                { routes: routesData },
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoadingSave = false;
                    this.modalConfirm = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response
                            ? err.response.data.message
                            : "Failed to save routes",
                    });
                    this.isLoadingSave = false;
                    this.modalConfirm = false;
                });
        },
        getRegencies() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.regencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getProvinces() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/province`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.provinces = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getFleets() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/fleet_type`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.fleets = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getVendors() {
            this.isPageLoading = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/list_vendor`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.names = res.data.data;
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDetail(vendorId) {
            this.isPageLoading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/vendors/detail`, {
                params: {
                    vendor_id: vendorId,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.detail = res.data.data;
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .multiselect--disabled .multiselect__tags,
::v-deep .multiselect--disabled .multiselect__tags .multiselect__single {
    background: #f3f5f7 !important;
    color: #495057 !important;
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.left-35 {
    left: -35px;
}
.outline-add {
    background-color: transparent !important;
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
}
.input-transparent {
    background: transparent;
    border: none;
    outline: none;
    resize: none;
}
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}
.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}
.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
.input-sidebar {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.input-sidebar-2 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    cursor: pointer;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.bb-r {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.rotate-180 {
    transform: rotate(180deg);
}
.outline-green {
    border: 1px solid #ff8600 !important;
    color: #ff8600 !important;
    background-color: transparent !important;
    border-radius: 8px;
}
</style>
