<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <h5 class="fw-bold mb-4">Tambah Route Management & Lead Time</h5>
            <div class="d-flex align-items-start">
                <img
                    src="../assets/location-dash.svg"
                    alt="location-dash"
                    class="me-3 d-none-sm"
                />
                <div class="row w-100">
                    <div class="col-lg-6 mb-4">
                        <label class="mb-2"
                            >Provinsi Asal
                            <span class="text-yellow">*</span></label
                        >
                        <multiselect
                            v-model="originProvince"
                            :options="provinces"
                            label="name"
                            track-by="id"
                            placeholder="Cari & pilih provinsi asal"
                            class="multi-form-custom magnifier"
                            :show-labels="false"
                            @input="getOriginRegencies()"
                        >
                            <template v-slot:caret>
                                <img
                                    :src="
                                        originProvince
                                            ? magnifierActiveIcon
                                            : magnifierIcon
                                    "
                                    alt="icon"
                                    style="
                                        width: 20px;
                                        height: 20px;
                                        position: absolute;
                                        left: 12px;
                                        top: 42%;
                                        transform: translateY(-50%);
                                    "
                                />
                            </template>
                        </multiselect>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-2"
                            >Kota/Kab. Asal
                            <span class="text-yellow">*</span></label
                        >
                        <multiselect
                            v-model="originRegency"
                            :options="originRegencies"
                            label="name"
                            track-by="id"
                            placeholder="Pilih kota/kab. asal"
                            class="multi-form-custom"
                            :show-labels="false"
                            :disabled="!originProvince"
                        >
                        </multiselect>
                    </div>
                </div>
            </div>
            <div
                v-for="(transit, index) in transitPoints"
                :key="index"
                class="row"
            >
                <div class="d-flex align-items-start">
                    <img
                        src="../assets/to-dash.svg"
                        alt="to dash"
                        class="me-3 d-none-sm"
                    />
                    <div class="row w-100">
                        <div class="col-lg-6 mb-4">
                            <label class="mb-2">
                                Kota/Kabupaten transit {{ index + 1 }}
                            </label>
                            <multiselect
                                v-model="transit.value"
                                :options="regencies"
                                label="name"
                                track-by="id"
                                placeholder="Cari & pilih kota transit"
                                class="multi-form-custom magnifier"
                                :show-labels="false"
                            >
                                <template v-slot:caret>
                                    <img
                                        :src="
                                            transit.value
                                                ? magnifierActiveIcon
                                                : magnifierIcon
                                        "
                                        alt="icon"
                                        style="
                                            width: 20px;
                                            height: 20px;
                                            position: absolute;
                                            left: 12px;
                                            top: 42%;
                                            transform: translateY(-50%);
                                        "
                                    />
                                </template>
                            </multiselect>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <label class="mb-2 invisible-md">
                                Kota {{ index + 1 }}
                                <span class="text-yellow">*</span>
                            </label>
                            <button
                                class="btn outline-green d-flex align-items-center"
                                @click="removeTransitPoint(index)"
                            >
                                <img
                                    src="../assets/trash-button.svg"
                                    alt="trash"
                                    class="me-2"
                                />
                                Hapus Bagian
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="d-flex align-items-start"
                v-if="transitPoints.length < 3"
            >
                <img
                    src="../assets/to-dash.svg"
                    alt="to dash"
                    class="me-3 d-none-sm"
                />
                <div class="mb-4">
                    <label class="mb-2">Kota/Kabupaten transit</label>
                    <button
                        class="btn outline-yellow d-block px-4"
                        @click="addTransitPoint"
                    >
                        <i class="pi pi-plus me-2"></i>
                        Tambah Titik Transit
                    </button>
                </div>
            </div>
            <div class="d-flex align-items-start">
                <img
                    src="../assets/location.svg"
                    alt="location"
                    class="me-3 d-none-sm"
                />
                <div class="row w-100">
                    <div class="col-lg-6 mb-4">
                        <label class="mb-2"
                            >Provinsi Tujuan
                            <span class="text-yellow">*</span></label
                        >
                        <multiselect
                            v-model="destinationProvince"
                            :options="provinces"
                            label="name"
                            track-by="id"
                            placeholder="Cari & pilih provinsi tujuan"
                            class="multi-form-custom magnifier"
                            :show-labels="false"
                            @input="getDestinationRegencies()"
                            :disabled="!originRegency"
                        >
                            <template v-slot:caret>
                                <img
                                    :src="
                                        destinationProvince
                                            ? magnifierActiveIcon
                                            : magnifierIcon
                                    "
                                    alt="icon"
                                    style="
                                        width: 20px;
                                        height: 20px;
                                        position: absolute;
                                        left: 12px;
                                        top: 42%;
                                        transform: translateY(-50%);
                                    "
                                />
                            </template>
                        </multiselect>
                    </div>
                    <div class="col-lg-6 mb-4" v-if="!isMulti">
                        <label class="mb-2"
                            >Kota/Kab. Tujuan
                            <span class="text-yellow">*</span></label
                        >
                        <multiselect
                            v-model="destinationRegency"
                            :options="destinationRegencies"
                            label="name"
                            track-by="id"
                            placeholder="Pilih kota/kab. tujuan"
                            class="multi-form-custom"
                            :show-labels="false"
                            :disabled="!destinationProvince"
                            @input="
                                services = [
                                    {
                                        serviceType: null,
                                        price: '',
                                        leadTime: '',
                                    },
                                ]
                            "
                        >
                        </multiselect>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-start">
                <img
                    src="../assets/location.svg"
                    alt="location"
                    class="me-3 d-none-sm invisible"
                />
                <label class="box w-100">
                    <div class="d-flex align-items-center gap-3">
                        <input
                            type="checkbox"
                            class="custom-checkbox"
                            v-model="isMulti"
                        />
                        <div class="d-flex flex-column">
                            <p class="mb-0 fw-bold">
                                Rute Multi Kota dalam Satu Provinsi
                            </p>
                            <p class="mb-0">
                                Anda dapat menerapkan tujuan rute ke beberapa
                                kota sekaligus dalam satu langkah.
                            </p>
                        </div>
                    </div>
                </label>
            </div>
        </div>
        <div class="row">
            <div
                class="col-lg-12"
                v-if="
                    !originRegency ||
                    !destinationProvince ||
                    (!isMulti && !destinationRegency) ||
                    (transitPoints.length > 0 &&
                        transitPoints.some((point) => !point.value))
                "
            >
                <div class="box-manifest mt-4">
                    <h5 class="fw-bold">Lead Time & Price</h5>
                    <div class="d-flex justify-content-center mt-5">
                        <img src="../assets/empty.svg" alt="empty" />
                    </div>
                    <p class="text-gray text-center mt-5">
                        Isi Kota/Kab. asal dan tujuan terlebih dahulu
                    </p>
                    <button class="btn btn-primary w-100" disabled>
                        Simpan
                    </button>
                </div>
            </div>
            <div class="col-lg-12" v-else>
                <div class="box-manifest mt-4">
                    <h5 class="fw-bold mb-4">Lead Time & Price</h5>

                    <div v-if="isMulti">
                        <div
                            v-for="(service, serviceIndex) in services"
                            :key="serviceIndex"
                            class="mb-4"
                        >
                            <div
                                class="d-flex align-items-center justify-content-between header-sidebar px-3 py-2 bb-r"
                            >
                                <h5 class="mb-0 fw-bold">
                                    Informasi Rute {{ serviceIndex + 1 }}
                                </h5>
                                <div class="d-flex align-items-center">
                                    <button
                                        class="btn outline-green me-3 d-flex align-items-center me-3"
                                        @click="
                                            services.splice(serviceIndex, 1)
                                        "
                                        v-if="serviceIndex > 0"
                                    >
                                        <img
                                            src="../assets/delete.svg"
                                            alt="trash"
                                            class="me-2"
                                        />
                                        Hapus Bagian
                                    </button>
                                    <img
                                        src="../assets/chevron-down.svg"
                                        alt="chevron down"
                                        class="rotate-180"
                                    />
                                </div>
                            </div>
                            <div class="body-sidebar px-3 py-2">
                                <div class="row">
                                    <div class="col-lg-12 my-3">
                                        <label class="fw-bold mb-2">
                                            Jenis Layanan
                                            <span class="text-yellow">*</span>
                                        </label>
                                        <multiselect
                                            v-model="service.serviceType"
                                            :options="serviceTypes"
                                            label="name"
                                            track-by="id"
                                            placeholder="Pilih jenis layanan"
                                            class="multi-form-custom"
                                            :show-labels="false"
                                        >
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="box">
                                    <h5 class="fw-bold mb-4">Samakan Data</h5>
                                    <div class="row">
                                        <div class="col-lg-3">
                                            <label class="fw-bold mb-2"
                                                >Harga</label
                                            >
                                            <div class="input-group mb-3">
                                                <span class="input-group-text"
                                                    >Rp</span
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control input-sidebar"
                                                    placeholder="Masukkan harga"
                                                    v-model="
                                                        service.uniformPrice
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <label class="fw-bold mb-2"
                                                >Lead Time</label
                                            >
                                            <div class="input-group mb-3">
                                                <input
                                                    type="text"
                                                    class="form-control input-sidebar-2"
                                                    placeholder="Masukkan waktu"
                                                    v-model="
                                                        service.uniformLeadTime
                                                    "
                                                />
                                                <span class="input-group-text"
                                                    >Hari</span
                                                >
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <label class="fw-bold mb-2"
                                                >Minimal Order</label
                                            >
                                            <div class="input-group mb-3">
                                                <input
                                                    type="text"
                                                    class="form-control input-sidebar-2"
                                                    placeholder="Masukkan angka"
                                                    v-model="
                                                        service.uniformMinOrder
                                                    "
                                                />
                                                <span
                                                    class="input-group-text"
                                                    >{{
                                                        services[serviceIndex]
                                                            .minOrderType
                                                            ? services[
                                                                  serviceIndex
                                                              ].minOrderType ===
                                                              "kilogram"
                                                                ? "Kg"
                                                                : "m³"
                                                            : "Kg"
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="row">
                                                <div class="col-lg-8">
                                                    <label
                                                        class="fw-bold mb-2 invisible"
                                                        >Minimal Order</label
                                                    >
                                                    <div
                                                        class="btn btn-primary w-100"
                                                        @click="
                                                            applyUniformData(
                                                                serviceIndex
                                                            )
                                                        "
                                                    >
                                                        Terapkan
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <label
                                                        class="fw-bold mb-2 invisible"
                                                        >Mini</label
                                                    >
                                                    <button
                                                        class="btn btn-outline-yellow"
                                                        @click="
                                                            resetUniformData(
                                                                serviceIndex
                                                            )
                                                        "
                                                    >
                                                        <img
                                                            src="../assets/refresh.svg"
                                                            alt="refresh"
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive-custom">
                                    <table class="table table-bordered mt-4">
                                        <thead class="table-light">
                                            <tr>
                                                <th
                                                    class="text-center"
                                                    scope="col"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        class="custom-checkbox"
                                                        @change="
                                                            toggleSelectAllCities(
                                                                serviceIndex
                                                            )
                                                        "
                                                    />
                                                </th>
                                                <th
                                                    class="text-center"
                                                    scope="col"
                                                >
                                                    Kota/Kab
                                                </th>
                                                <th scope="col">Harga</th>
                                                <th scope="col">Lead Time</th>
                                                <th
                                                    scope="col"
                                                    class="cursor-pointer"
                                                >
                                                    <div
                                                        class="d-flex justify-content-between align-items-center cursor-pointer"
                                                        @click="
                                                            toggleMinOrderTypeDropdown(
                                                                serviceIndex
                                                            )
                                                        "
                                                    >
                                                        Minimal Order
                                                        <div
                                                            class="position-relative"
                                                        >
                                                            <i
                                                                class="fa fa-chevron-down"
                                                            ></i>
                                                            <div
                                                                v-if="
                                                                    showMinOrderTypeDropdown ===
                                                                    serviceIndex
                                                                "
                                                                class="box p-3 rounded position-absolute left-35 z-10 bg-white shadow"
                                                            >
                                                                <p
                                                                    v-for="type in minOrderTypes"
                                                                    :key="
                                                                        type.value
                                                                    "
                                                                    class="mb-2 cursor-pointer hover:bg-gray-100 p-1"
                                                                    @click.stop="
                                                                        selectMinOrderType(
                                                                            serviceIndex,
                                                                            type.value
                                                                        )
                                                                    "
                                                                >
                                                                    {{
                                                                        type.name
                                                                    }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(
                                                    city, cityIndex
                                                ) in service.cities"
                                                :key="cityIndex"
                                            >
                                                <td class="text-center">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-checkbox"
                                                        v-model="
                                                            city.isSelected
                                                        "
                                                    />
                                                </td>
                                                <td>{{ city.city.name }}</td>
                                                <td>
                                                    <div
                                                        class="d-flex align-items-center gap-2"
                                                    >
                                                        <p class="mb-0">
                                                            Rp<span
                                                                class="text-gray ms-2"
                                                                >|</span
                                                            >
                                                        </p>
                                                        <input
                                                            type="text"
                                                            placeholder="Masukkan Harga"
                                                            class="input-transparent w-100"
                                                            v-model="city.price"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        class="d-flex align-items-center gap-2"
                                                    >
                                                        <input
                                                            type="text"
                                                            placeholder="Masukkan waktu"
                                                            class="input-transparent"
                                                            v-model="
                                                                city.leadTime
                                                            "
                                                        />
                                                        <p
                                                            class="mb-0 w-100 text-end"
                                                        >
                                                            <span
                                                                class="text-gray me-2"
                                                                >|</span
                                                            >Hari
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        class="d-flex align-items-center gap-2"
                                                    >
                                                        <input
                                                            type="text"
                                                            placeholder="Minimal Order"
                                                            class="input-transparent"
                                                            v-model="
                                                                city.minOrder
                                                            "
                                                        />
                                                        <p
                                                            class="mb-0 w-100 text-end"
                                                        >
                                                            <span
                                                                class="text-sm text-gray-500 ml-2"
                                                            >
                                                                {{
                                                                    services[
                                                                        serviceIndex
                                                                    ]
                                                                        .minOrderType
                                                                        ? services[
                                                                              serviceIndex
                                                                          ]
                                                                              .minOrderType ===
                                                                          "kilogram"
                                                                            ? "Kg"
                                                                            : "m³"
                                                                        : "Kg"
                                                                }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <button
                            class="btn outline-yellow w-100 mb-4"
                            @click="addMultiService"
                        >
                            <i class="pi pi-plus me-2"></i>
                            Tambah Layanan
                        </button>
                    </div>
                    <div v-if="!isMulti">
                        <div
                            class="row"
                            v-for="(service, index) in services"
                            :key="index"
                        >
                            <div
                                :class="{
                                    'col-lg-11': index > 0,
                                    'col-lg-12': index < 0,
                                }"
                            >
                                <div class="row">
                                    <div class="col-lg-4">
                                        <label class="mb-2"
                                            >Jenis Layanan
                                            <span class="text-yellow"
                                                >*</span
                                            ></label
                                        >
                                        <multiselect
                                            v-model="service.serviceType"
                                            :options="serviceTypes"
                                            label="name"
                                            track-by="id"
                                            placeholder="Pilih jenis layanan"
                                            class="multi-form-custom"
                                            :show-labels="false"
                                        >
                                        </multiselect>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="mb-2"
                                            >Harga
                                            <span class="text-yellow"
                                                >*</span
                                            ></label
                                        >
                                        <div class="input-group mb-3">
                                            <span class="input-group-text"
                                                >Rp</span
                                            >
                                            <input
                                                type="text"
                                                class="form-control input-sidebar"
                                                placeholder="Masukkan harga"
                                                v-model="service.price"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="mb-2"
                                            >Lead Time
                                            <span class="text-yellow"
                                                >*</span
                                            ></label
                                        >
                                        <div class="input-group mb-3">
                                            <input
                                                type="text"
                                                class="form-control input-sidebar-2"
                                                placeholder="Masukkan lead time"
                                                v-model="service.leadTime"
                                            />
                                            <span class="input-group-text"
                                                >Hari</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-lg-1"
                                v-if="index > 0"
                                @click="deleteSingleService(index)"
                            >
                                <label class="fw-bold mb-2 invisible"
                                    >inv
                                </label>
                                <div class="btn btn-outline-green">
                                    <img
                                        src="../assets/trash-multiple.svg"
                                        alt="trash multiple"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        class="btn outline-yellow w-100 mb-4"
                        @click="addSingle"
                        v-if="!isMulti"
                    >
                        <i class="pi pi-plus me-2"></i>
                        Tambah Layanan
                    </button>
                    <button
                        class="btn btn-primary w-100"
                        @click="modalConfirm = true"
                        :disabled="!isFormValid"
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data route & Lead Time akan disimpan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="saveData()"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data route & Lead Time sudah disimpan
                    </h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/route-and-lead"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import Multiselect from "vue-multiselect";
import magnifierDisabled from "../assets/magnifier-disabled.svg";
import magnifier from "../assets/magnifier.svg";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            minOrderTypes: [
                { name: "Kg", value: "kilogram" },
                { name: "m³", value: "metercubic" },
            ],
            showMinOrderTypeDropdown: null,
            modalConfirm: false,
            modalSuccess: false,
            serviceTypes: [
                {
                    name: "Pengiriman Barang",
                    id: "pengiriman_barang",
                },
                {
                    name: "Pengiriman Motor",
                    id: "pengiriman_motor",
                },
                {
                    name: "Pengiriman Mobil",
                    id: "pengiriman_mobil",
                },
                {
                    name: "Sewa Kontainer 20 Feet",
                    id: "sewa_container_20ft",
                },
                {
                    name: "Sewa Kontainer 40 Feet",
                    id: "sewa_container_40ft",
                },
            ],
            transitPoints: [],
            magnifierIcon: magnifierDisabled,
            magnifierActiveIcon: magnifier,
            isPageLoading: false,
            originProvince: null,
            originRegency: null,
            destinationProvince: null,
            destinationRegency: null,
            originRegencies: [],
            destinationRegencies: [],
            provinces: [],
            regencies: [],
            isLoading: false,
            isMulti: false,
            services: [],
        };
    },
    computed: {
        isFormValid() {
            return this.validateForm();
        },
    },
    watch: {
        isMulti(newValue) {
            if (newValue) {
                this.services = [
                    {
                        serviceType: null,
                        cities: this.destinationRegencies.map((city) => ({
                            city: city,
                            isSelected: false,
                            price: "",
                            leadTime: "",
                            minOrder: "",
                        })),
                    },
                ];
            } else {
                this.services = [
                    { serviceType: null, price: "", leadTime: "" },
                ];
            }
        },
    },
    created() {
        this.getProvinces();
        this.getRegencies();
    },
    methods: {
        resetServiceCities() {
            if (this.isMulti && this.destinationProvince) {
                this.services = [
                    {
                        serviceType: null,
                        cities: this.destinationRegencies.map((city) => ({
                            city: city,
                            isSelected: false,
                            price: "",
                            leadTime: "",
                            minOrder: "",
                        })),
                    },
                ];
            }
        },
        toggleMinOrderTypeDropdown(serviceIndex) {
            if (this.showMinOrderTypeDropdown === serviceIndex) {
                this.showMinOrderTypeDropdown = null;
            } else {
                this.showMinOrderTypeDropdown = serviceIndex;
            }
        },

        selectMinOrderType(serviceIndex, type) {
            if (!this.isMulti) {
                this.services[serviceIndex].minOrderType = type;
            } else {
                this.services[serviceIndex].minOrderType = type;
            }
            this.showMinOrderTypeDropdown = null;
        },
        addMultiService() {
            const newService = {
                cities: this.destinationRegencies.map((city) => ({
                    city: city,
                    isSelected: false,
                    price: "",
                    leadTime: "",
                    minOrder: "",
                })),
            };

            if (!this.services) {
                this.services = [];
            }
            this.services.push(newService);
        },
        toggleSelectAllCities(serviceIndex) {
            const service = this.services[serviceIndex];
            const allSelected = service.cities.every((city) => city.isSelected);

            service.cities.forEach((city) => {
                city.isSelected = !allSelected;
            });
        },

        applyUniformData(serviceIndex) {
            const service = this.services[serviceIndex];
            service.cities.forEach((city) => {
                city.price = service.uniformPrice;
                city.leadTime = service.uniformLeadTime;
                city.minOrder = service.uniformMinOrder;
            });
        },
        resetUniformData(serviceIndex) {
            const service = this.services[serviceIndex];
            service.uniformPrice = "";
            service.uniformLeadTime = "";
            service.uniformMinOrder = "";
        },
        deleteSingleService(index) {
            if (this.services.length > 1) {
                this.services.splice(index, 1);
            }
        },
        addSingle() {
            this.services.push({
                serviceType: null,
                price: "",
                leadTime: "",
            });
        },
        saveData() {
            this.isLoading = true;
            let routes = null;
            if (!this.isMulti) {
                routes = this.services.map((info) => {
                    let route = {
                        origin_regency_id: this.originRegency.id,
                        dooring_regency_id: this.destinationRegency.id,
                        dooring_province_id: this.destinationProvince.id,
                        service_type: info.serviceType.id,
                        price: info.price,
                        leadtime: parseInt(info.leadTime),
                        order_minimum: 0,
                        order_minimum_type: info.minOrderType || "kilogram",
                    };
                    this.transitPoints.forEach((transit, index) => {
                        if (transit.value) {
                            route[`transit_${index + 1}`] = transit.value.id;
                        }
                    });

                    return route;
                });
            } else {
                routes = this.services.flatMap((service) =>
                    service.cities
                        .filter((city) => city.isSelected)
                        .map((city) => {
                            let route = {
                                origin_regency_id: this.originRegency.id,
                                dooring_regency_id: city.city.id,
                                dooring_province_id:
                                    this.destinationProvince.id,
                                service_type: service.serviceType.id,
                                price: city.price,
                                leadtime: parseInt(city.leadTime),
                                order_minimum: parseFloat(city.minOrder || 0),
                                order_minimum_type:
                                    service.minOrderType || "kilogram",
                            };

                            this.transitPoints.forEach((transit, index) => {
                                if (transit.value) {
                                    route[`transit_${index + 1}`] =
                                        transit.value.id;
                                }
                            });

                            return route;
                        })
                );
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/ho/routes/create`,
                { routes },
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoading = false;
                    this.modalSuccess = true;
                    this.modalConfirm = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        addTransitPoint() {
            if (this.transitPoints.length < 3) {
                this.transitPoints.push({
                    value: null,
                });
            }
        },
        removeTransitPoint(index) {
            this.transitPoints.splice(index, 1);
        },
        getOriginRegencies() {
            this.originRegency = null;
            this.destinationProvince = null;
            this.destinationRegency = null;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                params: { province_id: this.originProvince.id },
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.originRegencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getRegencies() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.regencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDestinationRegencies() {
            this.destinationRegency = null;
            this.services = [];
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                params: { province_id: this.destinationProvince.id },
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.destinationRegencies = res.data.data;
                    if (this.isMulti) {
                        this.resetServiceCities();
                    }
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getProvinces() {
            this.isPageLoading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/province`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.provinces = res.data.data;
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        validateForm() {
            if (!this.originProvince || !this.originRegency) {
                return false;
            }
            if (!this.destinationProvince) {
                return false;
            }
            if (!this.isMulti && !this.destinationRegency) {
                return false;
            }
            if (this.transitPoints.length > 0) {
                const hasEmptyTransit = this.transitPoints.some(
                    (point) => !point.value
                );
                if (hasEmptyTransit) {
                    return false;
                }
            }
            for (const service of this.services) {
                if (!service.serviceType) {
                    return false;
                }

                if (!this.isMulti) {
                    if (!service.price || !service.leadTime) {
                        return false;
                    }
                } else {
                    const selectedCities = service.cities.filter(
                        (city) => city.isSelected
                    );

                    if (selectedCities.length === 0) {
                        return false;
                    }

                    const invalidCity = selectedCities.some(
                        (city) => !city.price || !city.leadTime
                    );

                    if (invalidCity) {
                        return false;
                    }
                }
            }

            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.left-35 {
    left: -35px;
}
.input-transparent {
    background: transparent;
    border: none;
    outline: none;
    resize: none;
}
.input-sidebar {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.input-sidebar-2 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}
.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}
.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
.d-none-sm {
    display: none;
}
.invisible-md {
    display: none;
}
@media only screen and (min-width: 992px) {
    .d-none-sm {
        display: block !important;
    }
    .invisible-md {
        visibility: hidden !important;
        display: block;
    }
}
@media only screen and (min-width: 435px) {
    .justify-content-sm-between {
        justify-content: space-between !important;
    }
}
::v-deep .multiselect--disabled .multiselect__select {
    background: transparent !important;
}
.br-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    border-radius: 8px;
}
.outline-green {
    border: 1px solid #ff8600 !important;
    color: #ff8600 !important;
    background-color: transparent !important;
    border-radius: 8px;
}
::v-deep .multi-form-custom.magnifier {
    .multiselect__tags {
        padding-left: 40px !important;
        padding-right: 0 !important;
    }
    .multiselect__select {
        padding: 0;
    }
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
</style>
