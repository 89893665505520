<template>
    <div class="padding-container">
        <div class="fw-bold">
            <div
                @click="$router.go(-1)"
                to="/order/process"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="fw-bold mb-4">
                Tipe Customer <span class="text-danger">*</span>
            </h5>
            <div class="row">
                <div
                    class="col-lg-6 cursor-pointer mb-3"
                    @click="customerType = 'individu'"
                >
                    <div
                        class="box h-100 align-items-center d-flex"
                        :class="{ selected: customerType === 'individu' }"
                    >
                        <label class="container-radio">
                            <div class="d-flex">
                                <img
                                    src="../assets/individu-active.svg"
                                    alt="individu"
                                    class="me-3"
                                    v-if="customerType === 'individu'"
                                />
                                <img
                                    v-else
                                    src="../assets/individu.svg"
                                    alt="individu"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <p class="fw-bold mb-0">Individu</p>
                                    <p class="text-gray mb-0">
                                        Pilihan untuk keperluan pengiriman
                                        pribadi
                                    </p>
                                </div>
                            </div>
                            <input
                                type="radio"
                                name="radio"
                                v-model="customerType"
                                value="individu"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div
                    class="col-lg-6 cursor-pointer mb-3"
                    @click="customerType = 'corporate'"
                >
                    <div
                        class="box h-100align-items-center d-flex"
                        :class="{ selected: customerType === 'corporate' }"
                    >
                        <label class="container-radio">
                            <div class="d-flex">
                                <img
                                    src="../assets/va-active.svg"
                                    alt="va"
                                    class="me-3"
                                    v-if="customerType === 'corporate'"
                                />
                                <img
                                    v-else
                                    src="../assets/va.svg"
                                    alt="va"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <p class="fw-bold mb-0">Corporate</p>
                                    <p class="text-gray mb-0">
                                        Pelanggan bisnis dengan perhitungan
                                        pajak yang disesuaikan.
                                    </p>
                                </div>
                            </div>
                            <input
                                type="radio"
                                name="radio"
                                v-model="customerType"
                                value="corporate"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="mb-0 fw-bold">Data Pengirim</h5>
            <div class="row mt-4">
                <div class="col-lg-6" ref="senderNameField">
                    <label class="fw-bold mb-1">
                        Nama Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        :class="[
                            'form-control',
                            { 'border-red': showValidation && !senderName },
                        ]"
                        placeholder="Masukkan nama pengirim"
                        v-model="senderName"
                    />
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="showValidation && !senderName"
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon lengkapi data nama pengirim
                        </p>
                    </div>
                </div>
                <div class="col-lg-6" ref="senderPhoneField">
                    <label class="fw-bold mb-1">
                        Nomor Handphone Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span
                            :class="[
                                'input-group-text',
                                {
                                    'border-red':
                                        (showValidation && !senderPhone) ||
                                        (this.senderPhone?.length != 0 &&
                                            (this.senderPhone?.length < 8 ||
                                                this.senderPhone?.length > 13)),
                                },
                            ]"
                            >+62</span
                        >
                        <input
                            type="text"
                            :class="[
                                'form-control',
                                'form-phone',
                                {
                                    'border-red':
                                        (showValidation && !senderPhone) ||
                                        (this.senderPhone?.length != 0 &&
                                            (this.senderPhone?.length < 8 ||
                                                this.senderPhone?.length > 13)),
                                },
                            ]"
                            placeholder="Masukkan nomor pengirim"
                            @input="validatePhoneSender()"
                            v-model="senderPhone"
                        />
                    </div>
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="showValidation && !senderPhone"
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon lengkapi data nomor handphone pengirim
                        </p>
                    </div>
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="
                            showValidation &&
                            this.senderPhone?.length != 0 &&
                            (this.senderPhone?.length < 8 ||
                                this.senderPhone?.length > 13)
                        "
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Nomor handphone pengirim harus antara 8 dan 13
                            digit.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 mt-3" ref="senderRegencyField">
                    <label class="fw-bold mb-1">
                        Kota/Kabupaten Asal
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="senderRegency"
                        :options="senderRegencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten asal"
                        :class="[
                            'multi-form-custom',
                            {
                                'multi-red-custom':
                                    showValidation && !senderRegency,
                            },
                        ]"
                        :show-labels="false"
                    >
                    </multiselect>
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="showValidation && !senderRegency"
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon lengkapi data kota/kabupaten asal
                        </p>
                    </div>
                </div>
                <div class="col-lg-12 mt-2" ref="senderAddressField">
                    <label class="fw-bold mb-1">
                        Alamat Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        :class="[
                            'form-control',
                            { 'border-red': showValidation && !senderAddress },
                        ]"
                        placeholder="Masukkan alamat pengirim"
                        v-model="senderAddress"
                    ></textarea>
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="showValidation && !senderAddress"
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon lengkapi data alamat pengirim
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="mb-0 fw-bold">Data Penerima</h5>
            <div class="row mt-4">
                <div class="col-lg-6" ref="receiverNameField">
                    <label class="fw-bold mb-1">
                        Nama Penerima
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        :class="[
                            'form-control',
                            { 'border-red': showValidation && !receiverName },
                        ]"
                        placeholder="Masukkan nama penerima"
                        v-model="receiverName"
                    />
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="showValidation && !receiverName"
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon lengkapi data nama penerima
                        </p>
                    </div>
                </div>
                <div class="col-lg-6" ref="receiverPhoneField">
                    <label class="fw-bold mb-1">
                        Nomor Handphone Penerima
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span
                            :class="[
                                'input-group-text',
                                {
                                    'border-red':
                                        (showValidation && !receiverPhone) ||
                                        (this.receiverPhone?.length != 0 &&
                                            (this.receiverPhone?.length < 8 ||
                                                this.receiverPhone?.length >
                                                    13)),
                                },
                            ]"
                            >+62</span
                        >
                        <input
                            type="text"
                            :class="[
                                'form-control',
                                'form-phone',
                                {
                                    'border-red':
                                        (showValidation && !receiverPhone) ||
                                        (this.receiverPhone?.length != 0 &&
                                            (this.receiverPhone?.length < 8 ||
                                                this.receiverPhone?.length >
                                                    13)),
                                },
                            ]"
                            placeholder="Masukkan nomor penerima"
                            @input="validatePhoneReceiver()"
                            v-model="receiverPhone"
                        />
                    </div>
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="showValidation && !receiverPhone"
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon lengkapi data nomor handphone penerima
                        </p>
                    </div>
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="
                            showValidation &&
                            this.receiverPhone?.length != 0 &&
                            (this.receiverPhone?.length < 8 ||
                                this.receiverPhone?.length > 13)
                        "
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Nomor handphone pengirim harus antara 8 dan 13
                            digit.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 mt-3" ref="receiverProvinceField">
                    <label class="fw-bold mb-1">
                        Provinsi
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="receiverProvince"
                        :options="receiverProvinces"
                        label="name"
                        track-by="id"
                        placeholder="Pilih provinsi"
                        :class="[
                            'multi-form-custom',
                            {
                                'multi-red-custom':
                                    showValidation && !receiverProvince,
                            },
                        ]"
                        :show-labels="false"
                        @input="changeReceiverProvince()"
                    >
                    </multiselect>
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="showValidation && !receiverProvince"
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon lengkapi data provinsi
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 mt-3" ref="receiverRegencyField">
                    <label class="fw-bold mb-1">
                        Kota/kabupaten
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="receiverRegency"
                        :options="receiverRegencies"
                        label="name"
                        track-by="id"
                        placeholder="Pilih kota/kabupaten"
                        :class="[
                            'multi-form-custom',
                            {
                                'multi-red-custom':
                                    showValidation && !receiverRegency,
                            },
                        ]"
                        :show-labels="false"
                        :disabled="!receiverProvince"
                        @input="changeReceiverRegency()"
                    >
                    </multiselect>
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="showValidation && !receiverRegency"
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon lengkapi data kota/kabupaten
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 mt-2" ref="receiverDistrictField">
                    <label class="fw-bold mb-1">
                        Kecamatan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="receiverDistrict"
                        :options="receiverDistricts"
                        label="name"
                        track-by="id"
                        placeholder="Pilih kecamatan"
                        :class="[
                            'multi-form-custom',
                            {
                                'multi-red-custom':
                                    showValidation && !receiverDistrict,
                            },
                        ]"
                        :show-labels="false"
                        :disabled="!receiverRegency"
                        @input="changeReceiverDistrict()"
                    >
                    </multiselect>
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="showValidation && !receiverDistrict"
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon lengkapi data kecamatan
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 mt-2" ref="receiverSubDistrictField">
                    <label class="fw-bold mb-1">
                        Kelurahan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="receiverSubDistrict"
                        :options="receiverSubDistricts"
                        label="name"
                        track-by="id"
                        placeholder="Pilih Kelurahan"
                        :class="[
                            'multi-form-custom',
                            {
                                'multi-red-custom':
                                    showValidation && !receiverSubDistrict,
                            },
                        ]"
                        :show-labels="false"
                        :disabled="!receiverDistrict"
                    >
                    </multiselect>
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="showValidation && !receiverSubDistrict"
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon lengkapi data kelurahan
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 mt-2">
                    <label class="fw-bold mb-1">Kode Pos</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan kode pos"
                        v-model="receiverZipCode"
                        @input="validateZipCode()"
                    />
                </div>
                <div class="col-lg-12 mt-3" ref="receiverAddressField">
                    <label class="fw-bold mb-1">
                        Alamat Penerima
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        :class="[
                            'form-control',
                            {
                                'border-red':
                                    showValidation && !receiverAddress,
                            },
                        ]"
                        placeholder="Masukkan alamat lengkap penerima"
                        v-model="receiverAddress"
                    ></textarea>
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="showValidation && !receiverAddress"
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon lengkapi data alamat penerima
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3" ref="serviceTypeField">
            <h5 class="fw-bold mb-3">Pilih Jenis Layanan</h5>
            <label class="fw-bold mb-1">
                Jenis Layanan
                <span class="text-danger fw-bold">*</span>
            </label>
            <multiselect
                v-model="serviceType"
                :options="serviceTypes"
                label="name"
                track-by="id"
                placeholder="Pilih jenis layanan"
                :class="[
                    'multi-form-custom',
                    {
                        'multi-red-custom': showValidation && !serviceType,
                    },
                ]"
                :show-labels="false"
            >
            </multiselect>
            <div
                class="d-flex align-items-center mt-2"
                v-if="showValidation && !serviceType"
            >
                <img src="../assets/warning-form.svg" alt="warning form" />
                <p class="text-red mb-0 ms-2 size-12">
                    Mohon lengkapi data jenis layanan
                </p>
            </div>
        </div>
        <div
            class="box-manifest mt-3"
            v-if="serviceType && serviceType.id === 'pengiriman_barang'"
        >
            <h5 class="fw-bold mb-0">Hasil Ukur - Timbang Barang</h5>
            <Goods
                ref="goodsComponent"
                @update:data="handleGoodsData"
                :showValidation="showValidation"
            />
        </div>
        <div
            class="box-manifest mt-3"
            v-if="serviceType && serviceType.id === 'pengiriman_motor'"
        >
            <h5 class="fw-bold mb-0">Detail Pengiriman Motor</h5>
            <Bike
                ref="bikesComponent"
                @update:data="handleBikesData"
                :showValidation="showValidation"
            />
        </div>
        <div
            class="box-manifest mt-3"
            v-if="serviceType && serviceType.id === 'pengiriman_mobil'"
        >
            <h5 class="fw-bold mb-0">Detail Pengiriman Mobil</h5>
            <Car
                ref="carsComponent"
                @update:data="handleCarsData"
                :showValidation="showValidation"
            />
        </div>
        <div class="box-manifest mt-3">
            <h5 class="fw-bold mb-4">
                Metode Pembayaran <span class="text-danger">*</span>
            </h5>
            <div class="row">
                <div
                    class="col-lg-4 cursor-pointer"
                    @click="selectedPayment = 'cash'"
                >
                    <div
                        class="box h-100 align-items-center d-flex"
                        :class="{ selected: selectedPayment === 'cash' }"
                    >
                        <label class="container-radio">
                            <div class="d-flex">
                                <img
                                    src="../assets/money-active.svg"
                                    alt="money"
                                    class="me-3"
                                    v-if="selectedPayment === 'cash'"
                                />
                                <img
                                    v-else
                                    src="../assets/money.svg"
                                    alt="money"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <p class="fw-bold mb-0">Tunai</p>
                                    <p class="text-gray mb-0">
                                        Pembayaran Tunai
                                    </p>
                                </div>
                            </div>
                            <input
                                type="radio"
                                name="radio"
                                value="cash"
                                v-model="selectedPayment"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div
                    class="col-lg-4 cursor-pointer"
                    @click="selectedPayment = 'va'"
                    v-if="paymentVa === 'enable'"
                >
                    <div
                        class="box h-100align-items-center d-flex"
                        :class="{ selected: selectedPayment === 'va' }"
                    >
                        <label class="container-radio">
                            <div class="d-flex">
                                <img
                                    src="../assets/va-active.svg"
                                    alt="va"
                                    class="me-3"
                                    v-if="selectedPayment === 'va'"
                                />
                                <img
                                    v-else
                                    src="../assets/va.svg"
                                    alt="va"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <p class="fw-bold mb-0">Virtual Account</p>
                                    <p class="text-gray mb-0">
                                        Cepat & otomatis terverifikasi
                                    </p>
                                </div>
                            </div>
                            <input
                                type="radio"
                                name="radio"
                                value="va"
                                v-model="selectedPayment"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div
                    class="col-lg-12 position-relative mt-2"
                    v-if="selectedPayment === 'va'"
                    ref="selectedBankField"
                >
                    <label class="fw-bold mb-1 mt-3">
                        Bank
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div
                        :class="[
                            'form-control',
                            'cursor-pointer',
                            {
                                'border-red':
                                    showValidation &&
                                    !selectedBank &&
                                    selectedPayment == 'va',
                                'mb-2': !isDropdownOpen,
                            },
                        ]"
                        @click="isDropdownOpen = !isDropdownOpen"
                    >
                        <div
                            class="d-flex justify-content-between align-items-center w-100"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    v-if="selectedBank"
                                    :src="selectedBank.asset"
                                    :alt="selectedBank.asset"
                                    width="50"
                                    class="me-2"
                                />
                                <p
                                    class="mb-0"
                                    :class="{
                                        'text-gray': !selectedBank,
                                    }"
                                >
                                    {{
                                        selectedBank
                                            ? selectedBank.label
                                            : "Pilih bank pembayaran"
                                    }}
                                </p>
                            </div>
                            <img
                                src="../assets/arrow-down-2.svg"
                                alt="arrow down 2"
                                :class="{ 'rotate-arrow': isDropdownOpen }"
                            />
                        </div>
                    </div>
                    <div
                        v-if="isDropdownOpen"
                        class="form-control my-2 position-absolute z-3"
                    >
                        <div
                            v-for="(channel, index) in channels"
                            :key="index"
                            class="d-flex justify-content-center flex-column py-2 cursor-pointer"
                            :class="{
                                'border-bottom': index !== channels.length - 1,
                            }"
                            @click="selectBank(channel)"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    :src="channel.asset"
                                    :alt="channel.asset"
                                    width="50"
                                    class="me-2"
                                />
                                {{ channel.label }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="d-flex align-items-center"
                        v-if="
                            showValidation &&
                            !selectedBank &&
                            selectedPayment == 'va'
                        "
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon lengkapi data bank
                        </p>
                    </div>
                </div>
                <div
                    class="col-lg-4 cursor-pointer"
                    @click="selectedPayment = 'transfer'"
                >
                    <div
                        class="box h-100align-items-center d-flex"
                        :class="{ selected: selectedPayment === 'transfer' }"
                    >
                        <label class="container-radio">
                            <div class="d-flex">
                                <img
                                    src="../assets/transfer-active.svg"
                                    alt="transfer"
                                    class="me-3"
                                    v-if="selectedPayment === 'transfer'"
                                />
                                <img
                                    v-else
                                    src="../assets/transfer.svg"
                                    alt="transfer"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <p class="fw-bold mb-0">Transfer Bank</p>
                                    <p class="text-gray mb-0">
                                        Fleksibel & bayar kapan saja!
                                    </p>
                                </div>
                            </div>
                            <input
                                type="radio"
                                name="radio"
                                value="transfer"
                                v-model="selectedPayment"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div
                    class="col-lg-12 position-relative mt-2"
                    v-if="selectedPayment === 'transfer'"
                    ref="selectedBankFieldTransfer"
                >
                    <label class="fw-bold mb-1 mt-3">
                        Bank
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div
                        :class="[
                            'form-control',
                            'cursor-pointer',
                            {
                                'border-red':
                                    showValidation &&
                                    !selectedBankTransfer &&
                                    selectedPayment == 'va',
                                'mb-2': !isDropdownOpenTransfer,
                            },
                        ]"
                        @click="
                            isDropdownOpenTransfer = !isDropdownOpenTransfer
                        "
                    >
                        <div
                            class="d-flex justify-content-between align-items-center w-100"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    v-if="selectedBankTransfer"
                                    :src="selectedBankTransfer.icon"
                                    :alt="selectedBankTransfer.icon"
                                    width="50"
                                    class="me-2"
                                />
                                <p
                                    class="mb-0"
                                    :class="{
                                        'text-gray': !selectedBankTransfer,
                                    }"
                                >
                                    {{
                                        selectedBankTransfer
                                            ? selectedBankTransfer.name
                                            : "Pilih bank pembayaran"
                                    }}
                                </p>
                            </div>
                            <img
                                src="../assets/arrow-down-2.svg"
                                alt="arrow down 2"
                                :class="{
                                    'rotate-arrow': isDropdownOpenTransfer,
                                }"
                            />
                        </div>
                    </div>
                    <div
                        v-if="isDropdownOpenTransfer"
                        class="form-control my-2 position-absolute z-3"
                    >
                        <div
                            v-for="(channel, index) in channelTransfers"
                            :key="index"
                            class="d-flex justify-content-center flex-column py-2 cursor-pointer"
                            :class="{
                                'border-bottom':
                                    index !== channelTransfers.length - 1,
                            }"
                            @click="selectBankTransfer(channel)"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    :src="channel.icon"
                                    :alt="channel.icon"
                                    width="50"
                                    class="me-2"
                                />
                                {{ channel.name }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="d-flex align-items-center"
                        v-if="
                            showValidation &&
                            !selectedBankTransfer &&
                            selectedPayment == 'va'
                        "
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon lengkapi data bank
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4" v-if="suggestion">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
                <div class="box selected">
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                src="../assets/information.svg"
                                alt="information"
                                class="me-2"
                            />
                            <h6 class="mb-0">Informasi Harga Pengiriman</h6>
                        </div>
                        <h5 class="mb-0 text-suggestion">
                            {{ currency(suggestion) }}/Kg
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
                <div class="box-manifest">
                    <h5 class="mb-0 fw-bold">Total Biaya Pengiriman</h5>
                    <div
                        class="d-flex align-items-center mt-3"
                        ref="servicePriceField"
                    >
                        <div>Biaya Pengiriman</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="isEditService = !isEditService"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditService == false,
                                    'text-primary': this.isEditService == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditService">
                                {{ currency(servicePrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="servicePrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('service')"
                                    :style="{
                                        width: moneyInputWidths.service + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div
                        class="d-flex align-items-center bg-warning-info mt-2 p-2"
                        v-if="showValidation && !servicePrice"
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon isi <b>biaya pengiriman</b> terlebih dahulu
                            untuk melanjutkan
                        </p>
                    </div>
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="
                            serviceType === '' ||
                            serviceType.id === 'pengiriman_barang' ||
                            serviceType.id === 'pengiriman_motor'
                        "
                    >
                        <div>Biaya Packing</div>
                        <div
                            class="ms-auto fw-bold d-flex align-items-center"
                            v-if="serviceType.id === 'pengiriman_barang'"
                        >
                            {{ currency(goodsPackingPrice) }}
                        </div>
                        <div
                            class="ms-auto fw-bold d-flex align-items-center"
                            v-if="
                                serviceType.id === 'pengiriman_motor' &&
                                bikesData?.protections?.length !== 0
                            "
                        >
                            <a
                                href="javascript:void(0)"
                                @click="isEditPacking = !isEditPacking"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditPacking == false,
                                    'text-primary': this.isEditPacking == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditPacking">
                                {{ currency(packingPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="bikePackingPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('packing')"
                                    :style="{
                                        width: moneyInputWidths.packing + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                        <div
                            class="ms-auto fw-bold d-flex align-items-center"
                            v-if="
                                serviceType.id === 'pengiriman_mobil' ||
                                serviceType.id === 'sewa_container_20ft' ||
                                serviceType.id === 'sewa_container_40ft' ||
                                !serviceType.id ||
                                bikesData?.protections?.length === 0
                            "
                        >
                            {{ currency(0) }}
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex align-items-center mt-2">
                        <div>Biaya Jasa Tambahan</div>
                    </div>
                    <div class="d-flex align-items-center mt-2 ms-3">
                        <div>Biaya Asuransi</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="isEditInsurance = !isEditInsurance"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditInsurance == false,
                                    'text-primary':
                                        this.isEditInsurance == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditInsurance">
                                {{ currency(insurancePrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="insurancePrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('insurance')"
                                    :style="{
                                        width:
                                            moneyInputWidths.insurance + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2 ms-3">
                        <div>Biaya Alat Berat</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="
                                    isEditHeavyEquipment = !isEditHeavyEquipment
                                "
                                class="me-1"
                                v-bind:class="{
                                    'text-gray':
                                        this.isEditHeavyEquipment == false,
                                    'text-primary':
                                        this.isEditHeavyEquipment == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditHeavyEquipment">
                                {{ currency(heavyEquipmentPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="heavyEquipmentPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('heavyEquipment')"
                                    :style="{
                                        width:
                                            moneyInputWidths.heavyEquipment +
                                            'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2 ms-3">
                        <div>Biaya Penerusan</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="isEditForwarding = !isEditForwarding"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditForwarding == false,
                                    'text-primary':
                                        this.isEditForwarding == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditForwarding">
                                {{ currency(forwardingPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="forwardingPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('forwarding')"
                                    :style="{
                                        width:
                                            moneyInputWidths.forwarding + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2 ms-3">
                        <div>Biaya Penjemputan</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="isEditPickUp = !isEditPickUp"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditPickUp == false,
                                    'text-primary': this.isEditPickUp == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditPickUp">
                                {{ currency(pickUpPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="pickUpPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('pickUp')"
                                    :style="{
                                        width: moneyInputWidths.pickUp + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2 ms-3">
                        <div>Biaya Inap Barang</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="isEditOverNight = !isEditOverNight"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditOverNight == false,
                                    'text-primary':
                                        this.isEditOverNight == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditOverNight">
                                {{ currency(overNightPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="overNightPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('overNight')"
                                    :style="{
                                        width:
                                            moneyInputWidths.overNight + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2 ms-3">
                        <div>Biaya Racking</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="isEditRacking = !isEditRacking"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditRacking == false,
                                    'text-primary': this.isEditRacking == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditRacking">
                                {{ currency(rackingPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="rackingPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('racking')"
                                    :style="{
                                        width: moneyInputWidths.racking + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2 ms-3">
                        <div>Biaya Buruh</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="isEditLabor = !isEditLabor"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditLabor == false,
                                    'text-primary': this.isEditLabor == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditLabor">
                                {{ currency(laborPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="laborPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('labor')"
                                    :style="{
                                        width: moneyInputWidths.labor + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex align-items-center mt-2">
                        <div class="text-yellow">Diskon</div>
                        <div
                            class="ms-auto fw-bold text-yellow d-flex align-items-center"
                        >
                            <a
                                href="javascript:void(0)"
                                @click="isEditDiscount = !isEditDiscount"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditDiscount == false,
                                    'text-primary': this.isEditDiscount == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            -<template v-if="!isEditDiscount">
                                {{ currency(discountPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="discountPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    @input="updateWidth('discount')"
                                    :style="{
                                        width: moneyInputWidths.discount + 'px',
                                    }"
                                    class="form-control form-edit yellow"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex align-items-center mt-2">
                        <h5 class="mb-0 fw-bold">Sub Total</h5>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            {{ currency(calculateSubTotal) }}
                        </div>
                    </div>
                    <div class="box-manifest mt-3">
                        <div class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="custom-checkbox"
                                v-model="withTax"
                            />
                            <div class="d-flex flex-column ms-3">
                                <p class="fw-bold mb-0">
                                    Tambahkan Komponen Pajak
                                </p>
                                <p class="mb-0">
                                    Otomatis menambahkan nilai pajak ke total
                                    pesanan
                                </p>
                            </div>
                        </div>
                        <hr v-if="withTax" />
                        <div
                            class="d-flex align-items-center mt-2"
                            v-if="withTax"
                        >
                            <div class="text-yellow">PPH 2%</div>
                            <div
                                class="ms-auto fw-bold text-yellow d-flex align-items-center"
                            >
                                - {{ currency(pph) }}
                            </div>
                        </div>
                        <div
                            class="d-flex align-items-center mt-2"
                            v-if="withTax"
                        >
                            <div class="mb-0 fw-bold">PPN 1,1%</div>
                            <div
                                class="ms-auto fw-bold d-flex align-items-center"
                            >
                                {{ currency(ppn) }}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-3">
                        <h5 class="text-green fw-bold">Total Pembayaran</h5>
                        <div class="ms-auto fw-bold text-green">
                            {{ currency(calculateTotalPayment) }}
                        </div>
                    </div>
                    <div class="mt-3">
                        <button
                            class="btn btn-primary w-100"
                            @click="modalSuccessConfirmation = true"
                        >
                            Buat Pesanan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessConfirmation">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Akan Dibuat</h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin membuat pesanan ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalSuccessConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isSave"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isSave"
                            ></span>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Berhasil Dibuat</h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/order/cashier"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccessVa">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Berhasil dibuat</h5>
                    <div class="text-gray-dashboard">
                        Segera lakukan pembayaran melalui
                        {{
                            selectedPayment === "va"
                                ? "virtual account"
                                : "bank transfer"
                        }}
                    </div>
                </div>
                <div class="mt-4">
                    <router-link
                        :to="'/order/cashier/va/' + receiptCode"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";
import { Money } from "v-money";
import Goods from "../components/ServiceType/Goods.vue";
import Bike from "../components/ServiceType/Bike.vue";
import Car from "../components/ServiceType/Car.vue";

export default {
    components: {
        Multiselect,
        Money,
        Goods,
        Bike,
        Car,
    },
    data() {
        return {
            customerType: "individu",
            orderType: "",
            senderName: "",
            senderPhone: "",
            senderRegency: null,
            senderRegencies: [],
            senderAddress: "",
            receiverName: "",
            receiverPhone: "",
            receiverProvince: null,
            receiverProvinces: [],
            receiverRegency: null,
            receiverRegencies: [],
            receiverDistrict: null,
            receiverDistricts: [],
            receiverSubDistrict: null,
            receiverSubDistricts: [],
            receiverZipCode: "",
            receiverAddress: "",
            serviceType: "",
            serviceTypes: [
                {
                    id: "pengiriman_barang",
                    name: "Pengiriman Barang",
                },
                {
                    id: "pengiriman_motor",
                    name: "Pengiriman Motor",
                },
                {
                    id: "pengiriman_mobil",
                    name: "Pengiriman Mobil",
                },
                {
                    id: "sewa_container_20ft",
                    name: "Sewa Container 20 Feet",
                },
                {
                    id: "sewa_container_40ft",
                    name: "Sewa Container 40 Feet",
                },
            ],
            items: null,
            note: "",
            suggestion: null,
            bikeType: "",
            bikeCapacity: "",
            bikePacking: "",
            bikeDescription: "",
            carType: "",
            carDescription: "",
            selectedPayment: "cash",
            selectedBank: null,
            isDropdownOpen: false,
            channels: [],
            selectedBankTransfer: null,
            isDropdownOpenTransfer: false,
            channelTransfers: [],
            moneyInputWidths: {
                service: 30,
                packing: 30,
                insurance: 30,
                heavyEquipment: 30,
                forwarding: 30,
                pickUp: 30,
                overNight: 30,
                racking: 30,
                labor: 30,
                discount: 30,
            },
            formatNumber: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp",
                suffix: "",
                precision: 0,
                masked: false,
                maxLength: 13,
                minimumValue: 0,
            },
            servicePrice: 0,
            packingPrice: 0,
            bikePackingPrice: 0,
            insurancePrice: 0,
            heavyEquipmentPrice: 0,
            forwardingPrice: 0,
            pickUpPrice: 0,
            overNightPrice: 0,
            rackingPrice: 0,
            laborPrice: 0,
            discountPrice: 0,
            totalPrice: 0,
            isEditService: false,
            isEditPacking: false,
            isEditInsurance: false,
            isEditHeavyEquipment: false,
            isEditForwarding: false,
            isEditPickUp: false,
            isEditOverNight: false,
            isEditRacking: false,
            isEditLabor: false,
            isEditDiscount: false,
            isSave: false,
            modalSuccessConfirmation: false,
            modalSuccess: false,
            modalSuccessVa: false,
            receiptCode: null,
            goodsData: null,
            bikesData: null,
            carsData: null,
            paymentVa: process.env.VUE_APP_PAYMENT_VA,
            withTax: false,
            showValidation: false,
        };
    },
    watch: {
        senderRegency() {
            this.checkAndCallGetSuggestion();
        },
        receiverRegency() {
            this.checkAndCallGetSuggestion();
        },
        serviceType() {
            this.checkAndCallGetSuggestion();
        },
    },
    created() {
        this.getSenderRegencies();
        this.getReceiverProvinces();
        this.getChannels();
        this.getChannelTransfers();
    },
    computed: {
        goodsPackingPrice() {
            const goodsPackingPrice = this.goodsData?.items?.reduce(
                (total, item) => {
                    if (item.protection && item.protection.length > 0) {
                        return (
                            total +
                            item.protection.reduce(
                                (sum, p) => sum + Math.round(p.price),
                                0
                            )
                        );
                    }
                    return total;
                },
                0
            );
            return goodsPackingPrice;
        },
        totalWeight() {
            return this.items.reduce(
                (total, item) => total + item.qty * item.weight,
                0
            );
        },
        totalVolume() {
            return this.items.reduce(
                (total, item) => total + item.qty * item.weight_volume,
                0
            );
        },
        totalCubic() {
            return this.items.reduce(
                (total, item) => total + item.qty * item.cubic,
                0
            );
        },
        chargeWeight() {
            return Math.max(this.totalWeight, this.totalVolume);
        },
        calculateAdditionalTotal() {
            const insurancePrice = this.insurancePrice;
            const heavyEquipmentPrice = this.heavyEquipmentPrice;
            const forwardingPrice = this.forwardingPrice;
            const pickUpPrice = this.pickUpPrice;
            const overNightPrice = this.overNightPrice;
            const rackingPrice = this.rackingPrice;
            const laborPrice = this.laborPrice;

            return (
                insurancePrice +
                heavyEquipmentPrice +
                forwardingPrice +
                pickUpPrice +
                overNightPrice +
                rackingPrice +
                laborPrice
            );
        },
        calculateSubTotal() {
            const servicePrice = this.servicePrice;
            const packingPrice =
                this.serviceType.id === "pengiriman_barang"
                    ? this.goodsPackingPrice
                    : this.serviceType.id === "pengiriman_motor"
                    ? this.bikePackingPrice
                    : this.packingPrice;
            const discountPrice = this.discountPrice;

            return (
                servicePrice +
                packingPrice +
                this.calculateAdditionalTotal -
                discountPrice
            );
        },
        pph() {
            return parseInt((2 * this.calculateSubTotal) / 100);
        },
        ppn() {
            return parseInt((1.1 * this.calculateSubTotal) / 100);
        },
        calculateTotalPayment() {
            if (this.withTax) {
                return this.calculateSubTotal - this.pph + this.ppn;
            } else {
                return this.calculateSubTotal;
            }
        },
    },
    directives: {
        autofocus: {
            inserted: (el) => {
                el.focus();
            },
        },
    },
    methods: {
        handleGoodsData(data) {
            this.goodsData = data;
        },
        handleBikesData(data) {
            this.bikesData = data;
        },
        handleCarsData(data) {
            this.carsData = data;
        },
        storeData() {
            this.isSave = true;
            this.showValidation = true;

            const emptyFields = [
                { field: this.$refs.senderNameField, value: !this.senderName },
                {
                    field: this.$refs.senderPhoneField,
                    value:
                        !this.senderPhone ||
                        this.senderPhone.length < 8 ||
                        this.senderPhone.length > 13,
                },
                {
                    field: this.$refs.senderRegencyField,
                    value: !this.senderRegency,
                },
                {
                    field: this.$refs.senderAddressField,
                    value: !this.senderAddress,
                },
                {
                    field: this.$refs.receiverNameField,
                    value: !this.receiverName,
                },
                {
                    field: this.$refs.receiverPhoneField,
                    value:
                        !this.receiverPhone ||
                        this.receiverPhone.length < 8 ||
                        this.receiverPhone.length > 13,
                },
                {
                    field: this.$refs.receiverProvinceField,
                    value: !this.receiverProvince,
                },
                {
                    field: this.$refs.receiverRegencyField,
                    value: !this.receiverRegency,
                },
                {
                    field: this.$refs.receiverDistrictField,
                    value: !this.receiverDistrict,
                },
                {
                    field: this.$refs.receiverSubDistrictField,
                    value: !this.receiverSubDistrict,
                },
                {
                    field: this.$refs.receiverAddressField,
                    value: !this.receiverAddress,
                },
                {
                    field: this.$refs.servicePriceField,
                    value: !this.servicePrice,
                },
                {
                    field: this.$refs.serviceTypeField,
                    value: !this.serviceType,
                },
                {
                    field: this.$refs.selectedBankField,
                    value: !this.selectedBank,
                },
                {
                    field: this.$refs.selectedBankFieldTransfer,
                    value: !this.selectedBankTransfer,
                },
                ...(this.serviceType.id === "pengiriman_barang"
                    ? [
                          {
                              field: this.$refs.goodsComponent?.getFirstEmptyField(),
                              value: this.goodsData?.items?.some(
                                  (item) =>
                                      !item.description ||
                                      !item.weight ||
                                      !item.length ||
                                      !item.width ||
                                      !item.height
                              ),
                          },
                      ]
                    : []),
                ...(this.serviceType.id === "pengiriman_motor"
                    ? [
                          {
                              field: this.$refs.bikesComponent?.getFirstEmptyField(),
                              value:
                                  !this.bikesData?.vehicle ||
                                  !this.bikesData?.cc,
                          },
                      ]
                    : []),
                ...(this.serviceType.id === "pengiriman_mobil"
                    ? [
                          {
                              field: this.$refs.carsComponent?.getFirstEmptyField(),
                              value: !this.carsData?.vehicle,
                          },
                      ]
                    : []),
            ];

            const firstEmptyField = emptyFields.find((item) => item.value);
            var data = {
                customer_type: this.customerType,
                sender_name: this.senderName,
                sender_phone: this.senderPhone,
                origin_regency_id: this.senderRegency?.id,
                sender_address: this.senderAddress,
                receiver_name: this.receiverName,
                receiver_phone: this.receiverPhone,
                destination_province_id: this.receiverProvince?.id,
                destination_regency_id: this.receiverRegency?.id,
                destination_district_id: this.receiverDistrict?.id,
                destination_subdistrict_id: this.receiverSubDistrict?.id,
                receiver_zipcode: this.receiverZipCode,
                receiver_address: this.receiverAddress,
                service_type: this.serviceType?.id,
                payment_method: this.selectedPayment,
                service_price: this.servicePrice,
                additional_price: this.calculateAdditionalTotal,
                discount_price: this.discountPrice,
                additionals: [
                    {
                        packing: "Biaya Asuransi",
                        price: this.insurancePrice,
                    },
                    {
                        packing: "Biaya Alat Berat",
                        price: this.heavyEquipmentPrice,
                    },
                    {
                        packing: "Biaya Penerusan",
                        price: this.forwardingPrice,
                    },
                    {
                        packing: "Biaya Penjemputan",
                        price: this.pickUpPrice,
                    },
                    {
                        packing: "Biaya Inap Barang",
                        price: this.overNightPrice,
                    },
                    {
                        packing: "Biaya Racking",
                        price: this.rackingPrice,
                    },
                    {
                        packing: "Biaya Buruh",
                        price: this.laborPrice,
                    },
                ],
                pph: this.withTax ? this.pph : 0,
                ppn: this.withTax ? this.ppn : 0,
            };
            if (this.serviceType.id === "pengiriman_barang") {
                const itemsData = this.goodsData?.items?.map((item) => ({
                    qty: item.qty.replace(/\./g, ""),
                    description: item.description,
                    weight: item.weight.replace(/\./g, ""),
                    length: item.length.replace(/\./g, ""),
                    width: item.width.replace(/\./g, ""),
                    height: item.height.replace(/\./g, ""),
                    weight_volume: item.volumeWeight,
                    cubic: item.cubic,
                    protection:
                        item.protection.length > 0
                            ? item.protection.map((h) => ({
                                  packing: h.id,
                                  price: Math.round(h.price),
                              }))
                            : null,
                }));
                data.items = itemsData;
                data.charge_weight = this.goodsData?.chargeWeight;
                data.packing_price = this.goodsPackingPrice;
                data.note = this.goodsData?.note;
            }

            if (this.serviceType.id === "pengiriman_motor") {
                data.vehicle = this.bikesData?.vehicle;
                data.cc = this.bikesData?.cc;
                data.note = this.bikesData?.description;
                data.protections =
                    this.bikesData?.protections.length > 0
                        ? this.bikesData?.protections.map((h) => ({
                              protection: h.packing,
                          }))
                        : null;
                data.packing_price =
                    this.bikesData?.protections.length === 0
                        ? 0
                        : this.bikePackingPrice;
                data.charge_weight = 0;
            }

            if (this.serviceType.id === "pengiriman_mobil") {
                data.vehicle = this.carsData?.vehicle;
                data.note = this.carsData?.description;
                data.packing_price = 0;
                data.charge_weight = 0;
            }

            if (
                this.serviceType.id === "sewa_container_20ft" ||
                this.serviceType.id === "sewa_container_40ft"
            ) {
                data.packing_price = 0;
                data.charge_weight = 0;
            }
            if (this.selectedPayment === "va") {
                data.va = this.selectedBank?.va;
            }
            if (this.selectedPayment === "transfer") {
                data.bank_transfer_id = this.selectedBankTransfer?.id;
            }

            Api.post(`${process.env.VUE_APP_SERVICE_URL}/order/create`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.isSave = false;
                    if (this.selectedPayment === "cash") {
                        this.modalSuccess = true;
                    } else {
                        this.receiptCode = res.data.data.receipt_code;
                        this.modalSuccessVa = true;
                    }
                    this.modalSuccessConfirmation = false;
                })
                .catch((err) => {
                    if (firstEmptyField) {
                        firstEmptyField?.field?.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                        this.isSave = false;
                        this.modalSuccessConfirmation = false;
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                        return;
                    }
                    this.isSave = false;
                    this.modalSuccessConfirmation = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        selectBank(channel) {
            this.selectedBank = channel;
            this.isDropdownOpen = false;
        },
        selectBankTransfer(channel) {
            this.selectedBankTransfer = channel;
            this.isDropdownOpenTransfer = false;
        },
        getChannelTransfers() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/general/list_bank_transfer`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.channelTransfers = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getChannels() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/payment/channel`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.channels = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        updateWidth(type) {
            const values = {
                service: this.servicePrice,
                packing: this.bikePackingPrice,
                insurance: this.insurancePrice,
                heavyEquipment: this.heavyEquipmentPrice,
                forwarding: this.forwardingPrice,
                pickUp: this.pickUpPrice,
                overNight: this.overNightPrice,
                racking: this.rackingPrice,
                labor: this.laborPrice,
                discount: this.discountPrice,
            };

            const valueLength = String(values[type]).length;
            this.moneyInputWidths[type] = 30 + (valueLength - 1) * 10;
        },
        checkAndCallGetSuggestion() {
            if (
                this.senderRegency?.id &&
                this.receiverRegency?.id &&
                this.serviceType
            ) {
                this.getSuggestion();
            }
        },
        getSuggestion() {
            var payload = {
                origin_regency_id: this.senderRegency?.id,
                destination_regency_id: this.receiverRegency?.id,
                service_type: this.serviceType,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/order/get_suggestion`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.suggestion = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getSenderRegencies() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.senderRegencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getReceiverProvinces() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/province`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.receiverProvinces = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getReceiverRegencies() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/regency?province_id=${this.receiverProvince?.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.receiverRegencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getReceiverDistricts() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/district?regency_id=${this.receiverRegency?.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.receiverDistricts = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getReceiverSubDistrict() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/subdistrict?district_id=${this.receiverDistrict?.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.receiverSubDistricts = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        validatePhoneSender() {
            if (!this.senderPhone.startsWith("8")) {
                this.senderPhone =
                    "8" + this.senderPhone.replace(/[^0-9]/g, "");
            } else {
                this.senderPhone = this.senderPhone.replace(/[^0-9]/g, "");
            }
        },
        changeReceiverProvince() {
            this.receiverRegency = null;
            this.receiverDistrict = null;
            this.receiverSubDistrict = null;
            if (this.receiverProvince) {
                this.getReceiverRegencies();
            }
        },
        changeReceiverRegency() {
            this.receiverDistrict = null;
            this.receiverSubDistrict = null;
            if (this.receiverRegency) {
                this.getReceiverDistricts();
            }
        },
        changeReceiverDistrict() {
            this.receiverSubDistrict = null;
            if (this.receiverDistrict) {
                this.getReceiverSubDistrict();
            }
        },
        validatePhoneReceiver() {
            if (!this.receiverPhone.startsWith("8")) {
                this.receiverPhone =
                    "8" + this.receiverPhone.replace(/[^0-9]/g, "");
            } else {
                this.receiverPhone = this.receiverPhone.replace(/[^0-9]/g, "");
            }
        },
        validateZipCode() {
            this.receiverZipCode = this.receiverZipCode.replace(/[^0-9]/g, "");
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .multi-form-custom.multi-red-custom .multiselect__tags {
    border: 1px solid #fe1e00 !important;
}
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}
.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}
.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
hr {
    color: #c1cdd7;
}
::v-deep .v-money.form-control {
    background-color: #f9fafb;
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody.body {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.badge-walk-in {
    background-color: #e0e6eb !important;
    border: 1px solid #c2cdd6 !important;
    color: #3e4e5c !important;
}
.badge-regular {
    background-color: #cce7ff !important;
    border: 1px solid #99d0ff !important;
    color: #005299 !important;
}
.container-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 50%;
    left: 0%;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #a3b4c2;
    transform: translate(0%, -50%);
}

.container-radio input:checked ~ .checkmark {
    background-color: transparent;
    border: 2px solid #329a5f;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container-radio input:checked ~ .checkmark:after {
    display: block;
}

.container-radio .checkmark:after {
    top: 2.6px;
    left: 2.6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #329a5f;
}

.box.selected {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}
.box.selected p {
    color: #329a5f !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
::v-deep .multiselect--disabled .multiselect__current,
::v-deep .multiselect--disabled .multiselect__select,
::v-deep .multiselect--disabled .multiselect__tags,
::v-deep .multiselect--disabled .multiselect__placeholder {
    background: #f3f5f7 !important;
    color: #495057 !important;
}
th {
    border-color: #c1cdd7;
    padding: 10px !important;
}
tr {
    border-color: #c1cdd7;
    padding: 10px !important;
}
td {
    padding: 10px !important;
    vertical-align: middle;
}
.table-light {
    background-color: #f7f7f7 !important;
    --bs-table-border-color: #f7f7f7 !important;
    --bs-table-bg: #f7f7f7 !important;
}
.text-suggestion {
    color: #329a5f !important;
}
td.bc-green {
    background-color: #d9f2e4;
    color: #329a5f;
    font-weight: 600 !important;
}
.table-responsive-custom table tbody tr td {
    font-weight: 300;
}
td.p-footer {
    padding: 5px !important;
}
.radius-select {
    border-radius: 7px;
    background: #f3f5f7;
    color: #000;
    font-weight: 500;
    padding: 7px 10px;
    border: 1px solid #d1d0d4;
}
.form-edit {
    border: unset !important;
    text-align: right;
    padding: 0px;
    font-weight: 600 !important;
    color: #000 !important;
    border-radius: unset !important;
}
.form-edit.yellow {
    color: #ff8600 !important;
}
.text-gray {
    color: #a3b4c2 !important;
}
.rotate-arrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}
textarea {
    resize: none;
}
</style>
